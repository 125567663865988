import React, {useContext} from "react";
import fr from "../../utils/FR.json";
import {GlobalContext} from "../layout/GlobalProvider";


export default function DisconnectModal({setToggleModal}) {

    const {removeToken} = useContext((GlobalContext))

    return (
        <>
            <div className="position-fixed top-0 vw-100 vh-100">
                <div className="w-100 h-100 bg-dark bg-opacity-75">
                    <div
                        className="position-absolute top-50 start-50 translate-middle"
                        style={{minWidth: "400px"}}
                    >
                        {/* show={show} onHide={toggle} onClick={toggle}*/}
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Déconnexion</h5>
                                    <button
                                        type="button"
                                        class="btn-close"
                                        onClick={() => {
                                            setToggleModal(false);
                                            removeToken()
                                        }}
                                    />
                                </div>
                                <div class="modal-body">
                                    <p>
                                        Vous avez été déconnecté. Expiration de
                                        session
                                    </p>
                                </div>
                                <div class="modal-footer">
                                    <button
                                        type="button"
                                        class="btn btn-secondary"
                                        onClick={() => {
                                            setToggleModal(false);
                                            removeToken()
                                        }}
                                    >
                                        {fr.buttonClose}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
