import React from 'react';

function InfoAllergeneProduit({produit}) {
    return (
        <div>

            <ul>
                {produit.allergenes.map((allergene) => {
                    if (allergene.is_present)
                        return <li key={allergene.id}>{allergene.allergene}</li>
                })
                }
            </ul>
        </div>
    );
}

export default InfoAllergeneProduit;