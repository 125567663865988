import React, {useContext, useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {Checkbox, message, Spin} from "antd";
import {API_URL} from "../../../utils/constants";
import {GlobalContext} from "../../layout/GlobalProvider";


function AllergenesProduit({produitData, onProduitDataChange, typeOfModal}) {

    const [allergenes, setAllergenes] = useState([])
    const [loading, setLoading] = useState(false)
    const {token} = useContext(GlobalContext)
    const getAllergnes = async () => {
        setLoading(true)
        try {
            const response = await fetch(API_URL + "get-all-allergenes", {
                method: "GET",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                setAllergenes(res.data)
                onProduitDataChange({allergenes: res.data})
            } else {
                message.error(res.message)

            }
        } catch (e) {
            message.error("Une erreur s'est produite")
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        if (typeOfModal === "add") {
            getAllergnes()
        }
    }, [])

    const handleAllergeneChange = (allergeneId, checked) => {
        const updatedAllergenes = produitData.allergenes.map((allergene) => {
            if (allergene.id === allergeneId) {
                return {...allergene, is_present: checked};
            }
            return allergene;
        });

        const updatedProduitData = {
            ...produitData,
            allergenes: updatedAllergenes,
        };

        onProduitDataChange(updatedProduitData);
    };


    return (
        <Modal.Body>
            <Spin spinning={loading} tip="Chargement des allergènes ...">
                {produitData.allergenes.map((allergene) => (
                    <Checkbox
                        key={allergene.id}
                        checked={allergene.is_present}
                        onChange={(e) =>
                            handleAllergeneChange(allergene.id, e.target.checked)
                        }
                    >
                        {allergene.allergene}
                    </Checkbox>
                ))}
            </Spin>
        </Modal.Body>
    );
}

export default AllergenesProduit;