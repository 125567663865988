import React, {useContext, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {API_URL} from "../../utils/constants";
import fr from "../../utils/FR.json";
import {message, Spin} from "antd";
import {GlobalContext} from "../layout/GlobalProvider";


function ModalAddHoraire({jour, children, getHoraires}) {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const {token} = useContext(GlobalContext)
    const toggle = () => setShow(!show);

    const [jourSemaine, setJourSemaine] = useState(jour.jourSemaine);
    const [ouvertureHeureAM, setOuvertureHeureAM] = useState(
        jour.ouvertAM[0] + jour.ouvertAM[1]
    );
    const [ouvertureMinuteAM, setOuvertureMinuteAM] = useState(
        jour.ouvertAM[3] + jour.ouvertAM[4]
    );
    const [fermetureHeureAM, setFermetureHeureAM] = useState(
        jour.fermerAM[0] + jour.fermerAM[1]
    );
    const [fermetureMinuteAM, setFermetureMinuteAM] = useState(
        jour.fermerAM[3] + jour.fermerAM[4]
    );
    const [ouvertureHeurePM, setOuvertureHeurePM] = useState(
        jour.ouvertPM[0] + jour.ouvertPM[1]
    );
    const [ouvertureMinutePM, setOuvertureMinutePM] = useState(
        jour.ouvertPM[3] + jour.ouvertPM[4]
    );
    const [fermetureHeurePM, setFermetureHeurePM] = useState(
        jour.fermerPM[0] + jour.fermerPM[1]
    );
    const [fermetureMinutePM, setFermetureMinutePM] = useState(
        jour.fermerPM[3] + jour.fermerPM[4]
    );
    const [general, setGeneral] = useState(jour.general);
    const [matin, setMatin] = useState(jour.matin);
    const [aprem, setAprem] = useState(jour.aprem);

    const handleSubmit = async () => {
        setLoading(true)
        let data = new FormData();
        data.append("jourSemaine", jourSemaine);
        data.append("ouvertureHeureAM", ouvertureHeureAM);
        data.append("ouvertureMinuteAM", ouvertureMinuteAM);
        data.append("fermetureHeureAM", fermetureHeureAM);
        data.append("fermetureMinuteAM", fermetureMinuteAM);
        data.append("ouvertureHeurePM", ouvertureHeurePM);
        data.append("ouvertureMinutePM", ouvertureMinutePM);
        data.append("fermetureHeurePM", fermetureHeurePM);
        data.append("fermetureMinutePM", fermetureMinutePM);
        data.append("general", general);
        data.append("matin", matin);
        data.append("aprem", aprem);

        async function editHoraire() {
            try {
                const response = await fetch(API_URL + "editHoraire", {
                    method: "POST",
                    headers: {Authorization: "Bearer " + token},
                    body: data,
                })
                const res = await response.json()
                if (res.status === 200) {
                    toggle()
                    getHoraires()
                } else {
                    message.error(res.message)
                }
            } catch (e) {
                message.error("Une erreur s'est produite sur l'édition d'horaire")
            }
            setLoading(false)
        }

        await editHoraire()
    };

    return (
        <>
            <button className="btn btn-outline-primary" onClick={toggle}>
                {children}
            </button>

            <Modal show={show} onHide={toggle}>
                <Modal.Header closeButton>
                    <Modal.Title>Modifier une horaire</Modal.Title>
                </Modal.Header>
                <Spin spinning={loading} tip="Modification de l'horaire en cours ...">
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <label className="fw-bold">Jour de la semaine</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Entrer le jour de la semaine"
                                value={jourSemaine}
                                onChange={(e) => setJourSemaine(e.target.value)}
                                disabled
                            ></input>
                            <Form.Check
                                type="switch"
                                id="switchGeneral"
                                label={general ? "Ouvert" : "Fermé"}
                                checked={general}
                                onChange={() => setGeneral(!general)}
                            />
                            {general ? (
                                <>
                                    <Form.Check
                                        type="switch"
                                        id="switchMatin"
                                        label={
                                            matin
                                                ? "Ouvert le matin"
                                                : "Fermé le matin"
                                        }
                                        checked={matin}
                                        onChange={() => setMatin(!matin)}
                                    />

                                    <div>
                                        {matin ? (
                                            <>
                                                <label className="fw-bold">
                                                    Heure d'ouverture matin
                                                </label>
                                                <div className="d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        maxLength={2}
                                                        className="form-control m-1"
                                                        value={ouvertureHeureAM}
                                                        onChange={(e) =>
                                                            setOuvertureHeureAM(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    :
                                                    <input
                                                        type="text"
                                                        maxLength={2}
                                                        className="form-control m-1"
                                                        value={ouvertureMinuteAM}
                                                        onChange={(e) =>
                                                            setOuvertureMinuteAM(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <label className="fw-bold">
                                                    Heure de fermeture du matin
                                                </label>
                                                <div className="d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        maxLength={2}
                                                        className="form-control m-1"
                                                        value={fermetureHeureAM}
                                                        onChange={(e) =>
                                                            setFermetureHeureAM(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    :
                                                    <input
                                                        type="text"
                                                        maxLength={2}
                                                        className="form-control m-1"
                                                        value={fermetureMinuteAM}
                                                        onChange={(e) =>
                                                            setFermetureMinuteAM(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </>
                                        ) : null}
                                        <Form.Check
                                            type="switch"
                                            id="switchAprem"
                                            label={
                                                aprem
                                                    ? "Ouvert l'après-midi"
                                                    : "Fermé l'après-midi"
                                            }
                                            checked={aprem}
                                            onChange={() => setAprem(!aprem)}
                                        />
                                        {aprem ? (
                                            <>
                                                <label className="fw-bold">
                                                    Heure d'ouverture de l'après
                                                    midi
                                                </label>
                                                <div className="d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        maxLength={2}
                                                        className="form-control m-1"
                                                        value={ouvertureHeurePM}
                                                        onChange={(e) =>
                                                            setOuvertureHeurePM(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    :
                                                    <input
                                                        type="text"
                                                        maxLength={2}
                                                        className="form-control m-1"
                                                        value={ouvertureMinutePM}
                                                        onChange={(e) =>
                                                            setOuvertureMinutePM(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <label className="fw-bold">
                                                    Heure de fermeture de l'après
                                                    midi
                                                </label>
                                                <div className="d-flex align-items-center">
                                                    <input
                                                        type="text"
                                                        maxLength={2}
                                                        className="form-control m-1"
                                                        value={fermetureHeurePM}
                                                        onChange={(e) =>
                                                            setFermetureHeurePM(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    :
                                                    <input
                                                        type="text"
                                                        maxLength={2}
                                                        className="form-control m-1"
                                                        value={fermetureMinutePM}
                                                        onChange={(e) =>
                                                            setFermetureMinutePM(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </>
                                        ) : null}
                                    </div>
                                </>
                            ) : null}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={toggle}>
                            {fr.buttonClose}
                        </Button>
                        <Button variant="primary" onClick={handleSubmit}>
                            <i className="bi bi-plus-lg"></i> Modifier
                        </Button>
                    </Modal.Footer>
                </Spin>
            </Modal>
        </>
    );
}

export default ModalAddHoraire;
