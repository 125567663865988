import React, {useContext, useEffect, useState} from "react";
import ModalEditHoraire from "../horaires/ModalEditHoraire";
import {API_URL} from "../../utils/constants";
import fr from "../../utils/FR.json";
import ModalAddHoraire from "../horaires/ModalAddHoraire";
import {message, Spin, Switch, Table} from "antd";
import {GlobalContext} from "../layout/GlobalProvider";

const GestionHoraires = () => {
    const [horaires, setHoraires] = useState([]);
    const [loadingRows, setLoadingRows] = useState([]);
    const {token, role} = useContext(GlobalContext)

    async function getHoraires() {
        try {
            const response = await fetch(API_URL + "afficherHoraire", {
                method: "GET",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                setHoraires(res.data)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            console.log(e)
            message.error("Erreur lors de la récupérations des horaires")
        }

    }

    useEffect(() => {
        getHoraires();
    }, []);

    const colonnes = [

        {
            title: 'Jour',
            dataIndex: 'jourSemaine',
            key: 'jourSemaine',
            sorter: (a, b) => {
                const joursSemaine = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];
                const indexA = joursSemaine.indexOf(a.jourSemaine.toLowerCase());
                const indexB = joursSemaine.indexOf(b.jourSemaine.toLowerCase());

                return indexA - indexB;
            },
            defaultSortOrder: 'ascend',
        },
        {
            title: 'General',
            dataIndex: 'general',
            key: 'general',
            render: (enabled, record) => (
                <Spin spinning={loadingRows.includes(record.jourSemaine)}>
                    <Switch
                        checked={enabled}
                        onChange={(checked) => handleQuickChange(record.jourSemaine, checked, "general")}
                    />
                </Spin>
            ),
        },
        {
            title: 'Matin',
            dataIndex: 'matin',
            key: 'matin',
            render: (enabled, record) => (
                <Spin spinning={loadingRows.includes(record.jourSemaine)}>
                    <Switch
                        checked={enabled}
                        onChange={(checked) => handleQuickChange(record.jourSemaine, checked, "matin")}
                    />
                </Spin>
            ),
        },
        {
            title: 'Ouverture matin',
            dataIndex: 'ouvertAM',
            key: 'ouvertAM',
        },
        {
            title: 'Fermeture matin',
            dataIndex: 'fermerAM',
            key: 'fermerAM',

        },
        {
            title: 'Après midi',
            dataIndex: 'aprem',
            key: 'aprem',
            render: (enabled, record) => (
                <Spin spinning={loadingRows.includes(record.jourSemaine)}>
                    <Switch
                        checked={enabled}
                        onChange={(checked) => handleQuickChange(record.jourSemaine, checked, "aprem")}
                    />
                </Spin>
            ),
        },
        {
            title: 'Ouverture Après midi',
            dataIndex: 'ouvertPM',
            key: 'ouvertPM',
        },
        {
            title: 'Fermeture Après midi',
            dataIndex: 'fermerPM',
            key: 'fermerPM',

        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <div>
                    <ModalEditHoraire jour={record} getHoraires={getHoraires}>
                        <i className="bi bi-pencil"></i> {fr.buttonEdit}
                    </ModalEditHoraire>
                </div>
            ),
        },
    ];

    const handleQuickChange = async (jourSemaine, checked, typeHoraire) => {
        setLoadingRows((prevLoadingRows) => {
            return [...prevLoadingRows, jourSemaine]; // Ajouter l'ID à la liste des lignes en cours de chargement
        });
        // Mettez à jour l'état du bouton switch dans le composant
        const updatedHoraire = horaires.map((horaire) => {
            if (horaire.jourSemaine === jourSemaine) {
                return {...horaire, enabled: checked};
            }
            return horaire;
        });
        setHoraires(updatedHoraire);
        try {
            // Effectuez une requête vers la base de données pour mettre à jour l'état du produit
            let data = new FormData();
            data.append("jourSemaine", jourSemaine);
            data.append("valeur", checked);
            data.append("typeHoraire", typeHoraire);
            const response = await fetch(API_URL + "quick-change-horaire", {
                method: "POST",
                body: data,
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                getHoraires()
                message.success(res.message)
            } else {
                message.error(res.message)
            }
        } catch (error) {
            message.error(`Erreur lors de la mise à jour de l'horaire : ${jourSemaine}`);
        }
        setLoadingRows((prevLoadingRows) =>
            prevLoadingRows.filter((rowId) => rowId !== jourSemaine) // Supprimer l'ID de la liste des lignes en cours de chargement une fois la requête terminée
        );
    };


    return (
        <>
            <h2 className="text-primary text-center fw-bold">Horaires</h2>
            <div className="d-flex justify-content-center align-items-center gap-3 my-3">
                <ModalAddHoraire getHoraires={getHoraires}>
                    Ajouter une horaire
                </ModalAddHoraire>
            </div>
            <div className="table-responsive">
                <Table
                    rowKey="jourSemaine"
                    dataSource={horaires}
                    columns={colonnes}
                    loading={horaires.length < 1}
                />
            </div>
        </>
    );
};

export default GestionHoraires;

