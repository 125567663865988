import React, {createContext, useEffect, useState} from 'react';
import useToken from "../connexion/useToken";
import {API_URL} from "../../utils/constants";
import {message} from "antd";

export const GlobalContext = createContext();


function GlobalProvider({children, setMaintenance, setSnowFalling}) {
    const [globalConfig, setGlobalConfig] = useState(false);
    const [serverOk, setServerOk] = useState(false);

    async function getConfig() {
        try {
            const response = await fetch(API_URL + "configuration")
            const res = await response.json()
            if (res.status === 200) {
                setGlobalConfig(res.data)
                setSnowFalling(res.data.snowfall)
                setMaintenance(res.data.maintenance)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            console.log(e)
            message.error("Erreur de configuration")
        }
    }

    async function editConfig(updatedConfig) {
        try {
            const response = await fetch(API_URL + "configuration", {
                method: "PUT",
                body: JSON.stringify(updatedConfig),
                headers: {Authorization: "Bearer " + token, "Content-Type": "application/json"}
            })
            const res = await response.json()
            if (res.status === 200) {
                message.success(res.message)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Erreur de configuration")
        }
    }

    async function checkServer() {
        let retryCount = 0;
        const maxRetries = 3;
        const retryInterval = 5000; // 5 secondes
        const checkTiming = 60000; // 5 secondes

        const makeRequest = async () => {
            if (!globalConfig.maintenance) {
                try {
                    const response = await fetch(API_URL + "serverOk");
                    if (!response.ok) {
                        throw new Error("Erreur lors de la requête");
                    }
                    if (retryCount > 0) {
                        message.success("connexion établie")
                    }
                    setServerOk(true);
                    setTimeout(checkServer, checkTiming);
                    getConfig()
                } catch (error) {
                    retryCount++;
                    message.error("Tentative de connexion : " + retryCount + "/3")
                    if (retryCount === maxRetries) {
                        setTimeout(() => {
                            message.error("La connexion a échoué")
                        }, [2000])
                    }
                    if (retryCount < maxRetries) {
                        setTimeout(makeRequest, retryInterval);
                    } else {
                        setServerOk(false);
                        setMaintenance(true)
                        setTimeout(checkServer, checkTiming);
                    }
                }
            }
        };
        makeRequest();
    }

    useEffect(() => {
        checkServer();
    }, []);

    useEffect(() => {
        getConfig()
    }, [])

    const {token, role, setToken, removeToken, isValidToken} = useToken()
    // Autres variables et fonctions globales

    return (
        <GlobalContext.Provider
            value={{globalConfig, editConfig, getConfig, token, role, setToken, removeToken, isValidToken}}>
            {children}
        </GlobalContext.Provider>
    );
}

export default GlobalProvider;
