import {Carousel} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Header from "../components/layout/Header";
import {API_URL} from "../utils/constants";
import fr from "../utils/FR.json";
import {Helmet} from "react-helmet";
import {message} from "antd";

const titre = "Pièces montées - Boulangerie Pâtisserie l'Épi d'Or"
const url = "https://boulangerielepidor.fr/Store/Cake"
const content = "Découvrez notre sélection de pièces montées pour vos événements spéciaux. La boulangerie pâtisserie l'Épi d'Or à Orléans vous propose une variété de créations uniques pour faire de votre célébration un moment inoubliable."
const imgPreview = "preview/PieceMontee.png"
const PagePieceMontee = ({element, token, role}) => {
    const [mariage, setMariage] = useState([]);
    const [bapteme, setBapteme] = useState([]);
    const [anniversaire, setAnniversaire] = useState([]);
    const [autre, setAutre] = useState([]);
    const [loading, setLoading] = useState(false);

    const getPiecesMontees = async () => {
        try {
            const categories = ["Mariage", "Religieux", "Anniversaire", "Autre"];
            const fetchData = async (categorie) => {
                try {
                    const formData = new FormData();
                    formData.append("categorie", categorie);
                    const response = await fetch(API_URL + "afficherPieceMonteeCategorie", {
                        method: "POST",
                        body: formData,
                    });
                    const res = await response.json();
                    if (res.status === 200) {
                        return res.data;
                    } else {
                        message.error(res.message);
                        return [];
                    }
                } catch (error) {
                    console.error("Une erreur s'est produite lors de la récupération des données pour la catégorie", categorie, ":", error);
                    message.error("Une erreur s'est produite lors de la récupération des données pour la catégorie", categorie);
                    return [];
                }
            };

            const fetchedData = await Promise.all(categories.map((categorie) => fetchData(categorie)));

            const [mariage, bapteme, anniversaire, autre] = fetchedData;

            setMariage(mariage);
            setBapteme(bapteme);
            setAnniversaire(anniversaire);
            setAutre(autre);
        } catch (error) {
            console.error("Une erreur s'est produite lors de la récupération des données :", error);
            message.error("Une erreur s'est produite lors de la récupération des données");
        }
    };


    useEffect(() => {
        getPiecesMontees();
    }, []);

    const carousel = (categorie) => {
        try {
            return (
                <Carousel>
                    {categorie.map((elem, i) => {
                        return (
                            <Carousel.Item key={i}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div
                                        className="badge bg-primary text-dark position-absolute"
                                        style={{
                                            top: "0.5rem",
                                            left: "0.5rem",
                                        }}
                                    >
                                        {i + 1}
                                    </div>
                                    <img
                                        className="d-block rounded img-fluid"
                                        src={
                                            API_URL +
                                            "get_image/imagePieceMontee/" +
                                            elem.photo

                                        }
                                        alt={elem.titre}
                                        style={{
                                            height: "50vh",
                                            objectFit: "cover",
                                        }}
                                        loading="lazy"
                                    />
                                </div>
                                <Carousel.Caption>
                                    <h3>{elem.titre}</h3>
                                </Carousel.Caption>
                            </Carousel.Item>
                        );
                    })}
                </Carousel>
            );
        } catch (err) {
            return <p>Loading ...</p>;
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    {titre}
                </title>
                <meta
                    name="title"
                    content={titre}
                />
                <meta
                    name="description"
                    content={content}
                />

                <meta property="og:type" content="website"/>
                <meta
                    property="og:url"
                    content={url}
                />
                <meta
                    property="og:title"
                    content={titre}
                />
                <meta
                    property="og:description"
                    content={content}
                />
                <meta property="og:image" content={imgPreview}/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta
                    property="twitter:url"
                    content={url}
                />
                <meta
                    property="twitter:title"
                    content={titre}
                />
                <meta
                    property="twitter:description"
                    content={content}
                />
                <meta property="twitter:image" content={imgPreview}/>
            </Helmet>
            <Header
                title={fr.cakeTitle}
                subtitle={fr.cakeSubTitle}
                element={element}
                background={"piecemontee.jpg"}
                hauteur={"70vh"}
            />
            <section className="page-section ">
                <div className="container ">
                    <div className="product-item">
                        <div className="product-item-title d-flex ">
                            <div className="bg-faded p-5 d-flex ms-auto rounded">
                                <h2 className="section-heading mb-0">
                                    <span className="section-heading-upper">
                                        {fr.cakeDefTitle}
                                    </span>
                                    <span className="section-heading-lower">
                                        {fr.cakeDefSubTitle}
                                    </span>
                                </h2>
                            </div>
                        </div>
                        <div className="product-item-description d-flex me-auto">
                            <div className="bg-faded p-5 rounded">
                                <p className="mb-0">
                                    <em>{fr.cakeDef[1]}</em>
                                    <br/>
                                    {fr.cakeDef[2]}{" "}
                                    <strong>{fr.cakeDef[3]}</strong>{" "}
                                    {fr.cakeDef[4]}
                                </p>
                                <br/>
                                <p className="mb-0">{fr.cakeDefEnd}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-white">
                <article
                    className="d-flex align-items-center mx-5"
                    style={{marginTop: "3rem", marginBottom: "3rem"}}
                >

                    <section style={{width: "50%"}}>
                        {carousel(mariage)}
                    </section>

                    <section style={{width: "50%", margin: "2rem"}}>
                        <h1>{fr.cakeWeddingTitle}</h1>
                        <p>{fr.cakeWeddingSubTitle}</p>
                    </section>

                </article>
                <article
                    className="d-flex mx-5 align-items-center"
                    style={{marginTop: "3rem", marginBottom: "3rem"}}
                >
                    <section
                        className="text-end"
                        style={{width: "50%", margin: "2rem"}}
                    >
                        <h1>{fr.cakeBatismTitle}</h1>
                        <p>{fr.cakeBatismSubTitle}</p>
                    </section>
                    <section style={{width: "50%"}}>
                        {carousel(bapteme)}
                    </section>
                </article>
                <article
                    className="d-flex mx-5 align-items-center"
                    style={{marginTop: "3rem", marginBottom: "3rem"}}
                >
                    <section style={{width: "50%"}}>
                        {carousel(anniversaire)}
                    </section>
                    <section style={{width: "50%", margin: "2rem"}}>
                        <h1>{fr.cakeBirthdayTitle}</h1>
                        <p>{fr.cakeBirthdaySubTitle}</p>
                    </section>
                </article>
                <article
                    className="d-flex mx-5 align-items-center"
                    style={{marginTop: "3rem", marginBottom: "3rem"}}
                >
                    <section
                        className="text-end"
                        style={{width: "50%", margin: "2rem"}}
                    >
                        <h1>{fr.cakeEventTitle}</h1>
                        <p>{fr.cakeEventSubTitle}</p>
                    </section>
                    <section style={{width: "50%"}}>
                        {carousel(autre)}
                    </section>
                </article>
            </section>
            <div className="about-heading-content">
                <div>
                    <div className="col-xl-9 col-lg-10 mx-auto">
                        <div className="bg-faded rounded p-5">
                            <h2 className="section-heading mb-4">
                                <span className="section-heading-upper">
                                    {fr.cakeOrderSubTitle}
                                </span>
                                <span className="section-heading-lower">
                                    {fr.cakeOrderTitle}
                                </span>
                            </h2>
                            <p>
                                {fr.cakeOrderFirstText[1]}{" "}
                                <a
                                    href="tel:0238883051"
                                    style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                    }}
                                >
                                    <strong>{fr.cakeOrderFirstText[2]}</strong>
                                </a>{" "}
                                {fr.cakeOrderFirstText[3]}
                            </p>
                            <p className="mb-0">
                                {fr.cakeOrderSecondtext[1]}{" "}
                                <em>{fr.cakeOrderSecondtext[2]}</em>{" "}
                                {fr.cakeOrderSecondtext[3]}{" "}
                                <em>{fr.cakeOrderSecondtext[4]}</em>
                            </p>
                            <p className="mb-0">{fr.cakeOrderSecondtext[5]}</p>
                            <br/>
                            <small>{fr.cakeOrderWarning}</small>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PagePieceMontee;
