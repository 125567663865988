import React, {useContext, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {API_URL} from "../../utils/constants";
import fr from "../../utils/FR.json";
import {message, Spin} from "antd";
import {GlobalContext} from "../layout/GlobalProvider";

const ModalEditUtilisateur = ({children, getUtilisateurs, user}) => {
    const [show, setShow] = useState(false);
    const toggle = () => setShow(!show);
    const {token} = useContext(GlobalContext)

    const [nom, setNom] = useState(user.nom);
    const [mdp, setMdp] = useState("");
    const [email, setEmail] = useState(user.email);
    const [isValide, setIsValide] = useState("form-control");
    const [loading, setLoading] = useState(false);
    const [cpassword, setCPassword] = useState("");
    const [type, setType] = useState(user.type);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (mdp === "") {
            envoie();
        } else {
            if (mdp !== cpassword) {
                message.warning("Les mots de passe de correspondent pas");
            } else {
                envoie();
            }
        }
    };

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleChangeEmail = (e) => {
        if (!isValidEmail(e.target.value)) {
            setIsValide("form-control is-invalid");
        } else {
            setIsValide("form-control is-valid");
        }
        setEmail(e.target.value);
    };

    const envoie = () => {
        setLoading(true)
        let data = new FormData();
        data.append("id", user.id);
        data.append("nom", nom);
        data.append("mdp", mdp);
        data.append("type", type);
        data.append("email", email);

        const Upload = async () => {
            try {
                const response = await fetch(API_URL + "editUser", {
                    method: "POST",
                    headers: {Authorization: "Bearer " + token},
                    body: data,
                })
                const res = await response.json()
                if (res.status === 200) {
                    getUtilisateurs()
                    toggle()
                    message.success(res.message)
                } else {
                    message.error(res.message)
                }
            } catch (e) {
                message.error("Une erreur est survenue")
            }
            setLoading(false)

        };
        Upload();
    };

    return (
        <>
            <button className="btn btn-outline-primary" onClick={toggle}>
                {children}
            </button>

            <Modal show={show} onHide={toggle}>
                <Modal.Header closeButton>
                    <Modal.Title>Modifier un utilisateur</Modal.Title>
                </Modal.Header>
                <Spin spinning={loading} tip="Modification de l'utilisateur en cours ...">
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <label className="fw-bold">Nom</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Entrer le nom"
                                value={nom}
                                onChange={(e) => setNom(e.target.value)}
                            ></input>
                            <label className="fw-bold">Adresse e-mail</label>
                            <input
                                type="text"
                                className={isValide}
                                placeholder="Entrer l'adresse e-mail"
                                value={email}
                                onChange={(e) => handleChangeEmail(e)}
                            ></input>
                            <label className="fw-bold">Mot de passe</label>
                            <input
                                type="password"
                                className={
                                    mdp.length === 0
                                        ? "form-control"
                                        : "form-control is-valid"
                                }
                                placeholder="Entrer le mot de passe"
                                value={mdp}
                                onChange={(e) => setMdp(e.target.value)}
                            ></input>
                            <label className="fw-bold">
                                Confirmer le mot de passe
                            </label>
                            <input
                                type="password"
                                className={
                                    cpassword.length === 0
                                        ? "form-control"
                                        : mdp === cpassword
                                            ? "form-control is-valid"
                                            : "form-control is-invalid"
                                }
                                placeholder="Confirmer le mot de passe"
                                value={cpassword}
                                onChange={(e) => setCPassword(e.target.value)}
                            ></input>
                            <label className="fw-bold">Type d'utilisateur</label>
                            <Form.Select
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                <option>CONTRIBUTEUR</option>
                                <option>MODERATEUR</option>
                                <option>ADMIN</option>
                            </Form.Select>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={toggle}>
                            {fr.buttonClose}
                        </Button>

                        <Button variant="primary" onClick={handleSubmit}>
                            <i className="bi bi-pencil"></i> {fr.buttonEdit}
                        </Button>

                    </Modal.Footer>
                </Spin>
            </Modal>
        </>
    );
};

export default ModalEditUtilisateur;
