import React, {useEffect, useState} from "react";
import {AutoComplete, Input} from "antd";

const SearchBarGestion = ({produits, setFilteredProduits}) => {
    const [searchText, setSearchText] = useState("");
    const [options, setOptions] = useState([]);

    useEffect(() => {
        // Filtrer les produits en fonction du texte de recherche
        const filtered = produits.filter((produit) =>
            produit.nom.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredProduits(filtered);
    }, [searchText, produits, setFilteredProduits]);

    const handleSearch = (value) => {
        setSearchText(value);
        // Mettre à jour les options d'autocomplétion en fonction de la recherche
        const filteredOptions = produits
            .filter((produit) =>
                produit.nom.toLowerCase().includes(value.toLowerCase())
            )
            .map((produit) => ({value: produit.nom}));
        setOptions(filteredOptions);
    };

    const handleSelect = (value) => {
        setSearchText(value);
    };

    return (
        <div className="d-flex justify-content-center align-items-center">
            <AutoComplete
                options={options}
                onSelect={handleSelect}
                onSearch={handleSearch}
                className="w-50 m-3"
            >
                <Input.Search placeholder="Rechercher un produit"/>
            </AutoComplete>
        </div>
    );
};

export default SearchBarGestion;
