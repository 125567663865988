import React from "react";
import {Helmet} from "react-helmet";

const titre = "Politique de Confidentialité - Boulangerie Pâtisserie l'Épi d'Or"
const url = "https://boulangerielepidor.fr//PrivacyPolicy"
const content = "Découvrez notre politique de confidentialité pour la boulangerie pâtisserie l'Épi d'Or à Orléans. Nous prenons la protection de vos données personnelles très au sérieux et nous nous engageons à respecter votre vie privée. Consultez notre politique de confidentialité pour en savoir plus sur les informations que nous collectons et la manière dont nous les utilisons."
const PageCGU = ({element}) => {
    return (
        <>
            <Helmet>
                <title>
                    {titre}
                </title>
                <meta
                    name="title"
                    content={titre}
                />
                <meta
                    name="description"
                    content={content}
                />

                <meta property="og:type" content="website"/>
                <meta
                    property="og:url"
                    content={url}
                />
                <meta
                    property="og:title"
                    content={titre}
                />
                <meta
                    property="og:description"
                    content={content}
                />


                <meta property="twitter:card" content="summary_large_image"/>
                <meta
                    property="twitter:url"
                    content={url}
                />
                <meta
                    property="twitter:title"
                    content={titre}
                />
                <meta
                    property="twitter:description"
                    content={content}
                />

            </Helmet>
            <header
                className="py-5"
                ref={element}
                style={{marginTop: "5rem"}}
            >
                <div className="container px-4 px-lg-5 my-5">
                    <div className="text-center text-white">
                        <h1 className="display-4 fw-bolder">
                            Politique de confidentialité
                        </h1>
                        <p className="lead fw-normal text-white-50 mb-0">
                            Données personnelles
                        </p>
                    </div>
                </div>
            </header>
            <section
                className="page-section clearfix"
                style={{
                    padding: "3rem",
                    margin: "2rem",
                    borderRadius: "10px",
                    backgroundColor: "lightGrey",
                    opacity: "0.85",
                }}
            >
                <div className="container" style={{textAlign: "justify"}}>
                    <h1 className="text-dark text-start">
                        Politique de confidentialité du site de la boulangerie
                        l’Épi d’Or d’Orléans
                    </h1>
                    <h2 className="text-center">Données personnelles</h2>
                    <h2>Définitions</h2>
                    <p>
                        <strong>L'Éditeur</strong> : La personne, physique ou
                        morale, qui édite les services de communication au
                        public en ligne.
                        <br/>
                        <strong>Le Site</strong> : L'ensemble des sites, pages
                        Internet et services en ligne proposés par l'Éditeur.
                        <br/>
                        <strong>L'Utilisateur</strong> : La personne utilisant
                        le Site et les services.
                    </p>
                    <br/>
                    <h2>1 - Nature des données collectées</h2>
                    <b>
                        Dans le cadre de l'utilisation des Sites, l'Éditeur est
                        susceptible de collecter les catégories de données
                        suivantes concernant ses Utilisateurs :
                    </b>
                    <p>
                        Données d'état-civil, d'identité, d'identification...{" "}
                        <br/>
                        Données de connexion (adresses IP, journaux
                        d'événements...)
                        <br/> Données de localisation (déplacements, données
                        GPS, GSM...
                    </p>
                    <br/>
                    <h2>
                        2- Communication des données personnelles à des tiers
                    </h2>
                    <b>Pas de communication à des tiers</b>
                    <p>
                        Vos données ne font l'objet d'aucune communication à des
                        tiers. Vous êtes toutefois informés qu'elles pourront
                        être divulguées en application d'une loi, d'un règlement
                        ou en vertu d'une décision d'une autorité réglementaire
                        ou judiciaire compétente.
                    </p>
                    <br/>
                    <h2>
                        3- Information préalable pour la communication des
                        données personnelles à des tiers en cas de fusion /
                        absorption
                    </h2>
                    <b>
                        Information préalable et possibilité d’opt-out avant et
                        après la fusion / acquisition
                    </b>
                    <p>
                        Dans le cas où nous prendrions part à une opération de
                        fusion, d’acquisition ou à toute autre forme de cession
                        d’actifs, nous nous engageons à garantir la
                        confidentialité de vos données personnelles et à vous
                        informer avant que celles-ci ne soient transférées ou
                        soumises à de nouvelles règles de confidentialité
                    </p>
                    <br/>
                    <h2>4- Agrégation des données</h2>
                    <b>Agrégation avec des données non personnelles</b>
                    <p>
                        Nous pouvons publier, divulguer et utiliser les
                        informations agrégées (informations relatives à tous nos
                        Utilisateurs ou à des groupes ou catégories spécifiques
                        d'Utilisateurs que nous combinons de manière à ce qu'un
                        Utilisateur individuel ne puisse plus être identifié ou
                        mentionné) et les informations non personnelles à des
                        fins d'analyse du secteur et du marché, de profilage
                        démographique, à des fins promotionnelles et
                        publicitaires et à d'autres fins commerciales.
                    </p>
                    <b>
                        {" "}
                        Agrégation avec des données personnelles disponibles sur
                        les comptes sociaux de l'Utilisateur
                    </b>
                    <p>
                        Si vous connectez votre compte à un compte d’un autre
                        service afin de faire des envois croisés, ledit service
                        pourra nous communiquer vos informations de profil, de
                        connexion, ainsi que toute autre information dont vous
                        avez autorisé la divulgation. Nous pouvons agréger les
                        informations relatives à tous nos autres Utilisateurs,
                        groupes, comptes, aux données personnelles disponibles
                        sur l’Utilisateur.
                    </p>
                    <br/>
                    <h2>5- Collecte des données d'identité</h2>
                    <b>Consultation libre</b>
                    <p>
                        La consultation du Site ne nécessite pas d'inscription
                        ni d'identification préalable. Elle peut s'effectuer
                        sans que vous ne communiquiez de données nominatives
                        vous concernant (nom, prénom, adresse, etc). Nous ne
                        procédons à aucun enregistrement de données nominatives
                        pour la simple consultation du Site.
                    </p>
                    <br/>
                    <h2>6- Collecte des données d'identification</h2>
                    <b>
                        Utilisation de l'identifiant de l’utilisateur uniquement
                        pour l’accès aux services
                    </b>
                    <p>
                        Nous utilisons vos identifiants électroniques seulement
                        pour et pendant l'exécution du contrat.
                    </p>
                    <br/>
                    <h2>7- Géolocalisation</h2>
                    <b>Géolocalisation à des fins de fourniture du service</b>
                    <p>
                        Nous collectons et traitons vos données de
                        géolocalisation afin de vous fournir nos services. Nous
                        pouvons être amenés à faire usage des données
                        personnelles dans le but de déterminer votre position
                        géographique en temps réel. Conformément à votre droit
                        d'opposition prévu par la loi n°78-17 du 6 janvier 1978
                        relative à l'informatique, aux fichiers et aux libertés,
                        vous avez la possibilité, à tout moment, de désactiver
                        les fonctions relatives à la géolocalisation.
                    </p>
                    <b>Géolocalisation à des fins de croisement</b>
                    <p>
                        Nous collectons et traitons vos données de
                        géolocalisation afin de permettre à nos services
                        d'identifier les points de croisement dans le temps et
                        dans l'espace avec d'autres Utilisateurs du service afin
                        de vous présenter le profil des Utilisateurs croisés.
                        Conformément à votre droit d'opposition prévu par la loi
                        n°78-17 du 6 janvier 1978 relative à l'informatique, aux
                        fichiers et aux libertés, vous avez la possibilité, à
                        tout moment, de désactiver les fonctions relatives à la
                        géolocalisation. Vous reconnaissez alors que le service
                        ne sera plus en mesure de vous présenter de profil des
                        autres Utilisateurs.
                    </p>
                    <b>
                        Géolocalisation avec mise à disposition des partenaires
                        pour référencement et agrégation (avec opt-in)
                    </b>
                    <p>
                        Nous pouvons collecter et traiter vos données de
                        géolocalisation avec nos partenaires. Nous nous
                        engageons à anonymiser les données utilisées.
                        Conformément à votre droit d'opposition prévu par la loi
                        n°78-17 du 6 janvier 1978 relative à l'informatique, aux
                        fichiers et aux libertés, vous avez la possibilité, à
                        tout moment, de désactiver les fonctions relatives à la
                        géolocalisation.
                    </p>
                    <br/>
                    <h2>8- Collecte des données du terminal</h2>
                    <b>
                        Collecte des données de profilage et des données
                        techniques à des fins de fourniture du service
                    </b>
                    <p>
                        Certaines des données techniques de votre appareil sont
                        collectées automatiquement par le Site. Ces informations
                        incluent notamment votre adresse IP, fournisseur d'accès
                        à Internet, configuration matérielle, configuration
                        logicielle, type et langue du navigateur... La collecte
                        de ces données est nécessaire à la fourniture des
                        services.
                    </p>
                    <b>
                        Collecte des données techniques à des fins
                        publicitaires, commerciales et statistiques
                    </b>
                    <p>
                        Les données techniques de votre appareil sont
                        automatiquement collectées et enregistrées par le Site,
                        à des fins publicitaires, commerciales et statistiques.
                        Ces informations nous aident à personnaliser et à
                        améliorer continuellement votre expérience sur notre
                        Site. Nous ne collectons ni ne conservons aucune donnée
                        nominative (nom, prénom, adresse...) éventuellement
                        attachée à une donnée technique. Les données collectées
                        sont susceptibles d’être revendues à des tiers.
                    </p>
                    <br/>
                    <h2>9- Cookies</h2>
                    <b>Durée de conservation des cookies</b>
                    <p>
                        Conformément aux recommandations de la CNIL, la durée
                        maximale de conservation des cookies est de 13 mois au
                        maximum après leur premier dépôt dans le terminal de
                        l'Utilisateur, tout comme la durée de la validité du
                        consentement de l’Utilisateur à l’utilisation de ces
                        cookies. La durée de vie des cookies n’est pas prolongée
                        à chaque visite. Le consentement de l’Utilisateur devra
                        donc être renouvelé à l'issue de ce délai.
                    </p>
                    <b>Finalité cookies</b>
                    <p>
                        Les cookies peuvent être utilisés pour des fins
                        statistiques notamment pour optimiser les services
                        rendus à l'Utilisateur, à partir du traitement des
                        informations concernant la fréquence d'accès, la
                        personnalisation des pages ainsi que les opérations
                        réalisées et les informations consultées. Vous êtes
                        informé que l'Éditeur est susceptible de déposer des
                        cookies sur votre terminal. Le cookie enregistre des
                        informations relatives à la navigation sur le service
                        (les pages que vous avez consultées, la date et l'heure
                        de la consultation...) que nous pourrons lire lors de
                        vos visites ultérieures
                    </p>
                    <b>
                        Droit de l'Utilisateur de refuser les cookies, la
                        désactivation entraînant un fonctionnement dégradé du
                        service
                    </b>
                    <p>
                        Vous reconnaissez avoir été informé que l'Éditeur peut
                        avoir recours à des cookies, et l'y autorisez. Si vous
                        ne souhaitez pas que des cookies soient utilisés sur
                        votre terminal, la plupart des navigateurs vous
                        permettent de désactiver les cookies en passant par les
                        options de réglage. Toutefois, vous êtes informé que
                        certains services sont susceptibles de ne plus
                        fonctionner correctement.
                    </p>
                    <b>
                        Association possible des cookies avec des données
                        personnelles pour permettre le fonctionnement du service
                    </b>
                    <p>
                        L'Éditeur peut être amenée à recueillir des informations
                        de navigation via l'utilisation de cookies.
                    </p>
                    <br/>
                    <h2>10- Conservation des données techniques</h2>
                    <b>Durée de conservation des données techniques</b>
                    <p>
                        Les données techniques sont conservées pour la durée
                        strictement nécessaire à la réalisation des finalités
                        visées ci-avant.
                    </p>
                    <h2>
                        <br/>
                        11- Délai de conservation des données personnelles et
                        d'anonymisation
                    </h2>
                    <b>
                        Conservation des données pendant la durée de la relation
                        contractuelle
                    </b>
                    <p>
                        Conformément à l'article 6-5° de la loi n°78-17 du 6
                        janvier 1978 relative à l'informatique, aux fichiers et
                        aux libertés, les données à caractère personnel faisant
                        l'objet d'un traitement ne sont pas conservées au-delà
                        du temps nécessaire à l'exécution des obligations
                        définies lors de la conclusion du contrat ou de la durée
                        prédéfinie de la relation contractuelle.
                    </p>
                    <b>
                        Conservation des données anonymisées au delà de la
                        relation contractuelle / après la suppression du compte
                    </b>
                    <p>
                        Nous conservons les données personnelles pour la durée
                        strictement nécessaire à la réalisation des finalités
                        décrites dans les présentes Politiques de
                        confidentialité. Au-delà de cette durée, elles seront
                        anonymisées et conservées à des fins exclusivement
                        statistiques et ne donneront lieu à aucune exploitation,
                        de quelque nature que ce soit.
                    </p>
                    <b>Suppression des données après suppression du compte</b>
                    <p>
                        Des moyens de purge de données sont mis en place afin
                        d'en prévoir la suppression effective dès lors que la
                        durée de conservation ou d'archivage nécessaire à
                        l'accomplissement des finalités déterminées ou imposées
                        est atteinte. Conformément à la loi n°78-17 du 6 janvier
                        1978 relative à l'informatique, aux fichiers et aux
                        libertés, vous disposez par ailleurs d'un droit de
                        suppression sur vos données que vous pouvez exercer à
                        tout moment en prenant contact avec l'Éditeur
                    </p>
                    <b>Suppression des données après 3 ans d'inactivité</b>
                    <p>
                        Pour des raisons de sécurité, si vous ne vous êtes pas
                        authentifié sur le Site pendant une période de trois
                        ans, vous recevrez un e-mail vous invitant à vous
                        connecter dans les plus brefs délais, sans quoi vos
                        données seront supprimées de nos bases de données.
                    </p>
                    <br/>
                    <h2>12- Suppression du compte</h2>
                    <b>Suppression du compte à la demande</b>
                    <p>
                        L'Utilisateur a la possibilité de supprimer son Compte à
                        tout moment, par simple demande à l'Éditeur OU par le
                        menu de suppression de Compte présent dans les
                        paramètres du Compte le cas échéant.
                    </p>
                    <b>
                        Suppression du compte en cas de violation de la
                        Politique de Confidentialité
                    </b>
                    <p>
                        En cas de violation d'une ou de plusieurs dispositions
                        de la Politique de Confidentialité ou de tout autre
                        document incorporé aux présentes par référence,
                        l'Éditeur se réserve le droit de mettre fin ou
                        restreindre sans aucun avertissement préalable et à sa
                        seule discrétion, votre usage et accès aux services, à
                        votre compte et à tous les Sites.
                    </p>
                    <h2>
                        <br/>
                        13- Indications en cas de faille de sécurité décelée par
                        l'Éditeur
                    </h2>
                    <b>
                        Information de l'Utilisateur en cas de faille de
                        sécurité
                    </b>
                    <p>
                        Nous nous engageons à mettre en oeuvre toutes les
                        mesures techniques et organisationnelles appropriées
                        afin de garantir un niveau de sécurité adapté au regard
                        des risques d'accès accidentels, non autorisés ou
                        illégaux, de divulgation, d'altération, de perte ou
                        encore de destruction des données personnelles vous
                        concernant. Dans l'éventualité où nous prendrions
                        connaissance d'un accès illégal aux données personnelles
                        vous concernant stockées sur nos serveurs ou ceux de nos
                        prestataires, ou d'un accès non autorisé ayant pour
                        conséquence la réalisation des risques identifiés
                        ci-dessus, nous nous engageons à : Vous notifier
                        l'incident dans les plus brefs délais ; Examiner les
                        causes de l'incident et vous en informer ; Prendre les
                        mesures nécessaires dans la limite du raisonnable afin
                        d'amoindrir les effets négatifs et préjudices pouvant
                        résulter dudit incident.
                    </p>
                    <b>Limitation de la responsabilité</b>
                    <p>
                        En aucun cas les engagements définis au point ci-dessus
                        relatifs à la notification en cas de faille de sécurité
                        ne peuvent être assimilés à une quelconque
                        reconnaissance de faute ou de responsabilité quant à la
                        survenance de l'incident en question.
                    </p>
                    <br/>
                    <h2>14- Transfert des données personnelles à l'étranger</h2>
                    <b>Pas de transfert en dehors de l'Union européenne</b>
                    <p>
                        L'Éditeur s'engage à ne pas transférer les données
                        personnelles de ses Utilisateurs en dehors de l'Union
                        européenne.{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.cnil.fr/fr/la-protection-des-donnees-dans-le-monde"
                        >
                            https://www.cnil.fr/fr/la-protection-des-donnees-dans-le-monde
                        </a>
                    </p>
                    <br/>
                    <h2>15- Modification de la politique de confidentialité</h2>
                    <b>
                        En cas de modification de la présente Politique de
                        Confidentialité, engagement de ne pas baisser le niveau
                        de confidentialité de manière substantielle sans
                        l'information préalable des personnes concernées
                    </b>
                    <p>
                        Nous nous engageons à vous informer en cas de
                        modification substantielle de la présente Politique de
                        Confidentialité, et à ne pas baisser le niveau de
                        confidentialité de vos données de manière substantielle
                        sans vous en informer et obtenir votre consentement.
                    </p>
                    <br/>
                    <h2>16- Droit applicable et modalités de recours</h2>
                    <b>
                        Application du droit français (législation CNIL) et
                        compétence des tribunaux
                    </b>
                    <p>
                        La présente Politique de Confidentialité et votre
                        utilisation du Site sont régies et interprétées
                        conformément aux lois de France, et notamment à la Loi
                        n° 78-17 du 6 janvier 1978 relative à l'informatique,
                        aux fichiers et aux libertés. Le choix de la loi
                        applicable ne porte pas atteinte à vos droits en tant
                        que consommateur conformément à la loi applicable de
                        votre lieu de résidence. Si vous êtes un consommateur,
                        vous et nous acceptons de se soumettre à la compétence
                        nonexclusive des juridictions françaises, ce qui
                        signifie que vous pouvez engager une action relative à
                        la présente Politique de Confidentialité en France ou
                        dans le pays de l'UE dans lequel vous vivez. Si vous
                        êtes un professionnel, toutes les actions à notre
                        encontre doivent être engagées devant une juridiction en
                        France. En cas de litige, les parties chercheront une
                        solution amiable avant toute action judiciaire. En cas
                        d'échec de ces tentatives, toutes contestations à la
                        validité, l'interprétation et / ou l'exécution de la
                        Politique de Confidentialité devront être portées même
                        en cas de pluralité des défendeurs ou d'appel en
                        garantie, devant les tribunaux français.
                    </p>
                </div>
            </section>
        </>
    );
};

export default PageCGU;
