import React, {useContext, useEffect, useState} from "react";
import Header from "../components/layout/Header.js";
import fr from "../utils/FR.json";
import {Helmet} from "react-helmet";
import ProductCard from "../components/produits/ProductCard";
import AdsCard from "../components/produits/AdsCard";
import {Pagination, Popconfirm} from "antd";
import SearchBarProducts from "../components/produits/SearchBarProducts";
import {GlobalContext} from "../components/layout/GlobalProvider";
import ProductCardLoading from "../components/produits/ProductCardLoading";


const titre = "Nos Produits - Boulangerie Pâtisserie l'Épi d'Or"
const url = "https://boulangerielepidor.fr/Store/Product"
const content = "Découvrez la sélection complète de produits et les prix proposés par la boulangerie pâtisserie l'Épi d'Or à Orléans. Vous y trouverez tout ce que vous recherchez, des délicieuses baguettes croustillantes aux croissants frais du matin, en passant par les irrésistibles pains au chocolat. Faites-vous plaisir et venez découvrir notre gamme de produits de qualité."
const imgPreview = "preview/Produits.png"
const PageProduits = ({element, token, role}) => {

    const [produit, setProduit] = useState({});

    const [index, setIndex] = useState(30);
    const [complete, setComplete] = useState(false);
    const {globalConfig} = useContext(GlobalContext)

    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);

    let res = [];
    if (produit.length > 0) {
        for (let i = 0; i < produit.length; i++) {
            if (
                produit[i].nom.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
                res.push(produit[i]);
            }
        }
    }
    useEffect(() => {
        if (index < res.length) {
            setComplete(false);
        } else {
            setComplete(true);
            setIndex(30);
        }
        if (res.length <= index && res.length !== 0) {
            setIndex(res.length);
            setComplete(true);
        }
    }, [res]);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedProducts = res.slice(startIndex, endIndex);

    return (
        <>
            <Helmet>
                <title>
                    {titre}
                </title>
                <meta
                    name="title"
                    content={titre}
                />
                <meta
                    name="description"
                    content={content}
                />

                <meta property="og:type" content="website"/>
                <meta
                    property="og:url"
                    content={url}
                />
                <meta
                    property="og:title"
                    content={titre}
                />
                <meta
                    property="og:description"
                    content={content}
                />
                <meta property="og:image" content={imgPreview}/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta
                    property="twitter:url"
                    content={url}
                />
                <meta
                    property="twitter:title"
                    content={titre}
                />
                <meta
                    property="twitter:description"
                    content={content}
                />
                <meta property="twitter:image" content={imgPreview}/>
            </Helmet>
            <Header
                title={fr.productTitle}
                subtitle={fr.productSubTitle}
                element={element}
                background={"product.jpg"}
                hauteur="70vh"
            />

            <section>
                <section className="page-section about-heading">
                    <div className="container">
                        <div
                            className="about-heading-content"
                            style={{marginTop: "2rem"}}
                        >
                            <div
                                className="row"
                                style={{textAlign: "justify"}}
                            >
                                <div className="col-xl-9 col-lg-10 mx-auto">
                                    <div className="bg-faded rounded p-5">
                                        <h2 className="section-heading mb-4">
                                            <span className="section-heading-upper">
                                                Page des
                                            </span>
                                            <span className="section-heading-lower">
                                                Produits
                                            </span>
                                        </h2>
                                        <p>
                                            Sur cette page vous pourrez
                                            retrouver l'ensemble des produits
                                            qui peuvent vous être proposés dans
                                            la boulangerie.
                                        </p>


                                        <span className="mb-0">
                                            <strong>Légende :</strong>
                                            <ul>
                                                <li>
                                                    <span className="text-primary">
                                                        Nouveau
                                                    </span>{" "}
                                                    : C'est une nouvelle
                                                    création
                                                </li>
                                                <li>
                                                    <span className="text-success">
                                                        Disponible
                                                    </span>{" "}
                                                    : Le produit est disponible
                                                    dans la mesure des stocks
                                                </li>
                                                <li>
                                                    <span className="text-danger">
                                                        Indisponible
                                                    </span>{" "}
                                                    : Le produit est
                                                    indisponible temporairement.
                                                </li>
                                                <li>
                                                    <span className="text-secondary">
                                                        Que le week-end :
                                                    </span>{" "}
                                                    Vous trouverez ce produits
                                                    exclusivements les week-ends
                                                    (toujours dans la mesure des
                                                    stocks).
                                                </li>
                                                <li>
                                                    <span className="text-secondary">
                                                        Sur commande :
                                                    </span>{" "}
                                                    Le produit est disponible
                                                    uniquement sur commande
                                                    (téléphone ou en boutique).
                                                </li>
                                            </ul>
                                        </span>
                                        <section className="d-flex justify-content-between align-items-center">
                                            {fr.productLegend.thanks}
                                            <Popconfirm title="Aide"
                                                        description="
                                                        Cette page n'est pas mise à jour
                                            en temps réel en fonction de stocks.
                                            Des informations sont mises à
                                            disposition afin de vous informer de
                                            ce que vous pouvez y retrouver. Les
                                            prix des produits peuvent ne pas
                                            correspondre à ce que vous pouvez
                                            retrouver en boutique. Seuls les
                                            prix en boutique font foi.
                                                        Recharger la page pour afficher les mises à jour"

                                                        onConfirm={() => window.location.reload()}
                                                        okText="Actualiser"
                                                        cancelText="Annuler"
                                            >

                                                <span className="btn text-success">

                                                    <i className="fas fa-info-circle" style={{cursor: "pointer"}}></i> Attention
                                                </span>
                                            </Popconfirm>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <SearchBarProducts setSearchTerm={setSearchTerm} setProduit={setProduit}
                                   setCurrentPage={setCurrentPage} produits={res} setLoadingProduct={setLoading}/>
                {res.length === 0 && !loading ? (
                    <h1 className="text-center">
                        {fr.productNotFind} {searchTerm}
                    </h1>
                ) : searchTerm !== "" &&
                    <h5 className="text-center text-white">
                        {fr.productFind} {searchTerm} :{" "}
                    </h5>
                }


                {!loading ?
                    <section className="py-5">
                        <div className="container px-4 px-lg-5 mt-5">
                            <div
                                className="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center">
                                {paginatedProducts.map((produit, index) => {
                                        if ((index + 1) % 27 === 0 && globalConfig.publicite) {
                                            return <AdsCard key={"pub" + produit.id}/>
                                        }
                                        return <ProductCard key={produit.id} produit={produit}/>

                                    }
                                )}
                            </div>
                        </div>
                    </section>
                    :
                    <section className="py-5">
                        <div className="container px-4 px-lg-5 mt-5">
                            <div
                                className="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center">
                                {[...Array(10)].map((_, index) =>
                                    <ProductCardLoading key={"loadingCards" + index}/>
                                )}

                            </div>
                        </div>
                    </section>
                }
                <article className="d-flex justify-content-center align-items-center">
                    <span
                        className="d-flex justify-content-center align-items-center bg-faded p-3 fw-bold rounded-pill w-75">
                        <Pagination
                            total={res.length}
                            showSizeChanger
                            showTotal={(total) => `${total} produits`}
                            onChange={(page, pageSize) => {
                                setCurrentPage(page);
                                setPageSize(pageSize);
                            }}
                        />
                    </span>
                </article>
            </section>
        </>
    );
};

export default PageProduits;

