import React, {useContext, useEffect, useState} from 'react';
import {message, Popconfirm, Spin, Switch, Table} from "antd";
import {GlobalContext} from "../layout/GlobalProvider";
import {API_URL} from "../../utils/constants";
import fr from "../../utils/FR.json";
import {QuestionCircleOutlined} from "@ant-design/icons";
import ModalEditPublication from "../publications/ModalEditPublication";
import ModalAddPublication from "../publications/ModalAddPublication";


function GestionPublications() {

    const [publications, setPublications] = useState([])
    const [loadingRows, setLoadingRows] = useState([]);

    const {token} = useContext(GlobalContext)

    async function getPublications() {
        try {
            const response = await fetch(API_URL + "afficher-publications-admin", {
                method: "GET",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                setPublications(res.data)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur s'est produite")
        }
    }

    async function removePublication(idPublication) {
        try {
            const response = await fetch(API_URL + "removePublication/" + idPublication, {
                method: "DELETE",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                getPublications()
                message.success(res.message)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur s'est produite")
        }
    }

    useEffect(() => {
        getPublications()
    }, [])

    const handleQuickChange = async (publicationId, checked) => {
        setLoadingRows((prevLoadingRows) => {
            return [...prevLoadingRows, publicationId]; // Ajouter l'ID à la liste des lignes en cours de chargement
        });
        // Mettez à jour l'état du bouton switch dans le composant
        const updatedProducts = publications.map((photo) => {
            if (photo.id === publicationId) {
                return {...photo, enabled: checked};
            }
            return photo;
        });
        setPublications(updatedProducts);
        try {
            // Effectuez une requête vers la base de données pour mettre à jour l'état du produit
            let data = new FormData();
            data.append("publicationId", publicationId);
            data.append("valeur", checked);
            const response = await fetch(API_URL + "quick-change-publication", {
                method: "POST",
                body: data,
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                await getPublications()
                message.success(res.message)
            } else {
                message.error(res.message)
            }

        } catch (error) {
            message.error(`Erreur lors de la mise à jour de l'état de la publication : ${publicationId}`);
        }
        setLoadingRows((prevLoadingRows) =>
            prevLoadingRows.filter((rowId) => rowId !== publicationId) // Supprimer l'ID de la liste des lignes en cours de chargement une fois la requête terminée
        );
    };

    const colonnes = [
        {
            title: 'Titre',
            dataIndex: 'titre',
            key: 'titre',
            sorter: (a, b) => a.titre.localeCompare(b.titre),
        },
        {
            title: 'Texte',
            dataIndex: 'texte',
            key: 'texte',
            sorter: (a, b) => a.texte.length - b.texte.length,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => a.type.localeCompare(b.type),
        },
        {
            title: 'Date de mise en ligne',
            dataIndex: 'datePublication',
            key: 'datePublication',
            sorter: (a, b) => new Date(a.datePublication) - new Date(b.datePublication),
            render: text => new Date(text).toDateString()
        },
        {
            title: 'Photos',
            dataIndex: 'photos',
            key: 'photos',
            render: (_, record) => {
                if (record.photos[0] === '') {
                    return "Pas d'image"
                }
                return <>
                    {record.photos.map((image) => {
                        return <img
                            key={image.filename}
                            src={API_URL + `/get_image/imagePublication/${record.dirPhoto}/` + image.filename}
                            alt={image.filename}
                            style={{width: '50px'}}/>
                    })}
                </>
            },
        },
        {
            title: 'Emplacement',
            dataIndex: 'dirPhoto',
            key: 'dirPhoto',
            sorter: (a, b) => a.dirPhoto.localeCompare(b.dirPhoto),
        },
        {
            title: 'Affiché',
            dataIndex: 'display',
            key: 'display',
            render: (enabled, record) => (
                <Spin spinning={loadingRows.includes(record.id)}>
                    <Switch
                        checked={enabled}
                        onChange={(checked) => handleQuickChange(record.id, checked, "set-display")}
                    />
                </Spin>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <div>
                    <Popconfirm title="Action irréversible"
                                description="Êtes-vous sur de supprimer cette publication ?"
                                onConfirm={() => removePublication(record.id)}
                                icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                                cancelText="Annuler"
                                okText="Supprimer"
                    >
                        <button className="btn btn-danger">
                            <i className="bi bi-trash"></i> {fr.buttonRemove}
                        </button>
                    </Popconfirm>
                    <ModalEditPublication getPublications={getPublications} publication={record}>
                        <i className="bi bi-pencil"></i> {fr.buttonEdit}
                    </ModalEditPublication>
                </div>
            ),
        },
    ];

    return (
        <>
            <h2 className="text-primary text-center fw-bold">Publications</h2>
            <div className="d-flex justify-content-center align-items-center gap-3 my-3">
                <ModalAddPublication getPublications={getPublications}>Ajouter une publication</ModalAddPublication>
            </div>
            <h3 className="text-primary text-center">{publications.length} publications</h3>
            <div className="table-responsive">
                <Table
                    rowKey="id"
                    dataSource={publications}
                    columns={colonnes}
                    loading={publications.length < 1}

                />
            </div>
        </>
    );
}

export default GestionPublications;