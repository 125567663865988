import {useEffect, useState} from "react";
import jwt_decode from "jwt-decode";
import {API_URL} from "../../utils/constants";

export default function useToken() {
    function getToken() {
        try {
            const token = localStorage.getItem("token")
            jwt_decode(token)
            return token;
        } catch (e) {
            localStorage.removeItem("token")
            return null
        }
    }

    async function isValidToken() {
        const response = await fetch(API_URL + "is-logged",
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
        );
        const res = await response.json();
        if (res.status === 200) {
            return false
        }
        return false
    }

    const [token, setToken] = useState(getToken());

    function saveToken(userToken) {
        localStorage.setItem("token", userToken);
        setToken(userToken);
    }

    function removeToken() {
        localStorage.removeItem("token");
        setToken(null);
        setRole(null);
    }

    const [role, setRole] = useState(null);

    function getRole() {
        try {
            setRole(jwt_decode(token)["role"]);
        } catch (err) {
            setRole(null);
        }
    }

    useEffect(() => {
        getRole();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, role]);

    return {
        setToken: saveToken,
        token,
        removeToken,
        role,
        isValidToken,
    };
}
