import React, {useState} from 'react';
import {API_URL} from '../../utils/constants';
import ImageListItem from '@mui/material/ImageListItem';
import {Modal} from 'antd';

const ModalPhoto = ({photo}) => {
    const [visible, setVisible] = useState(false);

    const showModal = () => {
        setVisible(true);
    };

    const hideModal = () => {
        setVisible(false);
    };

    const imageUrl = `${API_URL}get_image/imagePhoto/${photo.photo}`;

    return (
        <>
            <ImageListItem>
                <div className="position-relative mt-auto">
                    <div
                        className="badge bg-dark text-dark position-absolute"
                        style={{
                            top: '0.5rem',
                            left: '0.5rem',
                        }}
                    >
                        <i
                            style={{
                                color: 'white',
                                cursor: 'pointer',
                            }}
                            onClick={showModal}
                            className="bi bi-arrows-angle-expand fa-1x"
                        ></i>
                    </div>
                </div>
                <img
                    src={`${imageUrl}?w=248&fit=crop&auto=format`}
                    srcSet={`${imageUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={photo.titre}
                    className="rounded"
                    loading="lazy"
                    onClick={showModal}
                    style={{cursor: 'pointer'}}
                />
            </ImageListItem>

            <Modal
                open={visible}
                onCancel={hideModal}
                footer={null}
                title={photo.titre}
                width={700}
                centered
            >
                <img
                    src={imageUrl}
                    alt={photo.titre}
                    style={{width: '100%', borderRadius: '30px'}}
                />
            </Modal>
        </>
    );
};

export default ModalPhoto;
