import React, {useContext, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {API_URL} from "../../utils/constants";
import fr from "../../utils/FR.json";
import {GlobalContext} from "../layout/GlobalProvider";
import {message, Spin} from "antd";
import UploadPreview from "../gestion/UploadPreview";


const ModalAddPieceMontee = ({children, getPiecesMontees}) => {
    const [show, setShow] = useState(false);
    const toggle = () => setShow(!show);
    const {token} = useContext(GlobalContext)

    const [titre, setTitre] = useState("");
    const [categorie, setCategorie] = useState("Mariage");
    const [photo, setPhoto] = useState({image: null});
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (photo.image === null) {
            message.warning("Veuillez mettre une photo");
            setLoading(false)
        } else {
            let data = new FormData();
            data.append("titre", titre);
            data.append("categorie", categorie);
            data.append("photo", photo.image);

            const Upload = async () => {
                try {
                    const response = await fetch(API_URL + "addPieceMontee", {
                        method: "POST",
                        headers: {Authorization: "Bearer " + token},
                        body: data,
                    })
                    const res = await response.json()
                    if (res.status === 200) {
                        toggle()
                        getPiecesMontees()
                        message.success(res.message)
                    } else {
                        message.error(res.message)
                    }
                } catch (e) {
                    message.error("Une erreur est survenue")
                }
                setLoading(false)
            };
            Upload();
        }
    };

    return (
        <>
            <button className="btn btn-outline-primary" onClick={toggle}>
                <i className="bi bi-plus-lg"></i> {children}
            </button>

            <Modal show={show} onHide={toggle}>
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une pièce montée</Modal.Title>
                </Modal.Header>
                <Spin spinning={loading} tip="Ajout de la pièce montée en cours ...">
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <label className="fw-bold">Titre</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Entrer le titre de la pièce montée"
                                value={titre}
                                onChange={(e) => setTitre(e.target.value)}
                            ></input>
                            <label className="fw-bold">Catégorie</label>
                            <Form.Select
                                value={categorie}
                                onChange={(e) => setCategorie(e.target.value)}
                            >
                                {Object.entries(fr.categoriePieceMontee).map(
                                    (elem, i) => {
                                        return <option key={i}>{elem[1]}</option>;
                                    }
                                )}
                            </Form.Select>
                            <label className="fw-bold">Image</label>
                            <UploadPreview onProduitDataChange={setPhoto}/>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={toggle}>
                            {fr.buttonClose}
                        </Button>

                        <Button variant="primary" onClick={handleSubmit}>
                            <i className="bi bi-plus-lg"></i> {fr.buttonAdd}
                        </Button>

                    </Modal.Footer>
                </Spin>
            </Modal>
        </>
    );
};

export default ModalAddPieceMontee;
