import React from 'react';
import {Card} from "antd";
import fr from "../../../utils/FR.json";
import {API_URL} from "../../../utils/constants";

function InfoGeneralProduit({produit, globalConfig}) {
    return (
        <>
            <div style={{position: 'relative'}}>
                {produit.nouveau &&
                    <div className="badge bg-warning text-dark position-absolute"
                         style={{bottom: "0.5rem", right: "0.5rem"}}>
                        Nouveau
                    </div>
                }
                {produit.disponibilite ===
                fr.disponibilite.disponible ? null : produit
                    .disponibilite ===
                fr.disponibilite.weekend ||
                produit.disponibilite ===
                fr.disponibilite.commande ? (
                    <div
                        className="badge bg-dark text-white position-absolute"
                        style={{
                            top: "0.5rem",
                            left: "0.5rem",
                        }}
                    >
                        {produit.disponibilite}
                    </div>
                ) : (
                    <div
                        className="badge bg-danger text-white position-absolute"
                        style={{
                            top: "0.5rem",
                            left: "0.5rem",
                        }}
                    >
                        Indisponible
                    </div>
                )}
                <img
                    src={produit.image !== 'none.png' ? API_URL + "get_image/imageProduit/" + produit.image : require("../../../img/loading.jpg")}
                    alt={produit.nom}
                    style={{width: '100%', borderRadius: "10px"}}
                    loading="lazy"
                />
            </div>
            <div className="d-flex justify-content-center m-2 fw-bold align-items-center gap-3">
                <div
                    className="d-inline-block border w-25 bg-primary border-primary rounded-pill px-3 text-center"
                >

                    {produit.type}

                </div>
                {globalConfig.displayPrice &&
                    <div
                        className="d-inline-block border w-25 bg-faded border-primary rounded-pill px-3 text-center">
                        <i className="bi bi-tag-fill"></i> {produit.prix}
                        {produit.mesurePrix === "unitaire"
                            ? "€"
                            : "€/kg"}
                    </div>}
                <div
                    className="d-inline-block border w-25 bg-primary border-primary rounded-pill px-3 text-center">
                    {produit.minPers}{" "}
                    <i className="bi bi-people-fill"></i>{" "}
                    {produit.maxPers !== 1
                        && produit.maxPers}
                </div>
            </div>
            <Card>
                <article className="d-flex row gap-1">
                    <span><strong>Spécialité : </strong> {produit.specialite}</span>
                    <span><strong>Description : </strong> {produit.description}</span>
                </article>
            </Card>
        </>
    );
}

export default InfoGeneralProduit;