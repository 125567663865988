import React, {useContext, useEffect, useState} from "react";
import {API_URL} from "../../utils/constants";
import {Button, Checkbox, message, Popconfirm, Spin, Switch, Table} from "antd";
import ModalEditProduit from "../produits/modalAddEditProduit/ModalEditProduit";
import {DeleteOutlined, QuestionCircleOutlined, RestOutlined} from "@ant-design/icons";
import ModalAddProduit from "../produits/modalAddEditProduit/ModalAddProduit";
import ModalEditPrice from "../produits/ModalEditPrice";
import ModalAddWatermarks from "./ModalAddWatermarks";
import {GlobalContext} from "../layout/GlobalProvider";
import SearchBarGestion from "./SearchBarGestion";
import ModalDetails from "../produits/modalDetails/modalDetails";


const GestionProduits = () => {
    const {token} = useContext(GlobalContext)
    const [produits, setProduits] = useState([]);
    const [loadingRows, setLoadingRows] = useState([]);
    const [filteredProduits, setFilteredProduits] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);


    async function getProduits() {
        try {
            const response = await fetch(API_URL + `get-products-admin`, {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            const res = await response.json()
            if (res.status !== 200) {
                message.error(res.message)
                setProduits([])
            } else {
                setProduits(res.data)
            }
        } catch (e) {
            message.error("Une erreur est survenue")
        }
    }

    async function remove(id) {
        try {
            const response = await fetch(API_URL + "removeProduct/" + id, {
                method: "DELETE",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status !== 200) {
                message.error(res.message)
            } else {
                getProduits()
                message.success(res.message)
            }
        } catch (e) {
            message.error("Une erreur est survenue")
        }

    }

    async function massiveRemove() {
        let data = new FormData()
        for (let produitId of selectedProducts) {
            data.append("produitsId[]", produitId)
        }
        try {
            const response = await fetch(API_URL + "massive-remove-products", {
                method: "DELETE",
                headers: {
                    Authorization: "Bearer " + token,
                },
                body: data
            })
            const res = await response.json()
            if (res.status !== 200) {
                message.error(res.message)
            } else {
                getProduits()
                message.success(res.message)
            }
        } catch (e) {
            message.error("Une erreur est survenue")
        }

    }

    const handleQuickChange = async (produitId, checked, route) => {
        setLoadingRows((prevLoadingRows) => {
            return [...prevLoadingRows, produitId]; // Ajouter l'ID à la liste des lignes en cours de chargement
        });
        // Mettez à jour l'état du bouton switch dans le composant
        const updatedProducts = produits.map((product) => {
            if (product.id === produitId) {
                return {...product, enabled: checked};
            }
            return product;
        });
        setProduits(updatedProducts);
        try {
            // Effectuez une requête vers la base de données pour mettre à jour l'état du produit
            let data = new FormData();
            data.append("produitId", produitId);
            data.append("valeur", checked);
            data.append("attribut", route);
            const response = await fetch(API_URL + "quick-change", {
                method: "POST",
                body: data,
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                await getProduits()
                message.success(res.message)
            } else {
                message.error(res.message)
            }

        } catch (error) {
            message.error(`Une erreur est survenue : ${produitId}`);
        }
        setLoadingRows((prevLoadingRows) =>
            prevLoadingRows.filter((rowId) => rowId !== produitId) // Supprimer l'ID de la liste des lignes en cours de chargement une fois la requête terminée
        );
    };

    useEffect(() => {
        getProduits();
    }, []);

    useEffect(() => {
        setFilteredProduits([...produits]); // Initialiser les produits filtrés avec la liste complète des produits
    }, [produits]);


    const colonnes = [
        {
            title: 'Nom',
            dataIndex: 'nom',
            key: 'nom',
            sorter: (a, b) => a.nom.localeCompare(b.nom),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => a.type.localeCompare(b.type),
        },
        {
            title: 'Prix (€)',
            dataIndex: 'prix',
            key: 'prix',
            sorter: (a, b) => a.prix - b.prix
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: (a, b) => a.description.length - b.description.length
        },
        {
            title: 'Spécialité',
            dataIndex: 'specialite',
            key: 'specialite',
            sorter: (a, b) => a.specialite.localeCompare(b.specialite),
        },
        {
            title: 'Disponibilité',
            dataIndex: 'disponibilite',
            key: 'disponibilite',
        },
        {
            title: 'Nouveauté',
            dataIndex: 'nouveau',
            key: 'nouveau',
            render: (checked, record) => (
                <Spin spinning={loadingRows.includes(record.id)}>
                    <Checkbox checked={checked}
                              onChange={(checked) => handleQuickChange(record.id, checked.target.checked, "set-new")}>

                    </Checkbox>
                </Spin>
            ),
        },
        {
            title: 'Affiché',
            dataIndex: 'display',
            key: 'display',
            render: (enabled, record) => (
                <Spin spinning={loadingRows.includes(record.id)}>
                    <Switch
                        checked={enabled}
                        onChange={(checked) => handleQuickChange(record.id, checked, "set-display")}
                    />
                </Spin>
            ),
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (image) => {
                if (image === "none.png") {
                    return "Pas d'image"
                }
                return <img src={API_URL + "/get_image/imageProduit/" + image} alt="Produit"
                            style={{width: '50px'}} loading="lazy"/>
            },
        },
        {
            title: 'Gestion',
            dataIndex: 'gestion',
            key: 'gestion',
            render: (text, record) => (
                <div className="d-flex justify-content-center align-items-center gap-1">
                    <Popconfirm title="Action irréversible"
                                description="Êtes-vous sur de supprimer ce produit ?"
                                onConfirm={() => remove(record.id)}
                                icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                    >
                        <button
                            className="btn btn-danger"
                            style={{margin: "3px"}}
                        >
                            <i className="bi bi-trash"></i> Supprimer
                        </button>
                    </Popconfirm>
                    <ModalEditProduit
                        token={token}
                        produit={record}
                        getProduits={getProduits}
                    >
                        <i className="bi bi-pencil"></i> Modifier
                    </ModalEditProduit>
                </div>
            ),
        },
    ];

    const ligneSup = (record) => {
        return (
            <div className="d-flex justify-content-between">
                <span>id : {record.id}</span>
                <span>Personnes : {record.minPers}/{record.maxPers}</span>
                <span>Mesure : {record.mesurePrix}</span>
                <span>
                    Allergène : {record.allergenes.filter(allergene => allergene.is_present).map(allergene => allergene.allergene).join(", ") || "Aucun"}
                </span>
                <span>
                    Formes : {record.formes.filter(forme => forme.is_present).map(forme => forme.forme).join(", ") || "Aucun"}
                </span>
                <span>
                    Décors : {record.decors.filter(decor => decor.is_present).map(decor => decor.nappage).join(", ") || "Aucun"}
                </span>
                <span>Historique des prix : {record.price_history.map(allergene => allergene.prix).join(", ")}</span>


            </div>
        )
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedProducts(selectedRows.map(row => row.id)); // Ajout de cette ligne
        },
        onSelect: (record, selected, selectedRows) => {
            console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected, selectedRows, changeRows);
        },
    };

    return (
        <>
            <h2 className="text-primary text-center fw-bold">Liste des produits</h2>
            <div className="d-flex justify-content-center align-items-center gap-3 my-3">
                <ModalAddProduit getProduits={getProduits}>Ajouter un produit</ModalAddProduit>
                <ModalDetails getProduits={getProduits}>Détails des produits</ModalDetails>
                <ModalAddWatermarks token={token}>
                    Actualiser les filigrannes
                </ModalAddWatermarks>
            </div>
            <div className="table-responsive">
                <h3 className="text-primary text-center">{produits.length} produits</h3>
                {selectedProducts.length > 0 &&
                    <span className="d-flex justify-content-center align-items-center gap-3">
                        <ModalEditPrice token={token} action="augmentation" getProduits={getProduits}
                                        selectedProducts={selectedProducts}/>
                        <ModalEditPrice token={token} action="diminution" getProduits={getProduits}
                                        selectedProducts={selectedProducts}/>
                        <Popconfirm title="Action irréversible !"
                                    description={`Vous êtes sur le point de supprimer ${selectedProducts.length} produits`}
                                    icon={<QuestionCircleOutlined style={{color: 'red'}}/>} okText="Supprimer"
                                    cancelText="Annuler" onConfirm={massiveRemove}>
                            <Button
                                danger type="primary" icon={<DeleteOutlined/>}
                                shape="round">Supprimer</Button>
                        </Popconfirm>
                        <Button
                            type="primary" icon={<RestOutlined/>} onClick={() => setSelectedProducts([])}
                            shape="round">Désélctionner tout</Button>
                    </span>
                }
                <SearchBarGestion produits={produits} setFilteredProduits={setFilteredProduits}/>
                {selectedProducts.length > 0 && <span
                    className="fw-bold d-flex justify-content-center align-items-center gap-3">{selectedProducts.length} produits séléctionnés</span>}
                <Table
                    rowKey="id"
                    dataSource={filteredProduits}
                    columns={colonnes}
                    loading={produits.length < 1}
                    expandable={{
                        expandedRowRender: ligneSup,
                    }}
                    rowSelection={{
                        ...rowSelection
                    }}
                />

            </div>
        </>
    );
};

export default GestionProduits;