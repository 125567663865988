import React, {useEffect, useState} from 'react';
import {Slider} from "@mui/material";
import fr from "../../utils/FR.json";
import {API_URL} from "../../utils/constants";
import {AutoComplete, InputNumber, message, Popover, Select, Spin, Tooltip} from "antd";

function SearchBarProducts({
                               setProduit,
                               setSearchTerm,
                               setCurrentPage,
                               produits,
                               setLoadingProduct
                           }) {

    const [filter, setFilter] = useState({
        ordre: 'default',
        valeurNombrePersonne: 'all',
        nombrePersonne: 1,
        nouveau: 'all',
        disponibilite: 'all',
        type: 'all',
        specialite: 'all',
        prix: [0, 100],
    });

    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [haveFilter, setHaveFilter] = useState(false);

    const handleSelect = (value, option) => {
        setSearchTerm(value);
    };

    const handleSearchTerm = (value) => {
        const searchList = [...produits]
        setSearchTerm(value);
        setCurrentPage(1)

        setOptions(value)
        const filteredOptions = searchList.filter((produit) =>
            produit.nom.toLowerCase().includes(value.toLowerCase())
        );

        // Transformez les produits filtrés en options formatées pour AutoComplete
        const autocompleteOptions = filteredOptions.map((produit) => ({
            value: produit.nom,
            label: produit.nom,
        }));
        setOptions(autocompleteOptions);
    };

    const reset = () => {
        setFilter({
            ordre: 'default',
            valeurNombrePersonne: "all",
            nombrePersonne: '1',
            nouveau: 'all',
            disponibilite: 'all',
            type: 'all',
            specialite: 'all',
            prix: [0, 100],
        })
    };
    const getProduitFilter = async () => {
        setLoading(true)
        setLoadingProduct(true)

        try {
            const params = new URLSearchParams();
            params.append("ordre", filter.ordre);
            params.append("type", filter.type);
            params.append("specialite", filter.specialite);
            params.append(
                "nombrePersonne",
                filter.valeurNombrePersonne === "all" ? "all" : filter.nombrePersonne
            );
            params.append("nouveau", filter.nouveau);
            params.append("disponibilite", filter.disponibilite);
            params.append("prixMin", filter.prix[0]);
            params.append("prixMax", filter.prix[1]);
            const response = await
                fetch(`${API_URL}products?${params.toString()}`)
            const res = await response.json()
            if (res.status === 200) {
                setProduit(res.data)
            } else {
                message.error(res.message)
            }
            setHaveFilter(false);
        } catch (e) {
            message.error("Une erreur est survenue")
        } finally {
            setLoading(false)
            setLoadingProduct(false)
        }
    };

    useEffect(() => {
        getProduitFilter()
    }, [filter.ordre])

    const handleChange = (newData) => {
        setFilter((prevData) => ({...prevData, ...newData}));
    };

    return (
        <article className="d-flex justify-content-center align-items-center">
            <div
                className="bg-light p-3 rounded w-75"
            >
                <h3>
                    <Popover title="Recherche automatique" content="Il n'y a pas de bouton pour valider la recherche">
                        <i className="bi bi-search"></i> {fr.productSearch}
                    </Popover>
                </h3>

                <div className="d-flex justify-content-center align-items-center gap-3">
                    <AutoComplete
                        options={options}
                        onSelect={handleSelect}
                        onSearch={handleSearchTerm}
                        className="col-10"
                    >
                        <input
                            type="text"
                            className="form-control rounded-pill"
                            placeholder="Rechercher..."
                        ></input>
                    </AutoComplete>
                    <Tooltip title="Filtrer par">
                        <button
                            className={
                                !haveFilter
                                    ? "btn btn-secondary"
                                    : "btn btn-primary"
                            }
                            onClick={() =>
                                setHaveFilter(!haveFilter)

                            }
                        >
                            <i className="bi bi-funnel"></i> Filtre <i
                            className={`bi bi-chevron-${haveFilter ? "up" : "down"}`}></i>
                        </button>
                    </Tooltip>

                </div>
                <Spin spinning={loading} tip="Récupération des produits en cours ...">
                    <div className="d-flex justify-content-center align-items-center gap-1 my-2">
                        <i className="bi bi-sort-up"></i><label>Trier par :</label>
                        <Select
                            value={filter.ordre}
                            style={{width: 120}}
                            onChange={(value) => handleChange({ordre: value})}
                            loading={loading}
                            options={[
                                {value: 'default', label: 'Par défaut'},
                                {value: 'nouveau', label: 'Nouveauté'},
                                {
                                    label: 'Nom',
                                    options: [
                                        {
                                            label: 'A à Z',
                                            value: 'aaz',
                                        },
                                        {
                                            label: 'Z à A',
                                            value: 'zaa',
                                        },
                                    ],
                                },
                                {
                                    label: 'Prix',
                                    options: [
                                        {
                                            label: 'Croissant',
                                            value: 'croissant',
                                        },
                                        {
                                            label: 'Décroissant',
                                            value: 'decroissant',
                                        },
                                    ],
                                },
                            ]}
                        />

                    </div>
                    {haveFilter &&
                        <>
                            <h4 className="text-dark">Filtrer par :</h4>
                            <div className="d-flex flex-wrap m-2 align-items-center justify-content-center">
                                <div className="g-col-6 g-col-md-4 m-2">
                                    <label className="me-2">Nouveauté</label>
                                    <Select
                                        value={filter.nouveau}
                                        style={{width: 120}}
                                        onChange={(value) => handleChange({nouveau: value})}
                                        options={[
                                            {value: 'all', label: 'Toutes'},
                                            {value: 'nouveau', label: 'Nouveau'},
                                            {value: 'ancien', label: 'Pas nouveau'},
                                        ]}
                                    />
                                </div>
                                <div className="g-col-6 g-col-md-4 m-2">
                                    <label className="me-2">Disponibilité</label>
                                    <Select
                                        value={filter.disponibilite}
                                        style={{width: 150}}
                                        onChange={(value) => handleChange({disponibilite: value})}
                                        options={[
                                            {value: 'all', label: 'Toutes'},
                                            {value: 'disponible', label: 'Disponible'},
                                            {value: 'indisponible', label: 'Indisponible'},
                                            {value: 'weekends', label: 'Que les week-ends'},
                                            {value: 'commande', label: 'Sur commande'},
                                        ]}
                                    />
                                </div>

                                <div className="g-col-6 g-col-md-4 m-2">
                                    <label className="me-2">
                                        Nombre de personnes
                                    </label>
                                    <Select value={filter.valeurNombrePersonne}
                                            style={{width: 120}} onChange={(e) => {
                                        e === 'all' ? handleChange({valeurNombrePersonne: 'all'}) : handleChange({valeurNombrePersonne: "number"})
                                    }}
                                            options={[
                                                {value: 'all', label: 'Tous'},
                                                {value: 'number', label: 'Nombre'},
                                            ]}>
                                    </Select>
                                    {filter.valeurNombrePersonne !== "all" && (
                                        <>
                                            <label>
                                                Combien ?
                                            </label>
                                            <InputNumber
                                                min={1}
                                                value={filter.nombrePersonne}
                                                onChange={(value) => handleChange({nombrePersonne: value})}
                                                style={{marginLeft: 10}}
                                            /></>
                                    )}
                                </div>
                                <div className="g-col-6 g-col-md-4 m-2">
                                    <label className="me-2">Type</label>
                                    <Select
                                        value={filter.type}
                                        style={{width: 120}}
                                        onChange={(value) => handleChange({type: value})}
                                        options={[
                                            {value: 'all', label: 'Tous'},
                                            {value: 'boulangerie', label: 'Boulangerie'},
                                            {value: 'patisserie', label: 'Pâtisserie'},
                                            {value: 'viennoiserie', label: 'Viennoiserie'},
                                            {value: 'traiteur', label: 'Traiteur'},
                                            {value: 'sucrerie', label: 'Sucrerie'},
                                            {value: 'chocolaterie', label: 'Chocolaterie'},
                                            {value: 'snackerie', label: 'Snackerie'},
                                            {value: 'boisson', label: 'Boisson'},
                                            {value: 'revente', label: 'Revente'},
                                            {value: 'autre', label: 'Autre'},
                                        ]}
                                    />
                                </div>
                                <div className="g-col-6 g-col-md-4 m-2">
                                    <label className="me-2">Spécialité</label>
                                    <Select
                                        value={filter.specialite}
                                        style={{width: 120}}
                                        onChange={(value) => handleChange({specialite: value})}
                                        options={[
                                            {value: 'all', label: 'Toutes'},
                                            {value: 'aucune', label: 'Aucune'},
                                            {value: 'francaise', label: 'Française'},
                                            {value: 'russe', label: 'Russe'},
                                            {value: 'noel', label: 'Noël'},
                                            {value: 'galette', label: 'Galette'},
                                        ]}
                                    />
                                </div>
                                <div className="g-col-6 g-col-md-4 m-2">
                                    <label className="me-2">Tranche de prix</label>

                                    <Slider
                                        min={0}
                                        max={100}
                                        className="text-primary"
                                        getAriaLabel={() =>
                                            "Fourchette de prix"
                                        }
                                        value={filter.prix}
                                        onChange={(e, value) => handleChange({prix: value})}
                                        valueLabelDisplay="auto"
                                        getAriaValueText={(e) => {
                                            return `${e}€`;
                                        }}
                                        marks={[
                                            {value: 0, label: '0€'},
                                            {value: 100, label: '100€'},
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button
                                    className="btn btn-danger m-1"
                                    onClick={() => reset()}
                                >
                                    <i className="bi bi-arrow-clockwise"></i> Réinitialiser le filtre
                                </button>
                                <button
                                    className="btn btn-success m-1"
                                    onClick={getProduitFilter}
                                >
                                    <i className="bi bi-check-lg"></i> Valider la recherche
                                </button>
                            </div>
                        </>
                    }
                </Spin>
            </div>
        </article>
    );
}

export default SearchBarProducts;
