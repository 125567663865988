import React, {useState} from "react";
import {Button, Upload} from "antd";
import {UploadOutlined} from '@ant-design/icons';

function MultiUploadPreview({setPhotos}) {
    const [fileList, setFileList] = useState([]);

    const handleFileChange = (e) => {
        let fileList = [...e.fileList];
        setFileList(fileList);
        if (fileList.length > 0) {
            setPhotos(fileList);
        }
    };


    return (
        <Upload
            listType="picture"
            className="d-flex"
            fileList={fileList}
            multiple={true}
            beforeUpload={() => false}
            onChange={handleFileChange}
        >
            <Button icon={<UploadOutlined/>}>Télécharger des images</Button>
        </Upload>
    );
}

export default MultiUploadPreview;


