import React, {useContext, useEffect, useState} from "react";
import jwt_decode from "jwt-decode";
import ModalEditUtilisateur from "../connexion/ModalEditUtilisateur";
import {API_URL} from "../../utils/constants";
import fr from "../../utils/FR.json";
import {message, Popconfirm, Spin, Switch, Table} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {GlobalContext} from "../layout/GlobalProvider";
import ModalAddUtilisateur from "../connexion/ModalAddUtilisateur";
import GestionConnexions from "./GestionConnexions";

const GestionUtilisateurs = () => {
    const [users, setUsers] = useState([]);
    const [loadingRows, setLoadingRows] = useState([]);
    const {token} = useContext(GlobalContext)

    async function remove(id) {
        try {
            const response = await fetch(API_URL + "removeUser/" + id, {
                method: "DELETE",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                getData()
                message.success(res.message)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur s'est produite")
        }
    }

    async function resetAttempts(id) {
        try {
            const data = new FormData()
            data.append("idUser", id)
            const response = await fetch(API_URL + "reset-attempts", {
                method: "PUT",
                headers: {Authorization: "Bearer " + token},
                body: data
            })
            const res = await response.json()
            if (res.status === 200) {
                getData()
                message.success(res.message)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur s'est produite")
        }
    }

    const colonnes = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'nom',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Nom',
            dataIndex: 'nom',
            key: 'nom',
            sorter: (a, b) => a.type.localeCompare(b.type),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.type.localeCompare(b.type)
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => a.type.localeCompare(b.type)
        },
        {
            title: 'Etat',
            dataIndex: 'activer',
            key: 'activer',
            render: (enabled, record) => (
                <Spin spinning={loadingRows.includes(record.id)}>
                    <Switch
                        checked={enabled}
                        onChange={(checked) => handleQuickChange(record.id, checked, "set-display")}
                    />
                </Spin>
            ),
        },
        {
            title: 'Dernière connexion',
            dataIndex: 'lastLogin',
            key: 'lastLogin',
            sorter: (a, b) => new Date(a.lastLogin) - new Date(b.lastLogin)
        },
        {
            title: 'Nombre de tentative echecs',
            dataIndex: 'login_attempts',
            key: 'login_attempts',
            sorter: (a, b) => a.login_attempts - b.login_attempts
        },
        {
            title: 'Prochaine tentative',
            dataIndex: 'next_login_datetime',
            key: 'next_login_datetime',
            sorter: (a, b) => new Date(a.next_login_datetime) - new Date(b.next_login_datetime)
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <div>
                    <Popconfirm title="Action irréversible"
                                description="Êtes-vous sur de supprimer cet utilisateur ?"
                                onConfirm={() => remove(record.id)}
                                icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                                cancelText="Annuler"
                                okText="Supprimer"
                    >
                        <button
                            className={
                                jwt_decode(token)["sub"] === record.nom
                                    ? "btn btn-danger disabled"
                                    : "btn btn-danger"
                            }
                            style={{margin: "3px"}}
                        >
                            <i className="bi bi-trash"></i> {fr.buttonRemove}
                        </button>
                    </Popconfirm>
                    <ModalEditUtilisateur getUtilisateurs={getData} user={record}>
                        <i className="bi bi-pencil"></i> {fr.buttonEdit}
                    </ModalEditUtilisateur>
                    <Popconfirm title="On reset ?"
                                description="Êtes-vous sur de réinitialiser les tentatives de cet utilisateur ?"
                                onConfirm={() => resetAttempts(record.id)}
                                cancelText="Annuler"
                                okText="Réinitialiser"
                    >
                        <button className="btn btn-primary"
                        >
                            <i className="bi bi-trash"></i> Réinitialiser
                        </button>
                    </Popconfirm>
                </div>
            ),
        },
        // {
        //     title: 'Image',
        //     dataIndex: 'image',
        //     key: 'image',
        //     render: (image) => {
        //         if (image === "none.png") {
        //             return "Pas d'image"
        //         }
        //         return <img src={API_URL + "/get_image/imageProduit/" + image} alt="Produit"
        //                     style={{width: '50px'}}/>
        //     },
        // },
    ];


    const handleQuickChange = async (userId, checked) => {
        setLoadingRows((prevLoadingRows) => {
            return [...prevLoadingRows, userId]; // Ajouter l'ID à la liste des lignes en cours de chargement
        });
        // Mettez à jour l'état du bouton switch dans le composant
        const updatedUsers = users.map((user) => {
            if (user.id === userId) {
                return {...user, enabled: checked};
            }
            return user;
        });
        setUsers(updatedUsers);
        try {
            // Effectuez une requête vers la base de données pour mettre à jour l'état du produit
            let data = new FormData();
            data.append("userId", userId);
            data.append("etat", checked);
            const response = await fetch(API_URL + "quick-change-user", {
                method: "POST",
                body: data,
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                await getData()
                message.success(res.message)
            } else {
                message.error(res.message)
            }
        } catch (error) {
            message.error(`Erreur lors de la mise à jour de l'état de l'utilisateur : ${userId}`);
        }
        setLoadingRows((prevLoadingRows) =>
            prevLoadingRows.filter((rowId) => rowId !== userId) // Supprimer l'ID de la liste des lignes en cours de chargement une fois la requête terminée
        );
    };

    async function getData() {
        try {
            const response = await fetch(API_URL + "get-users-admin", {
                method: "GET",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                setUsers(res.data)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Récupération des utilisateurs échoué")
        }


    }

    useEffect(() => {
        getData();
    }, []);

    const ligneSup = (record) => {
        return (
            <p
                style={{
                    margin: 0,
                }}
            >
                Max : {record.maxPers},
                Min : {record.minPers},
                Mesure du prix : {record.mesurePrix}
            </p>
        )
    }

    return (
        <>
            <h2 className="text-primary text-center fw-bold">Utilisateurs</h2>
            <div className="d-flex justify-content-center gap-3 my-3">
                <ModalAddUtilisateur getUtilisateurs={getData}>
                    Ajouter un utilisateur
                </ModalAddUtilisateur>
            </div>
            <h3 className="text-primary text-center">{users.length} utilisateurs</h3>
            <div className="table-responsive">
                <Table
                    rowKey="id"
                    dataSource={users}
                    columns={colonnes}
                    loading={users.length < 1}
                    expandable={{
                        expandRowByClick: true,
                        expandedRowRender: ligneSup,

                    }}
                />
            </div>
            <GestionConnexions/>
        </>
    )
};


export default GestionUtilisateurs;
