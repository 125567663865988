// import { render } from "@testing-library/react";
import React from "react";
import {hydrate, render} from "react-dom";
// import "bootstrap/dist/css/bootstrap.css";
import App from "./App.js";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(<App/>, rootElement);
} else {
    render(<App/>, rootElement);
}
// ReactDOM.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>,
//     document.getElementById("root")
// );
