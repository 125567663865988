import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import React, {useEffect, useState} from "react";
import Header from "../components/layout/Header";
import {API_URL} from "../utils/constants";
import fr from "../utils/FR.json";
import {Helmet} from "react-helmet";
import {message} from "antd";
import ModalPhoto from "../components/photos/ModalPhoto";


const titre = "Galerie Photo - Boulangerie Pâtisserie l'Épi d'Or"
const url = "https://boulangerielepidor.fr/GaleriePhoto"
const content = "Découvrez en images les délicieux gâteaux confectionnés par la boulangerie pâtisserie l'Épi d'Or à Orléans. Un assortiment de photos pour ravir vos yeux et vos papilles !"
const imgPreview = "preview/Photo.png"

function PagePhoto({element, token, role}) {
    const [photos, setPhotos] = useState([]);

    const getPhotos = async () => {
        try {
            const response = await fetch(API_URL + "afficherPhoto", {
                method: "GET",
            })
            const res = await response.json()
            if (res.status === 200) {
                setPhotos(res.data);
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur est survenue")
        }
    };

    useEffect(() => {
        getPhotos();
    }, []);


    return (
        <>
            <Helmet>
                <title>
                    {titre}
                </title>
                <meta
                    name="title"
                    content={titre}
                />
                <meta
                    name="description"
                    content={content}
                />

                <meta property="og:type" content="website"/>
                <meta
                    property="og:url"
                    content={url}
                />
                <meta
                    property="og:title"
                    content={titre}
                />
                <meta
                    property="og:description"
                    content={content}
                />
                <meta property="og:image" content={imgPreview}/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta
                    property="twitter:url"
                    content={url}
                />
                <meta
                    property="twitter:title"
                    content={titre}
                />
                <meta
                    property="twitter:description"
                    content={content}
                />
                <meta property="twitter:image" content={imgPreview}/>
            </Helmet>
            <Header
                title={fr.photoTitle}
                subtitle={fr.photoSubTitle}
                element={element}
                background={"galeriephoto.jpg"}
                hauteur="70vh"
            />
            <section className="m-3">
                <ImageList variant="masonry" cols={3} gap={8}>
                    {photos.map((item, i) => (
                        <ImageListItem key={i}>
                            <ModalPhoto photo={item}/>

                        </ImageListItem>
                    ))}
                </ImageList>
            </section>
        </>
    );
}

export default PagePhoto;


