import React, {useContext, useState} from 'react';
import {Button, message, Spin, Switch} from "antd";
import {GlobalContext} from "../layout/GlobalProvider";
import {CloudUploadOutlined} from "@ant-design/icons";
import {API_URL} from "../../utils/constants";


function GestionParametres(props) {
    const {globalConfig, editConfig, getConfig} = useContext(GlobalContext)
    const [loading, setLoading] = useState(false)

    const handleChangeConfig = async (attribut, valeur) => {
        setLoading(true); // Activer le spinner de chargement

        const updatedConfig = {...globalConfig, [attribut]: valeur};

        try {
            await editConfig(updatedConfig);
            getConfig();
        } catch (error) {
            message.error("Une erreur s'est produite lors de la mise à jour de la configuration :");
        } finally {
            setLoading(false); // Désactiver le spinner de chargement, quelle que soit la réponse
        }
    };

    const handleDownload = async () => {
        try {
            const response = await fetch(API_URL + 'download_archive');

            if (!response.ok) {
                // Si la réponse du serveur n'est pas "ok", lève une exception avec le message d'erreur
                throw new Error('Erreur lors du téléchargement du fichier');
            }

            const contentType = response.headers.get('content-type');

            if (contentType && contentType.includes('application/json')) {
                const json = await response.json();
                message.error(json.message);
            } else {

                const blob = await response.blob();

                // Crée un objet URL pour le fichier blob
                const fileUrl = URL.createObjectURL(blob);

                // Crée un lien de téléchargement
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = 'nom-du-fichier'; // Spécifie le nom du fichier à télécharger
                link.click();

                message.success('Le fichier a été téléchargé avec succès');
            }
        } catch (error) {
            console.error(error);
            message.error('Une erreur est survenue lors du téléchargement du fichier');
        }
    };


    return (
        <div className="d-flex justify-content-center align-items-center gap-3">

            {/*<h2 className="text-center">Tableau de bord des paramètres globaux</h2>*/}
            <Spin spinning={loading} tip="Modification des paramètres en cours ...">
                <div className="text-center">
                    <h3>Maintenance</h3>
                    <Switch checked={globalConfig.maintenance}
                            onChange={() => handleChangeConfig("maintenance", !globalConfig.maintenance)}/>
                </div>

                <div className="text-center">
                    <h3>Flocons de neige</h3>
                    <Switch checked={globalConfig.snowfall}
                            onChange={() => handleChangeConfig("snowfall", !globalConfig.snowfall)}/>
                </div>

                <div className="text-center">
                    <h3>Affichage des prix</h3>
                    <Switch checked={globalConfig.displayPrice}
                            onChange={() => handleChangeConfig("displayPrice", !globalConfig.displayPrice)}/>
                </div>

                <div className="text-center">
                    <h3>Affichage des publicités</h3>
                    <Switch checked={globalConfig.publicite}
                            onChange={() => handleChangeConfig("publicite", !globalConfig.publicite)}/>
                </div>
                <div className="text-center my-3">
                    <Button type="primary" icon={<CloudUploadOutlined/>}
                            onClick={() => handleDownload()}>
                        Télécharger un backup
                    </Button>
                </div>
            </Spin>
        </div>
    );
}

export default GestionParametres;