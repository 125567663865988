import React, {useContext, useState} from "react";
import {Modal, Tabs} from "antd";
import {GlobalContext} from "../../layout/GlobalProvider";
import InfoGeneralProduit from "./InfoGeneralProduit";
import InfoAllergeneProduit from "./InfoAllergeneProduit";
import InfoOptionsProduit from "./InfoOptionsProduit";

const TabPane = Tabs.TabPane
const ModalProduit = ({children, produit}) => {
    const [visible, setVisible] = useState(false);
    const {globalConfig} = useContext(GlobalContext)
    const showModal = () => {
        setVisible(true);
    };

    const hideModal = () => {
        setVisible(false);
    };

    const [afficherDecor, setAfficherDecor] = useState(false);

    // const image =
    //     produit.image === "none.png"
    //         ? require("../../../img/loading.jpg")
    //         : API_URL + "get_image/imageProduit/" + produit.image;

    const items = [
        {
            key: "1",
            label: "Fiche produit",
            children: <InfoGeneralProduit produit={produit} globalConfig={globalConfig}/>,
            visible: true
        },
        {
            key: "2",
            label: "Allergènes",
            children: <InfoAllergeneProduit produit={produit}/>,
            visible: produit.allergenes.some(allergene => allergene.is_present)
        },
        {
            key: "3",
            label: "Options",
            children: <InfoOptionsProduit produit={produit}/>,
            visible: produit.formes.some(forme => forme.is_present) || produit.decors.some(decor => decor.is_present)
        }
    ];

    return (
        <>
            <div className="text-center" onClick={showModal}>
                {children}
            </div>
            <Modal
                open={visible}
                onCancel={hideModal}
                footer={null}
                title={produit.nom.charAt(0).toUpperCase() + produit.nom.slice(1)}
                width={500}
            >
                {!produit.allergenes.some(allergene => allergene.is_present) && !produit.formes.some(forme => forme.is_present) && !produit.decors.some(decor => decor.is_present) ?
                    <InfoGeneralProduit produit={produit} globalConfig={globalConfig}/> :
                    <Tabs centered tabPosition="top" items={items.filter(item => item.visible)}/>
                }

            </Modal>
        </>
    );
};

export default ModalProduit;
