import React, {useContext, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {API_URL} from "../../utils/constants";
import fr from "../../utils/FR.json";
import {GlobalContext} from "../layout/GlobalProvider";
import {message, Spin} from "antd";
import MultiUploadPreview from "../gestion/MultiUploadPreview";


const ModalAddPublication = ({children, getPublications}) => {
    const [show, setShow] = useState(false);
    const toggle = () => setShow(!show);
    const {token} = useContext(GlobalContext)

    const [texte, setTexte] = useState("");
    const [titre, setTitre] = useState("");
    const [type, setType] = useState("Evenement");
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let data = new FormData();
        data.append("titre", titre);
        data.append("type", type);
        data.append("texte", texte);

        for (let i = 0; i < photos.length; ++i) {
            data.append("photos[]", photos[i].originFileObj);
        }

        const Upload = async () => {
            try {
                const response = await fetch(API_URL + "add-publication", {
                    method: "POST",
                    headers: {Authorization: "Bearer " + token},
                    body: data,
                })
                const res = await response.json()
                if (res.status === 200) {
                    toggle()
                    getPublications()
                    setTexte("");
                    setTitre("");
                    setType("");
                    setPhotos([])
                    message.success(res.message)
                } else {
                    message.error(res.message)
                }
            } catch (e) {
                message.error("Une erreur est survenue")
            }
            setLoading(false)
        };
        Upload();
    };

    const handleChangePhoto = (e) => {
        if (e.target.files.length > 4) {
            message.warning("Vous ne pouvez choisir que 4 photos maximum");
            document.getElementById("inputFiles").value = "";
        } else {
            const selectedPhotos = Array.from(e.target.files);
            setPhotos(selectedPhotos);
        }
    };

    return (
        <>
            <button className="btn btn-outline-primary" onClick={toggle}>
                <i className="bi bi-plus-lg"></i> {children}
            </button>

            <Modal show={show} onHide={toggle}>
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une publication</Modal.Title>
                </Modal.Header>
                <Spin spinning={loading} tip="Ajout de la publication en cours ...">

                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <label className="fw-bold">Titre</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Entrer le titre"
                                value={titre}
                                onChange={(e) => setTitre(e.target.value)}
                            ></input>
                            <label className="fw-bold">Text</label>
                            <textarea
                                type="text"
                                className="form-control"
                                placeholder="Entrer le texte"
                                value={texte}
                                onChange={(e) => setTexte(e.target.value)}
                            ></textarea>
                            <label className="fw-bold">Type</label>
                            <Form.Select
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                {Object.entries(fr.typePublication).map(
                                    (elem, i) => {
                                        return <option key={i}>{elem[1]}</option>;
                                    }
                                )}
                            </Form.Select>
                            <label className="fw-bold">Image(s)</label>
                            <MultiUploadPreview setPhotos={setPhotos}/>
                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={toggle}>
                            {fr.buttonClose}
                        </Button>

                        <Button variant="primary" onClick={handleSubmit}>
                            <i className="bi bi-plus-lg"></i> {fr.buttonAdd}
                        </Button>

                    </Modal.Footer>
                </Spin>
            </Modal>
        </>
    );
};

export default ModalAddPublication;
