import React from 'react';
import GoogleAd from "../layout/GoogleAd";
import {ADSENSE_CLIENT, ADSENSE_SLOT} from "../../utils/constants";

function AdsCard({id}) {
    return (
        <section
            className="col-lg-4 col-md-6  "
            style={{marginBottom: "4rem"}}
            key={id}
        >
            <div className="d-flex flex-column bg-white rounded  h-100">
                <GoogleAd
                    client={
                        ADSENSE_CLIENT
                    }
                    slot={ADSENSE_SLOT}
                    style={{
                        display: "block",
                    }}
                    layout="in-article"
                    format="fluid"
                />
            </div>
        </section>
    );
}

export default AdsCard;