import React, {useContext, useState} from 'react';
import {Button, Image, message, Spin, Tooltip} from "antd";
import {DeleteOutlined, RotateLeftOutlined} from "@ant-design/icons";
import {API_URL} from "../../utils/constants";
import {GlobalContext} from "../layout/GlobalProvider";

function ImageActions({image, chemin, idEntite, refresh}) {
    const {token} = useContext(GlobalContext)
    const [imageName, setImageName] = useState(image);
    const [loading, setLoading] = useState(false);

    async function removeImage() {
        setLoading(true);
        const response = await fetch(
            API_URL + "remove_image/" + chemin + "/" + imageName + "/" + idEntite,
            {
                method: "DELETE",
                headers: {Authorization: "Bearer " + token},
            })
        const res = await response.json()
        if (res.status === 200) {
            message.success(res.message)
            setImageName("none.png")
        } else {
            message.error(res.message)
        }
        setLoading(false)
    }

    const tournerImage = async (e) => {
        try {
            setLoading(true);
            const response = await fetch(
                API_URL + "rotate_picture/" + chemin + "/" + imageName + "/90",
                {
                    method: "GET",
                    headers: {Authorization: "Bearer " + token},
                })
            const res = await response.json()
            if (res.status === 200) {
                message.success(res.message)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur est survenue")
        } finally {
            setLoading(false)
        }

    };
    if (imageName === "none.png") {
        return <></>
    }

    return (
        <Spin spinning={loading} tip="Chargement de  l'image ..."
        >

            <div className="d-flex justify-content-center m-1 gap-3">
                <div style={{position: 'relative', display: 'inline-block', justifyContent: "center"}}>
                    <Image
                        width={200}
                        className="rounded"
                        alt={imageName}
                        loading="lazy"
                        src={imageName !== "none.png" ? `${API_URL}get_image/${chemin}/${imageName}?timestamp=${Date.now()}` : require('../../img/loading.jpg')}
                    />
                    {imageName !== "none.png" && <>
                        <Tooltip title="Tourner l'image de 90° à droite" placement="topLeft"><Button type="primary"
                                                                                                     icon={
                                                                                                         <RotateLeftOutlined/>}
                                                                                                     onClick={() => tournerImage()}
                                                                                                     style={{
                                                                                                         position: 'absolute',
                                                                                                         bottom: 0,
                                                                                                         left: 0,
                                                                                                         margin: "5px"
                                                                                                     }}/></Tooltip>
                        <Tooltip title="Supprimer l'image" placement="topLeft">
                            <Button danger type="primary"
                                    icon={<DeleteOutlined/>}
                                    onClick={() => removeImage()}
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        right: 0,
                                        margin: "5px"
                                    }}/></Tooltip></>}
                </div>
            </div>
        </Spin>
    );
}

export default ImageActions;