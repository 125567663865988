import React, {useEffect, useState} from "react";
import {ADDRESS, ADDRESSPLUS, API_URL} from "../../utils/constants";
import fr from "../../utils/FR.json";
import {message, Spin} from "antd";
import MapGoogle from "./MapGoogle";

function About() {
    const [lundi, setLundi] = useState([]);
    const [mardi, setMardi] = useState([]);
    const [mercredi, setMercredi] = useState([]);
    const [jeudi, setJeudi] = useState([]);
    const [vendredi, setVendredi] = useState([]);
    const [samedi, setSamedi] = useState([]);
    const [dimanche, setDimanche] = useState([]);
    const [loading, setLoading] = useState(false);

    async function getHoraires() {
        setLoading(true)
        try {
            const response = await fetch(API_URL + "afficherHoraire", {
                method: "GET",
            })
            const res = await response.json()
            if (res.status === 200) {
                attributionJour(res.data)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur s'est produite lors de la récupération des horaires")
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getHoraires()
    }, []);

    const attributionJour = (data) => {
        for (let jour of data) {
            if (jour["jourSemaine"] === "Lundi") {
                setLundi(jour);
            }
            if (jour["jourSemaine"] === "Mardi") {
                setMardi(jour);
            }
            if (jour["jourSemaine"] === "Mercredi") {
                setMercredi(jour);
            }
            if (jour["jourSemaine"] === "Jeudi") {
                setJeudi(jour);
            }
            if (jour["jourSemaine"] === "Vendredi") {
                setVendredi(jour);
            }
            if (jour["jourSemaine"] === "Samedi") {
                setSamedi(jour);
            }
            if (jour["jourSemaine"] === "Dimanche") {
                setDimanche(jour);
            }
        }
    };

    return (
        <>
            <section className="page-section">
                <div className="container">
                    <div className="product-item">
                        <div className="product-item-title d-flex">
                            <div className="bg-faded p-5 d-flex ms-auto rounded">
                                <h2 className="section-heading mb-0">
                                    <span className="section-heading-upper">
                                        {fr.aboutHistoryTitle}
                                    </span>
                                    <span className="section-heading-lower">
                                        {fr.aboutHistorySubTitle}
                                    </span>
                                </h2>
                            </div>
                        </div>
                        <div className="product-item-description d-flex me-auto">
                            <div className="bg-faded p-5 rounded">
                                <p className="mb-0">{fr.aboutHistoryText}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="page-section cta"
                style={{marginRight: "1rem", marginLeft: "1rem"}}
            >
                <div className="container">
                    <div className="row">
                        <div id="tabH" className="col-xl-9 mx-auto">
                            <div className="cta-inner bg-faded text-center rounded">
                                <Spin spinning={loading} tip="Chargement de horaires ...">
                                    <h2 className="section-heading mb-5">
                                    <span
                                        style={{fontSize: "3.5vw"}}
                                        className="section-heading-lower"
                                    >
                                        {fr.aboutHourlyTitle}
                                    </span>
                                    </h2>

                                    <ul className="list-unstyled list-hours mb-5 text-left mx-auto">

                                        <li className="list-unstyled-item list-hours-item d-flex">
                                            {fr.aboutHourlyDay.monday}
                                            <Heure jour={lundi}/>
                                        </li>
                                        <li className="list-unstyled-item list-hours-item d-flex">
                                            {fr.aboutHourlyDay.tuesday}
                                            <Heure jour={mardi}/>
                                        </li>
                                        <li className="list-unstyled-item list-hours-item d-flex">
                                            {fr.aboutHourlyDay.wednesday}
                                            <Heure jour={mercredi}/>
                                        </li>
                                        <li className="list-unstyled-item list-hours-item d-flex">
                                            {fr.aboutHourlyDay.thursday}
                                            <Heure jour={jeudi}/>
                                        </li>
                                        <li className="list-unstyled-item list-hours-item d-flex">
                                            {fr.aboutHourlyDay.friday}
                                            <Heure jour={vendredi}/>
                                        </li>
                                        <li className="list-unstyled-item list-hours-item d-flex">
                                            {fr.aboutHourlyDay.saturday}
                                            <Heure jour={samedi}/>
                                        </li>
                                        <li className="list-unstyled-item list-hours-item d-flex">
                                            {fr.aboutHourlyDay.sunday}
                                            <Heure jour={dimanche}/>
                                        </li>

                                    </ul>

                                    <p className="address mb-5">
                                        <em>
                                            <strong>{ADDRESS}</strong>
                                            <br/>
                                            {ADDRESSPLUS}
                                        </em>
                                    </p>
                                    <p className="mb-0">
                                        <small>
                                            <em>{fr.aboutHourlyExcept}</em>
                                        </small>
                                    </p>
                                </Spin>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="page-section about-heading">
                <div className="container">
                    <img
                        className="img-fluid rounded about-heading-img mb-3 mb-lg-0"
                        src={require("../../img/boulangerie.jpg")}
                        alt="Boulangerie"
                    />
                    <div className="about-heading-content">
                        <div className="row">
                            <div className="col-xl-9 col-lg-10 mx-auto">
                                <div className="bg-faded rounded p-5">
                                    <h2 className="section-heading mb-4">
                                        <span className="section-heading-upper">
                                            {fr.aboutMapTitle}
                                        </span>
                                        <span className="section-heading-lower">
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={fr.linkGoogleMaps}
                                                style={{
                                                    textDecoration: "none",
                                                    color: "inherit",
                                                }}
                                            >
                                                {ADDRESS}{" "}
                                                <small>
                                                    ({ADDRESSPLUS})
                                                </small>
                                            </a>
                                        </span>
                                    </h2>
                                    <MapGoogle/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default About;

function Heure({jour}) {
    let matin = [];
    let aprem = [];
    if (jour["aprem"] === undefined) {
        return (
            <span className="heuredouce ms-auto text-danger">
                {fr.chargement}
            </span>
        );
    }

    if (jour["matin"]) {
        matin.push(
            <span className="text-success" key={Math.random()}>
                {jour["ouvertAM"]} - {jour["fermerAM"]}
            </span>
        );
    } else {
        matin.push(
            <span className="text-danger">{fr.aboutHourlyCloseMorning}</span>
        );
    }
    if (jour["aprem"]) {
        aprem.push(
            <span className="text-success" key={Math.random()}>
                {jour["ouvertPM"]} - {jour["fermerPM"]}
            </span>
        );
    } else {
        aprem.push(
            <span className="text-danger" key={Math.random()}>
                {fr.aboutHourlyCloseAfternoon}
            </span>
        );
    }

    if (jour["general"]) {
        return (
            <span className="heuredouce ms-auto text-success">
                {matin} / {aprem}
            </span>
        );
    } else {
        return (
            <span className="heuredouce ms-auto text-danger">
                {fr.aboutHourlyClose}
            </span>
        );
    }
}
