import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {GlobalContext} from "./GlobalProvider";
import {ADSENSE_CLIENT, ADSENSE_SLOT} from "../../utils/constants";

const GoogleAd = ({classNames, style, format, timeout}) => {
    const [googleInit, setGoogleInit] = useState(null);
    const {globalConfig} = useContext(GlobalContext);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (typeof window !== "undefined") {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            }
        }, timeout);

        setGoogleInit(timeoutId);

        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [timeout]);

    return globalConfig.publicite ? (
        <div className={classNames}>
            <ins
                className="adsbygoogle"
                style={style || {display: "block", textAlign: "center"}}
                data-ad-client={ADSENSE_CLIENT}
                data-ad-slot={ADSENSE_SLOT}
                data-ad-format={format || "auto"}
                data-full-width-responsive="true"
            ></ins>
        </div>
    ) : null;
};

GoogleAd.propTypes = {
    classNames: PropTypes.string,
    slot: PropTypes.string,
    timeout: PropTypes.number,
    googleAdId: PropTypes.string,
};

GoogleAd.defaultProps = {
    classNames: "",
    timeout: 200,
};

export default GoogleAd;
