import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {API_URL} from "../../utils/constants";
import fr from "../../utils/FR.json";
import {GlobalContext} from "../layout/GlobalProvider";
import {message} from "antd";

export default function Login({element}) {
    const [nom, setNom] = useState("");
    const [password, setPassword] = useState("");
    const [ipaddr, setIpAddr] = useState({
        'country_code': null,
        'country_name': null,
        'city': null,
        'postal': null,
        'latitude': null,
        'longitude': null,
        'IPv4': null,
        'state': null
    });
    const [stayConnected, setStayConnected] = useState(false);

    let navigate = useNavigate();

    const {token, setToken} = useContext(GlobalContext)

    function logMeIn(e) {
        e.preventDefault();

        let data = new FormData();
        data.append("nom", nom);
        data.append("password", password);
        data.append("stayConnected", stayConnected);
        data.append("ipaddr", JSON.stringify(ipaddr));

        const login = async () => {
            try {
                const response = await fetch(API_URL + "login", {
                    method: "POST",
                    body: data,
                })
                const res = await response.json()
                if (res.status === 200) {
                    setToken(res.access_token)
                    navigate((fr.routeManagement).replace(":onglet", "9"))
                } else if (res.status === 405) {
                    message.warning(
                        res.message
                    );
                } else {
                    message.error(res.message);
                    setPassword("");
                }
            } catch (e) {
                message.error("Une erreur s'est produite")
            }
        }
        login()

    }

    const getIP = async () => {
        const response = await fetch("https://geolocation-db.com/json/")
        const res = await response.json()
        if (response.ok) {
            setIpAddr(res);
        } else {
            setIpAddr(
                {
                    'country_code': null,
                    'country_name': null,
                    'city': null,
                    'postal': null,
                    'latitude': null,
                    'longitude': null,
                    'IPv4': null,
                    'state': null
                }
            )
        }
    }

    useEffect(() => {
        if (token) {
            message.warning("Vous êtes déjà connecté !")
            navigate((fr.routeManagement).replace(":onglet", "9"));
        } else {
            getIP()
        }

    }, []);

    return (
        <section
            className="page-section clearfix"
            style={{marginTop: "10rem"}}
            ref={element}
        >
            <form>
                <div className="container">
                    <div className="intro">
                        <img
                            className="intro-img img-fluid mb-3 mb-lg-0 rounded"
                            src={require("../../img/bg.jpg")}
                            alt="Connexion"
                            loading="lazy"
                        />
                        <div className="intro-text left-0 text-center bg-faded p-5 rounded">
                            <h2 className="section-heading mb-4">
                                <span className="section-heading-upper">
                                    Réservé aux personnels autorisés
                                </span>
                                <span className="section-heading-lower">
                                    Connexion
                                </span>
                            </h2>
                            <div className="mb-3">
                                <label>Identifiant de connexion</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setNom(e.target.value)}
                                    value={nom}
                                />
                                <label>Mot de passe</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    value={password}
                                />

                                <div className="d-flex justify-content-center gap-3"
                                     onClick={() => setStayConnected(!stayConnected)}>

                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        onChange={(e) =>
                                            setStayConnected(!stayConnected)
                                        }
                                        checked={stayConnected}
                                    />
                                    <label className="form-check-label " style={{cursor: "pointer"}}>Se souvenir de
                                        moi</label>
                                </div>
                            </div>
                            <p className="mb-3">
                                En cas de soucis de connexion, contactez{" "}
                                <strong>contact@boulangerielepidor.fr</strong>
                            </p>
                            <em>
                                Toutes tentatives d'infiltration pourra mener à
                                des poursuites judiciaires
                            </em>
                            <div className="intro-button mx-auto">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-xl"
                                    onClick={logMeIn}
                                >
                                    Se connecter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </section>
    );
}
