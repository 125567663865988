import React, {useEffect, useState} from "react";
import {API_URL} from "../../utils/constants";
import fr from "../../utils/FR.json";
import {message, Popconfirm, Spin} from "antd";

function FormulaireContact(props) {
    const [nom, setNom] = useState("");
    const [email, setEmail] = useState("");
    const [objet, setObjet] = useState("");
    const [msg, setMsg] = useState("");
    const [contract, setContract] = useState(false);
    const [ipaddr, setIpAddr] = useState("");
    const [isValide, setIsValide] = useState("form-control");
    const [loading, setLoading] = useState(false);

    const send = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = new FormData();
        data.append("nom", nom);
        data.append("email", email);
        data.append("objet", objet);
        data.append("message", msg);
        data.append("ipaddr", JSON.stringify(ipaddr));
        Upload(data);
    };

    const Upload = async (data) => {
        try {
            const response = await fetch(API_URL + "contact", {
                method: "POST",
                body: data,
            })
            const res = await response.json()
            if (res.status === 200) {
                setNom("");
                setEmail("");
                setObjet("");
                setMsg("");
                setContract(false)
                message.success(res.message)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur s'est produite veuillez recommencer plus tard")
            console.log(e)
        }
        setLoading(false);
    };

    async function getIp() {
        const response = await fetch("https://geolocation-db.com/json/")
        const res = await response.json()
        setIpAddr(res)
    }

    useEffect(() => {
        getIp();
    }, [])

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleChangeEmail = (e) => {
        if (!isValidEmail(e.target.value)) {
            setIsValide("form-control is-invalid");
        } else {
            setIsValide("form-control is-valid");
        }
        setEmail(e.target.value);
    };

    return (
        <section className="page-section about-heading">
            <div className="container">
                <div
                    className="about-heading-content"
                    style={{marginTop: "2rem"}}
                >
                    <div className="row">
                        <div className="col-xl-9 col-lg-10 mx-auto">
                            <div className="bg-faded rounded p-5">
                                <Spin spinning={loading} size="large" tip="Envoie en cours ...">
                                    <h2 className="section-heading mb-4">
                                    <span className="section-heading-upper">
                                        Formulaire de contact
                                    </span>
                                    </h2>
                                    <section>
                                        <form onSubmit={send}>
                                            <div>
                                                <label>Nom et Prénom</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Entrez votre nom et votre prénom"
                                                    value={nom}
                                                    onChange={(e) =>
                                                        setNom(e.target.value)
                                                    }
                                                ></input>
                                            </div>

                                            <div>
                                                <label>Adresse e-mail</label>
                                                <input
                                                    type="text"
                                                    className={isValide}
                                                    placeholder="Entrez votre adresse e-mail"
                                                    value={email}
                                                    onChange={(e) =>
                                                        handleChangeEmail(e)
                                                    }
                                                ></input>
                                            </div>
                                            <div>
                                                <label>Objet</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Entrez l'objet de votre message"
                                                    value={objet}
                                                    onChange={(e) =>
                                                        setObjet(e.target.value)
                                                    }
                                                ></input>
                                            </div>
                                            <div>
                                                <label>Message</label>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Saisissez votre message ..."
                                                    value={msg}
                                                    onChange={(e) =>
                                                        setMsg(e.target.value)
                                                    }
                                                ></textarea>
                                            </div>
                                            <div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={contract}
                                                        id="flexCheckChecked"
                                                        onChange={() =>
                                                            setContract(!contract)
                                                        }
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckChecked"
                                                    >
                                                        J'ai lu et j'approuve la{" "}
                                                        <a
                                                            href={
                                                                fr.routePrivacyPolicy
                                                            }
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            politique de
                                                            confidentialité
                                                        </a>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="text-end">
                                            <span
                                                className="d-inline-block"
                                                tabIndex="0"
                                                data-toggle="tooltip"
                                                // title={
                                                //     (!contract ||
                                                //         !isValidEmail(email) ||
                                                //         nom.length === 0 ||
                                                //         msg.length === 0)
                                                //     && "Remplissez tous les champs avant d'envoyer"
                                                //
                                                // }
                                            >
                                                {(!contract ||
                                                    !isValidEmail(
                                                        email
                                                    ) ||
                                                    nom.length === 0 ||
                                                    msg.length === 0) ? (
                                                    <button
                                                        className="btn btn-primary"
                                                        disabled
                                                    >
                                                        Envoyer
                                                    </button>
                                                ) : objet.length === 0 ? (
                                                    <Popconfirm title="On envoie quand même ?"
                                                                description="L'objet est vide"
                                                                onConfirm={(e) => send(e)}
                                                                okText="Oui on envoie"
                                                                cancelText="Non"
                                                    >
                                                        <button
                                                            className="btn btn-primary"
                                                            type="button"
                                                        >
                                                            Envoyer
                                                        </button>
                                                    </Popconfirm>
                                                ) : <button
                                                    className="btn btn-primary"
                                                >
                                                    Envoyer
                                                </button>}
                                            </span>
                                            </div>
                                        </form>
                                    </section>
                                </Spin>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default FormulaireContact;
