import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import {NavLink} from "react-router-dom";

const titre = "Erreur 404 - Boulangerie Pâtisserie l'Épi d'Or"
const url = "https://boulangerielepidor.fr/404"
const content = "Désolé, la page que vous recherchez est introuvable. Nous vous invitons à retourner sur la page d'accueil de notre site web de la Boulangerie Pâtisserie l'Épi d'Or à Orléans pour découvrir nos produits et services de qualité."
const PageError404 = ({element}) => {

    useEffect(() => {
        document.title = titre;
        window.history.replaceState(
            {page: "404"},
            "404 Not Found",
            // "/404"
        );
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    {titre}
                </title>
                <meta
                    name="title"
                    content={titre}
                />
                <meta
                    name="description"
                    content={content}
                />
            </Helmet>
            <section className="page-section about-heading" ref={element}>
                <div className="container">
                    <div
                        className="about-heading-content"
                        style={{marginTop: "10rem"}}
                    >
                        <div className="row">
                            <div className="col-xl-9 col-lg-10 mx-auto">
                                <div
                                    className="bg-faded rounded p-5 "
                                    style={{textAlign: "center"}}
                                >
                                    <h2 className="section-heading mb-4">
                                        <span className="section-heading-upper">
                                            Ooooops ....
                                        </span>
                                        <span className="section-heading-lower">
                                            Erreur 404 - Page introuvable
                                        </span>
                                    </h2>
                                    <div>
                                        <i
                                            className="bi bi-x-circle fa-6x"
                                            style={{width: "20%"}}
                                        ></i>
                                    </div>
                                    <p className="mb-0">
                                        Il semblerait qu'il y ait eu une erreur
                                        dans votre requête.
                                    </p>
                                    <NavLink to="/Home" className="btn btn-primary"><i
                                        className="bi bi-box-arrow-left"></i> Retour au menu</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PageError404;
