import React from 'react';

function InfoOptionsProduit({produit}) {
    return (
        <div>
            <ul>
                {produit.formes.map((forme) => {
                    if (forme.is_present)
                        return <li key={forme.id}>{forme.forme}</li>
                })
                }
            </ul>
            <ul>
                {produit.decors.map((decor) => {
                    if (decor.is_present)
                        return <li key={decor.id}>{decor.position}, {decor.nappage}</li>
                })
                }
            </ul>

        </div>
    );
}

export default InfoOptionsProduit;