import React from "react";
import Header from "../components/layout/Header";
import {Helmet} from "react-helmet";
import {MAIL_CONTACT, PHONE_NUMBER} from "../utils/constants";

const titre = "Maintenance - Boulangerie Pâtisserie l'Épi d'Or"
const url = "https://boulangerielepidor.fr/Home"
const content = "Désolé pour la gêne occasionnée, notre site est en maintenance pour améliorer votre expérience. Nous serons bientôt de retour en ligne. Merci pour votre compréhension."

function PageMaintenance(props) {
    return (
        <>
            <Helmet>
                <title>
                    {titre}
                </title>
                <meta
                    name="title"
                    content={titre}
                />
                <meta
                    name="description"
                    content={content}
                />

                <meta property="og:type" content="website"/>
                <meta
                    property="og:url"
                    content={url}
                />
                <meta
                    property="og:title"
                    content={titre}
                />
                <meta
                    property="og:description"
                    content={content}
                />

                <meta property="twitter:card" content="summary_large_image"/>
                <meta
                    property="twitter:url"
                    content={url}
                />
                <meta
                    property="twitter:title"
                    content={titre}
                />
                <meta
                    property="twitter:description"
                    content={content}
                />
            </Helmet>
            <Header
                title="Maintenance"
                subtitle="Maintenance"
                element={props.element}
                hauteur={"70vh"}
                background="piecemontee.jpg"
            />
            <section className="page-section about-heading">
                <div className="container">
                    <div
                        className="about-heading-content"
                        style={{marginTop: "2rem"}}
                    >
                        <div className="row">
                            <div className="col-xl-9 col-lg-10 mx-auto">
                                <div className="bg-faded rounded p-5">
                                    <h2 className="section-heading mb-4">
                                        <span className="section-heading-upper">
                                            Modification en cours
                                        </span>
                                        <span className="section-heading-lower">
                                            Site en Maintenance
                                        </span>
                                    </h2>
                                    <p>
                                        Le site de la boulangerie pâtisserie
                                        l'Épi d'Or d'Orléans est actuellement en
                                        maintenance et nous nous excusons pour
                                        la gène occasionnée
                                    </p>
                                    <p className="mb-0">
                                        Pour consulter les horaires, vous pouvez
                                        les consulter sur notre page google qui
                                        reste a disposition
                                        <br/>
                                        <a
                                            href="https://www.google.com/search?q=Boulangerie+l%27epi+d%27or+Orl%C3%A9ans&rlz=1C1CHBF_frFR922FR922&sxsrf=ALiCzsbUCRdG9X0hAn2qry5CBlhZUgnLew%3A1657706935359&ei=t5nOYp3AFeyGwbkPuMaP6A0&ved=0ahUKEwjd95fIz_X4AhVsQzABHTjjA90Q4dUDCA8&uact=5&oq=Boulangerie+l%27epi+d%27or+Orl%C3%A9ans&gs_lcp=Cgdnd3Mtd2l6EAMyBggAEB4QFjoECCMQJzoOCC4QgAQQsQMQxwEQ0QM6CwguEIAEEMcBENEDOhEILhCABBCxAxCDARDHARDRAzoICAAQgAQQsQM6BAguECc6BwgAELEDEEM6BQgAEIAEOgsIABCABBCxAxCDAToKCC4QxwEQ0QMQJzoHCAAQyQMQQzoLCC4QgAQQxwEQrwE6BAgAEEM6CwgAEIAEELEDEMkDOg0IABCABBCHAhDJAxAUOgUIABCSAzoKCAAQgAQQhwIQFDoHCAAQgAQQCjoICAAQgAQQyQM6CwguEJIDEMcBEK8BOgoILhDHARCvARANSgQIQRgASgQIRhgAUABY9F1g0GNoAHABeACAAbgBiAHgI5IBBDAuMzCYAQCgAQHAAQE&sclient=gws-wiz"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Page Google
                                        </a>
                                    </p>
                                    <br/>
                                    <p className="mb-0">
                                        Pour toutes questions, vous pouvez nous
                                        contacter par mail à{" "}
                                        <a
                                            href={`mailto:${MAIL_CONTACT}`}
                                            style={{
                                                textDecoration: "none",
                                                color: "inherit",
                                            }}
                                        >
                                            <strong>{MAIL_CONTACT}</strong>
                                        </a>{" "}
                                        ou bien par téléphone au{" "}
                                        <a
                                            href={`tel:${PHONE_NUMBER}`}
                                            style={{
                                                textDecoration: "none",
                                                color: "inherit",
                                            }}
                                        >
                                            <strong>{PHONE_NUMBER}</strong>
                                        </a>
                                    </p>

                                    <p className="mb-0">
                                        Notre webmaster est sur le coup
                                    </p>
                                    <small>Merci de votre compréhension</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PageMaintenance;
