import React, {useContext, useState} from "react";
import {message, Spin} from "antd";
import {Button, Form, Modal} from "react-bootstrap";
import {GlobalContext} from "../layout/GlobalProvider";
import fr from "../../utils/FR.json";
import {API_URL} from "../../utils/constants";


export default function ModalEditPassword({children}) {
    const [show, setShow] = useState(false);
    const toggle = () => setShow(!show);

    const [old, setOld] = useState("");
    const [mdp, setMdp] = useState("");
    const [cpassword, setCPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const {token} = useContext(GlobalContext)

    const handleSubmit = (e) => {
        e.preventDefault();
        if (mdp === "") {
            envoie();
        } else {
            if (mdp !== cpassword) {
                alert("Les mots de passe de correspondent pas");
            } else {
                envoie();
            }
        }
    };

    const envoie = () => {
        setLoading(true)
        let data = new FormData();
        data.append("mdp", mdp);
        data.append("old", old);

        const Upload = async () => {
            try {
                const response = await fetch(API_URL + "edit-password", {
                    method: "POST",
                    headers: {Authorization: "Bearer " + token},
                    body: data,
                })
                const res = await response.json()
                if (res.status === 200) {
                    toggle();
                    setCPassword("");
                    setOld("");
                    setMdp("");
                    message.success(res.message)
                } else {
                    document.getElementById("inputOld").className =
                        "form-control is-invalid";
                    message.error(res.message)
                }
            } catch (e) {
                message.error("Erreur lors de la modification de la publication")
            } finally {
                setLoading(false)
            }

        };
        Upload();
    };

    return (
        <>
            <button className="btn btn-primary" onClick={toggle}>
                {children}
            </button>

            <Modal show={show} onHide={toggle}>
                <Modal.Header closeButton>
                    <Modal.Title>Modifier votre mot de passe</Modal.Title>
                </Modal.Header>
                <Spin spinning={loading} tip="Modification du mot de passe en cours ...">
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <label className="fw-bold">Ancien mot de passe</label>
                            <input
                                id="inputOld"
                                type="password"
                                className={"form-control"}
                                placeholder="Entrer l'ancien mot de passe"
                                value={old}
                                onChange={(e) => setOld(e.target.value)}
                                required
                            ></input>
                            <label className="fw-bold">Nouveau mot de passe</label>
                            <input
                                type="password"
                                className={
                                    mdp.length === 0
                                        ? "form-control"
                                        : "form-control is-valid"
                                }
                                placeholder="Entrer le mot de passe"
                                value={mdp}
                                onChange={(e) => setMdp(e.target.value)}
                                required
                            ></input>
                            <label className="fw-bold">
                                Confirmer le nouveau mot de passe
                            </label>
                            <input
                                type="password"
                                className={
                                    cpassword.length === 0
                                        ? "form-control"
                                        : mdp === cpassword
                                            ? "form-control is-valid"
                                            : "form-control is-invalid"
                                }
                                placeholder="Confirmer le mot de passe"
                                value={cpassword}
                                onChange={(e) => setCPassword(e.target.value)}
                                required
                            ></input>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={toggle}>
                            {fr.buttonClose}
                        </Button>
                        <Button variant="primary" onClick={handleSubmit}>
                            <i className="bi bi-pencil"></i> {fr.buttonEdit}
                        </Button>
                    </Modal.Footer>
                </Spin>
            </Modal>
        </>
    );
};