import React, {useContext, useEffect, useState} from 'react';
import {message, Popconfirm, Spin, Switch, Table} from "antd";
import {GlobalContext} from "../layout/GlobalProvider";
import {API_URL} from "../../utils/constants";
import fr from "../../utils/FR.json";
import {QuestionCircleOutlined} from "@ant-design/icons";
import ModalEditPieceMontee from "../piecemontee/ModalEditPieceMontee";
import ModalAddPieceMontee from "../piecemontee/ModalAddPieceMontee";


function GestionPieceMontee() {

    const [piecesMontees, setPiecesMontees] = useState([])
    const [loadingRows, setLoadingRows] = useState([]);

    const {token} = useContext(GlobalContext)

    async function getPiecesMontees() {
        try {
            const response = await fetch(API_URL + "afficher-pieces-montees-admin", {
                method: "GET",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                setPiecesMontees(res.data)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur s'est produite")
        }
    }

    async function removePieceMontee(idPieceMontee) {
        try {
            const response = await fetch(API_URL + "removePieceMontee/" + idPieceMontee, {
                method: "DELETE",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                getPiecesMontees()
                message.success(res.message)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur s'est produite")
        }
    }

    useEffect(() => {
        getPiecesMontees()
    }, [])

    const handleQuickChange = async (pieceMonteeId, checked) => {
        setLoadingRows((prevLoadingRows) => {
            return [...prevLoadingRows, pieceMonteeId]; // Ajouter l'ID à la liste des lignes en cours de chargement
        });
        // Mettez à jour l'état du bouton switch dans le composant
        const updatedProducts = piecesMontees.map((photo) => {
            if (photo.id === pieceMonteeId) {
                return {...photo, enabled: checked};
            }
            return photo;
        });
        setPiecesMontees(updatedProducts);
        try {
            // Effectuez une requête vers la base de données pour mettre à jour l'état du produit
            let data = new FormData();
            data.append("pieceMonteeId", pieceMonteeId);
            data.append("valeur", checked);
            const response = await fetch(API_URL + "quick-change-piece-montee", {
                method: "POST",
                body: data,
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                await getPiecesMontees()
                message.success(res.message)
            } else {
                message.error(res.message)
            }

        } catch (error) {
            message.error(`Erreur lors de la mise à jour de l'état de la pièce montée : ${pieceMonteeId}`);
        }
        setLoadingRows((prevLoadingRows) =>
            prevLoadingRows.filter((rowId) => rowId !== pieceMonteeId) // Supprimer l'ID de la liste des lignes en cours de chargement une fois la requête terminée
        );
    };

    const colonnes = [
        {
            title: 'Titre',
            dataIndex: 'titre',
            key: 'titre',
            sorter: (a, b) => a.titre.localeCompare(b.titre),
        },
        {
            title: 'Catégorie',
            dataIndex: 'categorie',
            key: 'categorie',
            sorter: (a, b) => a.categorie.localeCompare(b.categorie),
        },
        {
            title: 'Photo',
            dataIndex: 'photo',
            key: 'photo',
            render: (image) => {
                if (image === "none.png") {
                    return "Pas d'image"
                }
                return <img src={API_URL + "/get_image/imagePieceMontee/" + image} alt={image}
                            style={{width: '50px'}} loading="lazy"/>
            },
        },
        {
            title: 'Affiché',
            dataIndex: 'display',
            key: 'display',
            render: (enabled, record) => (
                <Spin spinning={loadingRows.includes(record.id)}>
                    <Switch
                        checked={enabled}
                        onChange={(checked) => handleQuickChange(record.id, checked, "set-display")}
                    />
                </Spin>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <div>
                    <Popconfirm title="Action irréversible"
                                description="Êtes-vous sur de supprimer cet pièce montée ?"
                                onConfirm={() => removePieceMontee(record.id)}
                                icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                                cancelText="Annuler"
                                okText="Supprimer"
                    >
                        <button className="btn btn-danger">
                            <i className="bi bi-trash"></i> {fr.buttonRemove}
                        </button>
                    </Popconfirm>
                    <ModalEditPieceMontee getPiecesMontees={getPiecesMontees} piece={record}>
                        <i className="bi bi-pencil"></i> {fr.buttonEdit}
                    </ModalEditPieceMontee>
                </div>
            ),
        },
    ];

    return (
        <>
            <h2 className="text-primary text-center fw-bold">Pièces montées</h2>
            <div className="d-flex justify-content-center align-items-center gap-3 my-3">
                <ModalAddPieceMontee getPiecesMontees={getPiecesMontees}>Ajouter une pièce montée</ModalAddPieceMontee>
            </div>
            <h3 className="text-primary text-center">{piecesMontees.length} pièces montées</h3>
            <div className="table-responsive">
                <Table
                    rowKey="id"
                    dataSource={piecesMontees}
                    columns={colonnes}
                    loading={piecesMontees.length < 1}

                />
            </div>
        </>
    );
}

export default GestionPieceMontee;