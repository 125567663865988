import React, {useContext, useEffect, useState} from "react";
import {Navigate, Outlet, useNavigate} from "react-router-dom";
import {message} from "antd";
import {API_URL} from "../../utils/constants";
import {GlobalContext} from "../layout/GlobalProvider";


const PrivateRoute = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()

    const {token, removeToken} = useContext(GlobalContext)

    const fetchData = async () => {
        try {
            if (token) {
                const response = await fetch(API_URL + "is-logged",
                    {
                        method: "GET",
                        headers: {
                            Authorization: "Bearer " + token,
                        },
                    }
                );
                const res = await response.json();
                if (res.status === 200) {
                    setIsAuthenticated(true)
                } else {
                    setIsAuthenticated(false)
                    removeToken()
                }
            } else {
                setIsAuthenticated(false);
            }
            setIsLoading(true);
        } catch (err) {
            console.log(err)
            navigate("/404")
            message.error("Une erreur est survenue")
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return isLoading ? (
        isAuthenticated ? (
            <>
                <Outlet/>
            </>
        ) : (
            <Navigate
                to="/404"
                state={{message: "Please log before access to this content"}}
            />
        )
    ) : null;
};

export default PrivateRoute;
