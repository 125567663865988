import React from "react";
import {Tabs} from "antd";
import GestionHoraires from "../components/gestion/GestionHoraires";
import GestionProduits from "../components/gestion/GestionProduits";
import GestionUtilisateurs from "../components/gestion/GestionUtilisateurs";
import Profile from "../components/connexion/Profile";
import {useNavigate, useParams} from "react-router-dom";
import GestionPieceMontee from "../components/gestion/GestionPieceMontee";
import GestionContact from "../components/gestion/GestionContact";
import GestionAvis from "../components/gestion/GestionAvis";
import GestionPhotos from "../components/gestion/GestionPhotos";
import GestionPublications from "../components/gestion/GestionPublications";
import GestionParametres from "../components/gestion/GestionParametres";


const PageGestion = ({element}) => {
    const {onglet} = useParams()
    const navigate = useNavigate()

    const tabs = [
        {label: "Produits", key: "0", children: <GestionProduits/>},
        {label: "Horaires", key: "1", children: <GestionHoraires/>},
        {label: "Pièces montées", key: "3", children: <GestionPieceMontee/>},
        {label: "Email", key: "4", children: <GestionContact/>},
        {label: "Avis", key: "5", children: <GestionAvis/>},
        {label: "Photos", key: "6", children: <GestionPhotos/>},
        {label: "Publications", key: "7", children: <GestionPublications/>},
        {label: "Utilisateurs", key: "8", children: <GestionUtilisateurs/>},
        {label: "Paramètres", key: "10", children: <GestionParametres/>},
        {label: "Profile", key: "9", children: <Profile/>},


    ]
    return (
        <>
            <header className="bg-secondary py-5" ref={element}>
                <div className="container px-4 px-lg-5 my-5">
                    <div className="text-center text-white">
                        <h1 className="display-4 fw-bolder">
                            Administration
                        </h1>
                        <p className="lead fw-normal text-white-50 mb-0">
                            Réservé aux personnels identifiés
                        </p>


                    </div>
                </div>
            </header>
            <div className="bg-white">
                <Tabs
                    defaultActiveKey={onglet}
                    centered
                    items={tabs}
                    onChange={(key) => navigate(`/managment/${key}`)}
                />
            </div>


            {/*<h1>Horaires</h1>*/}
            {/*<GestionHoraires token={token} role={role}/>*/}
            {/*<h1>Les produits</h1>*/}
            {/*<TableauStruct token={token}/>*/}
        </>
    );
};

export default PageGestion;

