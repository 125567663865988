import {Button, Form, Modal} from "react-bootstrap";
import React, {useContext, useState} from "react";
import {API_URL} from "../../utils/constants";
import fr from "../../utils/FR.json";
import {GlobalContext} from "../layout/GlobalProvider";
import {message, Spin} from "antd";
import UploadPreview from "../gestion/UploadPreview";
import ImageActions from "../gestion/ImageActions";


const ModalEditPieceMontee = ({children, piece, getPiecesMontees}) => {
    const [show, setShow] = useState(false);
    const {token} = useContext(GlobalContext)
    const toggle = () => setShow(!show);

    const [titre, setTitre] = useState(piece.titre);
    const [categorie, setCategorie] = useState(piece.categorie);
    const [photo, setPhoto] = useState({image: null});
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let data = new FormData();
        data.append("id", piece.id);
        data.append("titre", titre);
        data.append("categorie", categorie);
        data.append("photo", photo.image);

        const Upload = async () => {
            try {
                const response = await fetch(API_URL + "editPieceMontee", {
                    method: "POST",
                    headers: {Authorization: "Bearer " + token},
                    body: data,
                })
                const res = await response.json()
                if (res.status === 200) {
                    toggle()
                    getPiecesMontees()
                    message.success(res.message)
                } else {
                    message.error(res.message)
                }
            } catch (e) {
                message.error("Erreur lors de la modification de la pièce montée")
            }
            setLoading(false)
        };
        Upload();
    };
    return (
        <>
            <button className="btn btn-primary" onClick={toggle}>
                {children}
            </button>

            <Modal show={show} onHide={toggle}>
                <Modal.Header closeButton>
                    <Modal.Title>Modifer une pièce montée</Modal.Title>
                </Modal.Header>
                <Spin spinning={loading} tip="Modification de la pièce montée en cours ...">
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <label className="fw-bold">Titre</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Entrer le titre de la pièce montée"
                                value={titre}
                                onChange={(e) => setTitre(e.target.value)}
                            ></input>
                            <label className="fw-bold">Catégorie</label>
                            <Form.Select
                                value={categorie}
                                onChange={(e) => setCategorie(e.target.value)}
                            >
                                {Object.entries(fr.categoriePieceMontee).map(
                                    (elem, i) => {
                                        return <option key={i}>{elem[1]}</option>;
                                    }
                                )}
                            </Form.Select>
                            <label className="fw-bold">Image</label>
                            <ImageActions image={piece.photo} chemin="imagePieceMontee" idEntite={piece.id}/>
                            <UploadPreview onProduitDataChange={setPhoto}/>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={toggle}>
                            {fr.buttonClose}
                        </Button>

                        <Button variant="primary" onClick={handleSubmit}>
                            <i className="bi bi-pencil"></i> {fr.buttonEdit}
                        </Button>
                    </Modal.Footer>
                </Spin>
            </Modal>
        </>
    );
};

export default ModalEditPieceMontee;
