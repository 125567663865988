import {Button, Form, Modal} from "react-bootstrap";
import {useState} from "react";
import {API_URL} from "../../utils/constants";

import fr from "../../utils/FR.json";
import {message, Spin} from "antd";


const ModalAddWatermarks = ({children, token}) => {
    const [show, setShow] = useState(false);
    const toggle = () => setShow(!show);

    const [loading, setLoading] = useState(false);
    const [chemin, setChemin] = useState("Produit");

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        let data = new FormData();
        data.append("chemin", chemin);

        const Upload = async () => {
            try {
                const response = await fetch(API_URL + "update-watermarks", {
                    method: "PUT",
                    headers: {Authorization: "Bearer " + token},
                    body: data,
                })
                const res = await response.json()
                if (res.status === 200) {
                    message.success(res.message)
                } else {
                    message.error(res.message)
                }
            } catch (e) {
                message.error("Une erreur s'est produite veuillez recommencer plus tard")
                console.log(e)
            }
            setLoading(false);
        };

        Upload();
    };

    return (
        <>
            <button className="btn btn-outline-primary" onClick={toggle}>
                <i className="bi bi-plus-lg"></i> {children}
            </button>

            <Modal show={show} onHide={toggle}>
                <Modal.Header closeButton>
                    <Modal.Title>Mettre à jour les filigrannes</Modal.Title>
                </Modal.Header>
                <Spin spinning={loading} tip="Mise à jour des filigrannes en cours ...">
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <label className="fw-bold">
                                Type de photos à mettre à jour
                            </label>
                            <Form.Select
                                aria-label="Default select example"
                                value={chemin}
                                onChange={(e) => setChemin(e.target.value)}
                            >
                                <option>Produit</option>
                                <option>Photo</option>
                                <option>PieceMontee</option>
                                <option>Decor</option>
                            </Form.Select>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={toggle}>
                            {fr.buttonClose}
                        </Button>

                        <Button variant="primary" onClick={handleSubmit}>
                            <i className="bi bi-plus-lg"></i> Mettre à jour
                        </Button>

                    </Modal.Footer>
                </Spin>
            </Modal>
        </>
    );
};

export default ModalAddWatermarks;
