import React, {useContext, useEffect, useState} from "react";
import {API_URL} from "../../utils/constants";
import fr from "../../utils/FR.json";
import {HeartFilled, HeartOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {Button, message, Popconfirm, Rate, Table} from "antd";
import {GlobalContext} from "../layout/GlobalProvider";

export default function GestionAvis() {
    const [listeAvis, setListAvis] = useState([]);
    const {token} = useContext(GlobalContext)

    async function getAvis() {
        try {
            const response = await fetch(API_URL + "afficherAvis", {
                method: "GET",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                setListAvis(res.data);
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur est survenue")
        }
    }

    const removeAvis = async (id) => {
        try {
            const response = await fetch(API_URL + "removeAvis/" + id, {
                method: "DELETE",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                getAvis()
                message.success(res.message)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur est survenue")
        }

    };

    const likeAvis = async (avis) => {
        let data = new FormData()
        data.append("id", avis.id)
        data.append("valeur", !avis.like)
        const response = await fetch(API_URL + "likeAvis", {
            method: "POST",
            headers: {Authorization: "Bearer " + token},
            body: data,
        })
        const res = await response.json()
        if (res.status === 200) {
            getAvis()
            message.success(res.message)
        } else {
            message.error(res.message)
        }

    };

    const colonnes = [
        {
            title: 'Auteur',
            dataIndex: 'auteur',
            key: 'auteur',
            sorter: (a, b) => a.auteur.localeCompare(b.auteur),
        },
        {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
            sorter: (a, b) => a.note - b.note,
            render: (_, record) =>
                <Rate disabled value={record.note}></Rate>

        },
        {
            title: 'Avis',
            dataIndex: 'avis',
            key: 'avis',
            sorter: (a, b) => a.avis.length - b.avis.length
        },
        {
            title: 'Date de publication',
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => new Date(a.date) - new Date(b.date)
        },

        {
            title: 'Adresse IP',
            dataIndex: 'ipaddr',
            key: 'ipaddr',
        },
        {
            title: 'Like',
            dataIndex: 'like',
            key: 'like',
            render: (_, record) => (
                <Button
                    className={record.like ? 'like-button liked' : 'like-button'}
                    type="text"
                    icon={record.like ? <HeartFilled style={{fontSize: '18px'}}/> :
                        <HeartOutlined style={{fontSize: '18px'}}/>}
                    onClick={() => likeAvis(record)}
                >
                </Button>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <div>
                    <Popconfirm title="Action irréversible"
                                description="Êtes-vous sur de supprimer cet avis ?"
                                onConfirm={() => removeAvis(record.id)}
                                icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                                cancelText="Annuler"
                                okText="Supprimer"
                    >
                        <button
                            className="btn btn-danger"
                        >
                            <i className="bi bi-trash"></i> {fr.buttonRemove}
                        </button>
                    </Popconfirm>
                </div>
            ),
        },
    ];


    useEffect(() => {
        getAvis();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <h2 className="text-primary text-center fw-bold">Avis</h2>
            <h3 className="text-primary text-center">{listeAvis.length} avis</h3>
            <div className="table-responsive">
                <Table
                    rowKey="id"
                    dataSource={listeAvis}
                    columns={colonnes}
                    loading={listeAvis.length < 1}

                />
            </div>
        </>

    );
}

