import React, {useContext, useEffect, useState} from "react";
import {API_URL} from "../../utils/constants";
import {Form} from "react-bootstrap";
import {GlobalContext} from "../layout/GlobalProvider";
import {message} from "antd";
import TokenTimer from "./TokenTimer";
import ModalEditPassword from "./ModalEditPassword";

export default function Profile(props) {
    const [profile, setProfile] = useState();
    const {token, setToken} = useContext(GlobalContext)

    const getProfile = async () => {
        try {
            const response = await fetch(API_URL + "profile", {
                method: "GET",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                setProfile(res.profile)
                // res.access_token && setToken(res.access_token)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur est survenue")
        }

    }

    useEffect(() => {
        getProfile()
    }, []);

    // function changeNotification() {
    //     let data = new FormData();
    //     data.append("id", id);
    //     data.append("notification", notification);
    //     fetch(API_URL + "changeNotification", {
    //         method: "POST",
    //         body: data,
    //         headers: {Authorization: "Bearer " + props.token},
    //     }).then((res) => res.json().then((errcod) => null));
    // }

    // useEffect(() => {
    //     changeNotification();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [notification]);

    if (profile === undefined) {
        return (
            <div>
                Bite
            </div>
        )
    }

    return (
        <section
            className="page-section"
            ref={props.element}
        >
            <div className="container">
                <div className="product-item">
                    <div className="product-item-title d-flex">
                        <div className="bg-faded p-5 d-flex ms-auto rounded">
                            <h2 className="section-heading mb-0">
                                <span className="section-heading-upper">
                                    Dèrnière connexion :{" "}
                                    {profile.lastLogin === undefined
                                        ? "Loading..."
                                        : profile.lastLogin}
                                </span>
                                <span className="section-heading-lower">
                                    Bonjour{" "}
                                    {profile.nom === undefined ? "Loading..." : profile.nom}
                                </span>
                            </h2>
                        </div>
                    </div>
                    <div className="product-item-description d-flex me-auto">
                        <div className="bg-faded p-5 rounded">
                            <div className="mb-0">
                                <TokenTimer/>
                                Si vous rencontrez des problèmes dans la
                                navigation du site, contactez{" "}
                                <strong>l'administrateur</strong> dans les plus
                                brefs délais <br/>
                                Identifiant :
                                {profile.nom === undefined ? " Loading..." : profile.nom} <br/>
                                Adresse e-mail :{" "}
                                {profile.email === undefined
                                    ? " Loading..."
                                    : profile.email}{" "}
                                <br/>
                                Role :{" "}
                                {profile.type === undefined ? " Loading..." : profile.type}
                            </div>
                            <ModalEditPassword>
                                <i className="bi bi-pencil"></i> Modifier votre
                                mot de passe
                            </ModalEditPassword>
                            <br/>
                            <div>
                                Notifications :
                                <Form.Check
                                    type="switch"
                                    id="switchActiver"
                                    label={
                                        profile.notification ? "Activé" : "Désactivé"
                                    }
                                    checked={profile.notification}
                                    onChange={() =>
                                        profile.setNotification(!profile.notification)
                                    }
                                ></Form.Check>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
