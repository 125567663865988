import React from "react";
import {Helmet} from "react-helmet";
import fr from "../utils/FR.json";
import Avis from "../components/avis/Avis";
import Header from "../components/layout/Header";

const titre = "Partagez votre expérience - Boulangerie Pâtisserie l'Épi d'Or"
const url = "https://boulangerielepidor.fr/Other/Notice"
const content = "Exprimez votre satisfaction envers les viennoiseries et pâtisseries de la Boulangerie Pâtisserie l'Épi d'Or à Orléans en laissant un avis. Vos recommandations sont importantes pour nous, faites-nous savoir ce que vous pensez de notre établissement."
const imgPreview = "preview/Avis.png"
const PageAvis = ({element, token, role}) => {
    return (
        <>
            <Helmet>
                <title>
                    {titre}
                </title>
                <meta
                    name="title"
                    content={titre}
                />
                <meta
                    name="description"
                    content={content}
                />

                <meta property="og:type" content="website"/>
                <meta
                    property="og:url"
                    content={url}
                />
                <meta
                    property="og:title"
                    content={titre}
                />
                <meta
                    property="og:description"
                    content={content}
                />
                <meta property="og:image" content={imgPreview}/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta
                    property="twitter:url"
                    content={url}
                />
                <meta
                    property="twitter:title"
                    content={titre}
                />
                <meta
                    property="twitter:description"
                    content={content}
                />
                <meta property="twitter:image" content={imgPreview}/>
            </Helmet>
            <Header
                title={fr.avisTitle}
                subtitle={fr.avisSubTitle}
                element={element}
                background={"avis.jpg"}
                hauteur={"70vh"}
            />
            <section className="page-section about-heading">
                <div className="container">
                    <div
                        className="about-heading-content"
                        style={{marginTop: "2rem"}}
                    >
                        <div className="row">
                            <div className="col-xl-9 col-lg-10 mx-auto">
                                <div className="bg-faded rounded p-5">
                                    <h2 className="section-heading mb-4">
                                        <span className="section-heading-upper">
                                            {fr.avisRulesSubTitles}
                                        </span>
                                        <span className="section-heading-lower">
                                            {fr.avisRulesTitle}
                                        </span>
                                    </h2>
                                    <p>{fr.avisRulesText[1]}</p>
                                    <p className="mb-0">
                                        {fr.avisRulesText[2]}{" "}
                                        <a
                                            href="https://www.google.com/search?q=Boulangerie+l%27epi+d%27or+Orl%C3%A9ans&rlz=1C1CHBF_frFR922FR922&sxsrf=ALiCzsbUCRdG9X0hAn2qry5CBlhZUgnLew%3A1657706935359&ei=t5nOYp3AFeyGwbkPuMaP6A0&ved=0ahUKEwjd95fIz_X4AhVsQzABHTjjA90Q4dUDCA8&uact=5&oq=Boulangerie+l%27epi+d%27or+Orl%C3%A9ans&gs_lcp=Cgdnd3Mtd2l6EAMyBggAEB4QFjoECCMQJzoOCC4QgAQQsQMQxwEQ0QM6CwguEIAEEMcBENEDOhEILhCABBCxAxCDARDHARDRAzoICAAQgAQQsQM6BAguECc6BwgAELEDEEM6BQgAEIAEOgsIABCABBCxAxCDAToKCC4QxwEQ0QMQJzoHCAAQyQMQQzoLCC4QgAQQxwEQrwE6BAgAEEM6CwgAEIAEELEDEMkDOg0IABCABBCHAhDJAxAUOgUIABCSAzoKCAAQgAQQhwIQFDoHCAAQgAQQCjoICAAQgAQQyQM6CwguEJIDEMcBEK8BOgoILhDHARCvARANSgQIQRgASgQIRhgAUABY9F1g0GNoAHABeACAAbgBiAHgI5IBBDAuMzCYAQCgAQHAAQE&sclient=gws-wiz"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {fr.avisRulesText[3]}
                                        </a>
                                        {fr.avisRulesText[4]}
                                    </p>
                                    <br/>
                                    <p className="mb-0">
                                        {fr.avisRulesText[5]}
                                    </p>
                                    <br/>

                                    <p className="mb-0">{fr.avisRulesThanks}</p>
                                    <small>{fr.avisRulesAdd}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Avis/>
        </>
    );
};

export default PageAvis;
