import React, {useContext, useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {Checkbox, message, Spin} from "antd";
import {API_URL} from "../../../utils/constants";
import {GlobalContext} from "../../layout/GlobalProvider";


function OptionsProduit({produitData, onProduitDataChange, typeOfModal}) {

    const [formes, setFormes] = useState([])
    const [decors, setDecors] = useState([])
    const [loading, setLoading] = useState(false)
    const {token} = useContext(GlobalContext)
    const getFormes = async () => {
        setLoading(true)
        try {
            const response = await fetch(API_URL + "get-all-formes", {
                method: "GET",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                setFormes(res.data)
                onProduitDataChange({formes: res.data})
            } else {
                message.error(res.message)

            }
        } catch (e) {
            message.error("Une erreur s'est produite")
        } finally {
            setLoading(false)
        }
    };
    const getDecors = async () => {
        setLoading(true)
        try {
            const response = await fetch(API_URL + "get-all-decors", {
                method: "GET",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                setDecors(res.data)
                onProduitDataChange({decors: res.data})
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur s'est produite")
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        if (typeOfModal === "add") {
            getDecors()
            getFormes()
        }
    }, [])

    const handleFormeChange = (formeId, checked) => {
        const updatedFormes = produitData.formes.map((forme) => {
            if (forme.id === formeId) {
                return {...forme, is_present: checked};
            }
            return forme;
        });

        const updatedProduitData = {
            ...produitData,
            formes: updatedFormes,
        };

        onProduitDataChange(updatedProduitData);
    };

    const handleDecorChange = (decorId, checked) => {
        const updatedDecors = produitData.decors.map((decor) => {
            if (decor.id === decorId) {
                return {...decor, is_present: checked};
            }
            return decor;
        });

        const updatedProduitData = {
            ...produitData,
            decors: updatedDecors,
        };
        onProduitDataChange(updatedProduitData);
    };


    return (
        <Modal.Body>
            <h3>Formes disponibles</h3>
            <Spin spinning={loading || produitData.formes.length <= 0} tip="Chargement des formes ...">
                {produitData.formes.map((forme) => (
                    <Checkbox
                        key={forme.id}
                        checked={forme.is_present}
                        onChange={(e) =>
                            handleFormeChange(forme.id, e.target.checked)
                        }
                    >
                        {forme.forme}
                    </Checkbox>
                ))}
            </Spin>
            <h3>Décors disponibles</h3>
            <Spin spinning={loading || produitData.decors.length <= 0} tip="Chargement des décors ...">
                {produitData.decors.map((decor) => (
                    <Checkbox
                        key={decor.id}
                        checked={decor.is_present}
                        onChange={(e) =>
                            handleDecorChange(decor.id, e.target.checked)
                        }
                    >
                        {decor.nappage}
                    </Checkbox>
                ))}
            </Spin>
        </Modal.Body>
    );
}

export default OptionsProduit;