import React from "react";
import GestionUtilisateurs from "../components/gestion/GestionUtilisateurs";
import ModalAddUtilisateur from "../components/connexion/ModalAddUtilisateur";

const PageGestionUtilisateurs = ({element, token}) => {
    return (
        <>
            <header className="bg-secondary py-5" ref={element}>
                <div className="container px-4 px-lg-5 my-5">
                    <div className="text-center text-white">
                        <h1 className="display-4 fw-bolder">
                            Gestion des Utilisateurs
                        </h1>
                        <p className="lead fw-normal text-white-50 mb-0">
                            Réservé aux personnels administrateurs
                        </p>
                        <ModalAddUtilisateur token={token}>
                            <i className="bi bi-plus-lg"></i> Ajouter un
                            utilisateur
                        </ModalAddUtilisateur>
                    </div>
                </div>
            </header>
            <GestionUtilisateurs token={token}/>
        </>
    );
};

export default PageGestionUtilisateurs;
