import React, {useContext, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {API_URL} from "../../../utils/constants";

import fr from "../../../utils/FR.json";
import {message, Spin, Tabs} from "antd";
import {GlobalContext} from "../../layout/GlobalProvider";
import GeneralProduit from "./GeneralProduit";
import AllergenesProduit from "./AllergenesProduit";
import OptionsProduit from "./OptionsProduit";

const ModalAddProduit = ({children, getProduits}) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const toggle = () => setShow(!show);
    const {token} = useContext(GlobalContext)

    const [produitData, setProduitData] = useState({
        nom: "",
        type: "Boulangerie",
        prix: "",
        mesurePrix: "unitaire",
        minPers: 1,
        maxPers: 1,
        nouveau: true,
        description: "",
        specialite: "Aucune",
        image: "none.png",
        nomImage: "none.png",
        display: true,
        dispo: "Disponible",
        allergenes: [],
        formes: [],
        decors: [],
    });

    const handleProduitDataChange = (newData) => {
        setProduitData((prevData) => ({...prevData, ...newData}));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (
            produitData.minPers < 0 ||
            produitData.maxPers < 0 ||
            produitData.minPers > produitData.maxPers ||
            produitData.maxPers < produitData.minPers
        ) {
            message.warning(
                "Il semblerait que les valeurs min et max de personne sont impossible"
            );
            setLoading(false);
            return null;
        }
        let data = new FormData();
        data.append("nom", produitData.nom);
        data.append("type", produitData.type);
        data.append("prix", produitData.prix);
        data.append("minPers", produitData.minPers);
        data.append("maxPers", produitData.maxPers);
        data.append("nouveau", produitData.nouveau);
        data.append("description", produitData.description);
        data.append("specialite", produitData.specialite);
        data.append("image", produitData.image);
        data.append("display", produitData.display);
        data.append("dispo", produitData.dispo);
        data.append("mesurePrix", produitData.mesurePrix);
        data.append("allergenes", JSON.stringify(produitData.allergenes));
        data.append("formes", JSON.stringify(produitData.formes));
        data.append("decors", JSON.stringify(produitData.decors));

        const Upload = async () => {
            try {
                const response = await fetch(API_URL + "addProduct", {
                    method: "POST",
                    headers: {Authorization: "Bearer " + token},
                    body: data,
                })
                const res = await response.json()
                if (res.status !== 200) {
                    message.error(res.message)
                } else {
                    toggle()
                    getProduits()
                    resetForm()
                    message.success(res.message)
                }
            } catch (e) {
                message.error("Une erreur s'est produite")
            }
            setLoading(false)
        };
        Upload();
    };

    const resetForm = () => {
        setProduitData({
            nom: "",
            type: "Boulangerie",
            prix: "",
            mesurePrix: "unitaire",
            minPers: 1,
            maxPers: 1,
            nouveau: true,
            description: "",
            specialite: "Aucune",
            image: "none.png",
            nomImage: "none.png",
            display: true,
            dispo: "Disponible",
            allergenes: [],
            formes: [],
            decors: [],
        });
    };

    const items = [
        {
            key: 1,
            label: "Fiche Produit",
            children: <GeneralProduit produitData={produitData} onProduitDataChange={handleProduitDataChange}
                                      typeOfModal="add" getProduits={getProduits}/>
        },
        {
            key: 2,
            label: "Allergènes",
            children: <AllergenesProduit produitData={produitData} onProduitDataChange={handleProduitDataChange}
                                         typeOfModal="add"/>
        },
        {
            key: 3,
            label: "Options",
            children: <OptionsProduit produitData={produitData} onProduitDataChange={handleProduitDataChange}
                                      typeOfModal="add"/>
        },
    ]

    return (
        <>
            <button className="btn btn-outline-primary" onClick={toggle}>
                <i className="bi bi-plus-lg"></i> {children}
            </button>

            <Modal show={show} onHide={toggle}>
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter un produit</Modal.Title>
                </Modal.Header>

                <Spin tip={"Ajout en cours ..."} spinning={loading} size="large">
                    <Tabs items={items} centered tabPosition={"top"}/>
                    <Modal.Footer>

                        <Button variant="secondary" onClick={toggle}>
                            {fr.buttonClose}
                        </Button>

                        <Button variant="primary" onClick={handleSubmit}>
                            <i className="bi bi-plus-lg"></i> {fr.buttonAdd}
                        </Button>

                    </Modal.Footer>
                </Spin>
            </Modal>
        </>
    );
};

export default ModalAddProduit;
