import React from 'react';
import {Card, Skeleton} from "antd";
import Meta from "antd/es/card/Meta";


function ProductCardLoading() {

    return (
        <article
            className={`col-lg-4 col-md-6 wow `}
            data-wow-delay="0.1s"
            style={{marginBottom: "4rem"}}
        >
            <div
                className=" d-flex flex-column bg-white rounded overflow-hidden h-100">
                <div className="d-flex row justify-content-center gap-3">
                    <Card loading={true}>
                        <Meta
                            title="Chargement"
                            description="Chargement ..."
                        />
                    </Card>
                    <Skeleton.Image active={true} className="mb-1"
                                    style={{width: '250px', height: '200px'}}/>
                </div>
            </div>
        </article>
    );
}

export default ProductCardLoading;