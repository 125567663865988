import {Helmet} from "react-helmet";
import fr from "../utils/FR.json";
import About from "../components/layout/About";
import Header from "../components/layout/Header";

const titre = "Horaires, Adresse et Emplacement - Boulangerie Pâtisserie l'Épi d'Or"
const url = "https://boulangerielepidor.fr/About"
const content = "Découvrez les horaires d'ouverture et l'emplacement de la boulangerie pâtisserie l'Épi d'Or à Orléans. Nous sommes ouverts du mardi au dimanche pour vous proposer des produits frais et savoureux. Ne manquez pas de nous rendre visite !"
const imgPreview = "preview/About.png"
const PageAbout = ({element}) => {
    return (
        <>
            <Helmet>
                <title>
                    {titre}
                </title>
                <meta
                    name="title"
                    content={titre}
                />
                <meta
                    name="description"
                    content={content}
                />

                <meta property="og:type" content="website"/>
                <meta
                    property="og:url"
                    content={url}
                />
                <meta
                    property="og:title"
                    content={titre}
                />
                <meta
                    property="og:description"
                    content={content}
                />
                <meta property="og:image" content={imgPreview}/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta
                    property="twitter:url"
                    content={url}
                />
                <meta
                    property="twitter:title"
                    content={titre}
                />
                <meta
                    property="twitter:description"
                    content={content}
                />
                <meta property="twitter:image" content={imgPreview}/>
            </Helmet>
            <Header
                title={fr.aboutTitle}
                subtitle={fr.aboutSubTitle}
                element={element}
                background={"about.jpg"}
                hauteur={"70vh"}
            />
            <About/>
        </>
    );
};

export default PageAbout;
