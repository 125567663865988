export default function FormatDate(date, jour, heure) {
    const d = new Date(date);
    let options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "2-digit",
    };
    const dateFR = d.toLocaleDateString("fr-FR", options);
    const timeFR = d.toLocaleTimeString("fr-FR");

    if (jour && !heure) {
        return dateFR;
    }
    if (!jour && heure) {
        return timeFR;
    }
    if (jour && heure) {
        return dateFR + " à " + timeFR;
    }
}
