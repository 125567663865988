import React, {useEffect, useState} from 'react';
import jwtDecode from 'jwt-decode';

function TokenTimer() {
    const token = localStorage.getItem('token');
    const decodedToken = jwtDecode(token);
    const expirationTime = decodedToken.exp * 1000; // Convertir l'expiration du token en millisecondes
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    function calculateTimeLeft() {
        const difference = expirationTime - new Date().getTime();
        const secondsLeft = Math.floor(difference / 1000);

        return {
            hours: Math.floor(secondsLeft / 3600),
            minutes: Math.floor((secondsLeft % 3600) / 60),
            seconds: secondsLeft % 60,
        };
    }

    function formatTimeValue(value) {
        return value.toString().padStart(2, '0');
    }

    return (
        <div>
            Prochaine déconnexion dans {" "}
            {timeLeft.hours > 0 && (
                <span>
                    {formatTimeValue(timeLeft.hours)}h{' '}
                </span>
            )}
            {timeLeft.minutes > 0 && (
                <span>
                    {formatTimeValue(timeLeft.minutes)}min{' '}
                </span>
            )}
            <span>{formatTimeValue(timeLeft.seconds)}s</span>
        </div>
    );
}

export default TokenTimer;
