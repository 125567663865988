import React, {useEffect, useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import "./styles/Welcome.css";
import "./styles/Navbar.css";

import "./styles/styles.css";
import "./styles/style.css";

import useSticky from "./components/hooks/useSticky";
import PageWelcome from "./pages/PageWelcome";
import Navbar from "./components/layout/Navbar";
import PageAvis from "./pages/PageAvis.js";
import PageAbout from "./pages/PageAbout";
import PageGestion from "./pages/PageGestion";
import PageProduits from "./pages/PageProduits";
import PageSpecialites from "./pages/PageSpecialites";
import PagePieceMontee from "./pages/PagePieceMontee";
import PageContact from "./pages/PageContact";
import PageError404 from "./pages/PageError404";
import PageLegalNotice from "./pages/PageLegalNotice";
import PagePrivacyPolicy from "./pages/PagePrivacyPolicy";
import PageTmp from "./pages/PageTmp";
import PageHack from "./pages/PageHack";
import ScrollToTop from "./components/hooks/useScrollToTop";

import Login from "./components/connexion/Login";
import Profile from "./components/connexion/Profile";

import Footer from "./components/layout/Footer";
import PagePhoto from "./pages/PagePhoto";
import PageGestionUtilisateurs from "./pages/PageGestionUtilisateur";
import jwtDecode from "jwt-decode";
import DisconnectModal from "./components/connexion/DisconnectModal";

import CookieConsent, {Cookies} from "react-cookie-consent";
import fr from "./utils/FR.json";
import PageMaintenance from "./pages/PageMaintenance";
import {ConfigProvider} from "antd";
import GlobalProvider from "./components/layout/GlobalProvider";
import PrivateRoute from "./components/connexion/PrivateRoute";
import useToken from "./components/connexion/useToken";
import Snowfall from "react-snowfall";

function App() {
    const {isSticky, element} = useSticky();
    const [toggleModal, setToggleModal] = useState(false);
    const [showCookieModal, setShowCookieModal] = useState(true);
    const [maintenance, setMaintenance] = useState(false);
    const [snowFalling, setSnowFalling] = useState(false);
    const {token, role, setToken} = useToken()

    useEffect(() => {
        try {
            if (token) {
                const user = localStorage.getItem("token");
                const decode = jwtDecode(user);
                if (decode.exp * 1000 < Date.now()) {
                    setToggleModal(true);
                    console.log("Expiation du token");
                    // removeToken();
                }
            }
            if (Cookies.get(fr.nameLastCookiesContract) !== undefined) {
                setShowCookieModal(false);
            }
        } catch (e) {
            localStorage.removeItem("token")
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <BrowserRouter>
            <GlobalProvider setMaintenance={setMaintenance} setSnowFalling={setSnowFalling}>
                {snowFalling && <Snowfall
                    // Changes the snowflake color
                    color="white"
                    // Applied to the canvas element
                    style={{position: "fixed"}}
                    // Controls the number of snowflakes that are created (default 150)
                    snowflakeCount={150}
                ></Snowfall>}
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#e6a756',
                            colorText: 'black',
                        }
                    }}>
                    <CookieConsent
                        disableButtonStyles
                        visible={showCookieModal ? "show" : "hidden"}
                        buttonText={fr.cookiesButtonAgree}
                        declineButtonText={fr.cookiesButtonRefuse}
                        expires={30}
                        buttonClasses="btn btn-primary m-1"
                        declineButtonClasses="btn btn-danger m-1"
                        cookieName="cookies-01-10-2022"
                        enableDeclineButton
                        onAccept={() => setShowCookieModal(false)}
                        onDecline={() => setShowCookieModal(false)}
                        overlay
                    >
                        {fr.cookiesAgreement} <br/>
                        <small>
                            {fr.cookiesMoreInfo}{" "}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={fr.routePrivacyPolicy}
                            >
                                {fr.privacyPolicy}
                            </a>
                        </small>
                    </CookieConsent>
                    <ScrollToTop>
                        {toggleModal &&
                            <DisconnectModal setToggleModal={() => setToggleModal()}/>
                        }
                        <Navbar
                            sticky={isSticky}
                        />

                        <Routes>
                            <Route element={<PrivateRoute/>}>
                                <Route
                                    exact
                                    path={fr.routeManagement}
                                    element={
                                        <PageGestion
                                            element={element}
                                            token={token}
                                            setToken={setToken}
                                            role={role}
                                        />
                                    }
                                />
                                <Route
                                    exact
                                    path={fr.routeProfile}
                                    element={
                                        <Profile
                                            token={token}
                                            setToken={setToken}
                                            element={element}
                                            role={role}
                                        />
                                    }
                                />
                                {role === "ADMIN" &&
                                    <Route
                                        exact
                                        path={fr.routeManagementUsers}
                                        element={
                                            <PageGestionUtilisateurs
                                                element={element}
                                                token={token}
                                                role={role}
                                            />
                                        }
                                    />
                                }
                            </Route>
                            <Route
                                exact
                                path={fr.routeLegalNotice}
                                element={<PageLegalNotice element={element}/>}
                            />
                            <Route
                                exact
                                path={fr.routePrivacyPolicy}
                                element={<PagePrivacyPolicy element={element}/>}
                            />
                            <Route
                                path="*"
                                element={<PageError404 element={element}/>}
                            />
                            <Route
                                exact
                                path={fr.routeTemp}
                                element={<PageTmp element={element}/>}
                            />

                            {!maintenance ? (
                                <>
                                    {[fr.routeDefault, fr.routeHome].map((p, e) => (
                                        <Route
                                            key={e}
                                            exact
                                            path={p}
                                            element={
                                                <PageWelcome
                                                    element={element}
                                                    token={token}
                                                    role={role}
                                                />
                                            }
                                        />
                                    ))}
                                    {[
                                        "/index.html",
                                        "/admin",
                                        "/test.php",
                                        "/server-status",
                                    ].map((p, e) => (
                                        <Route
                                            key={e}
                                            exact
                                            path={p}
                                            element={
                                                <PageHack
                                                    element={element}
                                                    token={token}
                                                    role={role}
                                                />
                                            }
                                        />
                                    ))}

                                    <Route
                                        exact
                                        path={fr.routeShopProduct}
                                        element={
                                            <PageProduits
                                                element={element}
                                                token={token}
                                                role={role}
                                            />
                                        }
                                    />
                                    <Route
                                        exact
                                        path={fr.routeShopSpecialite}
                                        element={<PageSpecialites element={element}/>}
                                    />

                                    <Route
                                        exact
                                        path={fr.routeLogin}
                                        element={
                                            <Login
                                                element={element}
                                                setToken={setToken}
                                                token={token}
                                                role={role}
                                            />
                                        }
                                    />

                                    <Route
                                        exact
                                        path={fr.routeOtherNotice}
                                        element={
                                            <PageAvis
                                                element={element}
                                                token={token}
                                                role={role}
                                            />
                                        }
                                    />
                                    <Route
                                        exact
                                        path={fr.routeShopCake}
                                        element={
                                            <PagePieceMontee
                                                element={element}
                                                token={token}
                                                role={role}
                                            />
                                        }
                                    />

                                    <Route
                                        exact
                                        path={fr.routeOtherPhoto}
                                        element={
                                            <PagePhoto
                                                element={element}
                                                token={token}
                                                role={role}
                                            />
                                        }
                                    />
                                    <Route
                                        exact
                                        path={fr.routeAbout}
                                        element={<PageAbout element={element}/>}
                                    />
                                    <Route
                                        exact
                                        path={fr.routeContact}
                                        element={<PageContact element={element}/>}
                                    />

                                </>
                            ) : (
                                [
                                    fr.routeOtherPhoto,
                                    fr.routeAbout,
                                    fr.routeContact,
                                    fr.routeShopCake,
                                    fr.routeOtherNotice,
                                    fr.routeManagementUsers,
                                    fr.routeProfile,
                                    fr.routeLogin,
                                    fr.routeShopSpecialite,
                                    fr.routeShopProduct,
                                    fr.routeDefault,
                                    fr.routeHome,
                                ].map((e, p) => (
                                    <Route
                                        key={p}
                                        exact
                                        path={e}
                                        element={<PageMaintenance element={element}/>}
                                    />
                                ))
                            )}
                        </Routes>
                    </ScrollToTop>
                    <Footer setShowCookieModal={setShowCookieModal}/>
                </ConfigProvider>
            </GlobalProvider>
        </BrowserRouter>
    );
}

export default App;
