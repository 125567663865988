import React, {useContext, useEffect, useState} from 'react';
import {message, Popconfirm, Select, Spin, Table, Tag} from "antd";
import {GlobalContext} from "../layout/GlobalProvider";
import {API_URL} from "../../utils/constants";
import fr from "../../utils/FR.json";
import {QuestionCircleOutlined} from "@ant-design/icons";

function GestionContact(props) {
    const [email, setEmail] = useState([])
    const [loadingRows, setLoadingRows] = useState([]);

    const {token} = useContext(GlobalContext)

    async function getEmails() {
        try {
            const response = await fetch(API_URL + "get-emails-admin", {
                method: "GET",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                setEmail(res.data)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur s'est produite")
        }
    }

    const stateColor = [{etat: "Non traité", color: "gray"}, {etat: "En cours ...", color: "yellow"}, {
        etat: "Traité",
        color: "lime"
    }, {etat: "Rejeté", color: "volcano"}]
    const getColorByEtat = (etat) => {
        const found = stateColor.find((item) => item.etat === etat);
        return found ? found.color : 'geekblue'; // Couleur par défaut si aucun état ne correspond
    };

    async function removeEmails(idEmail) {
        try {
            const response = await fetch(API_URL + "remove-email/" + idEmail, {
                method: "DELETE",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                getEmails()
                message.success(res.message)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur s'est produite")
        }
    }

    useEffect(() => {
        getEmails()
    }, [])

    const handleQuickChange = async (emailId, value) => {
        setLoadingRows((prevLoadingRows) => {
            return [...prevLoadingRows, emailId]; // Ajouter l'ID à la liste des lignes en cours de chargement
        });
        // Mettez à jour l'état du bouton switch dans le composant
        const updatedEmails = email.map((email) => {
            if (email.id === emailId) {
                return {...email, enabled: value};
            }
            return email;
        });
        setEmail(updatedEmails);
        try {
            // Effectuez une requête vers la base de données pour mettre à jour l'état du produit
            let data = new FormData();
            data.append("emailId", emailId);
            data.append("valeur", value);
            const response = await fetch(API_URL + "quick-change-email", {
                method: "POST",
                body: data,
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                await getEmails()
                message.success(res.message)
            } else {
                message.error(res.message)
            }

        } catch (error) {
            message.error(`Erreur lors de la mise à jour de l'état de la photo : ${emailId}`);
        }
        setLoadingRows((prevLoadingRows) =>
            prevLoadingRows.filter((rowId) => rowId !== emailId) // Supprimer l'ID de la liste des lignes en cours de chargement une fois la requête terminée
        );
    };

    const colonnes = [
        {
            title: 'Nom',
            dataIndex: 'nom',
            key: 'nom',
            sorter: (a, b) => a.nom.localeCompare(b.nom),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
        },
        {
            title: 'Objet',
            dataIndex: 'objet',
            key: 'objet',
            sorter: (a, b) => a.objet.localeCompare(b.objet),
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            sorter: (a, b) => a.message.localeCompare(b.message),
        },
        {
            title: 'Date de récéption',
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            defaultSortOrder: 'descend',
        },
        {
            title: 'Etat',
            dataIndex: 'etat',
            key: 'etat',
            render: (etat, record) => (
                <Spin spinning={loadingRows.includes(record.id)}>
                    <Select
                        value={etat}
                        onChange={(value) => handleQuickChange(record.id, value)}
                        style={{width: 120}}
                    >
                        {stateColor.map((state) => (
                            <Select.Option value={state.etat} key={state.etat}>
                                <Tag color={state.color}>{state.etat}</Tag>
                            </Select.Option>
                        ))}
                    </Select>
                </Spin>
            ),
        },
        {
            title: 'IP',
            dataIndex: 'ip',
            key: 'ip',
            sorter: (a, b) => a.ip.localeCompare(b.ip),
        },

        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <div>
                    <Popconfirm title="Action irréversible"
                                description="Êtes-vous sur de supprimer cette email ?"
                                onConfirm={() => removeEmails(record.id)}
                                icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                                cancelText="Annuler"
                                okText="Supprimer"
                    >
                        <button className="btn btn-danger">
                            <i className="bi bi-trash"></i> {fr.buttonRemove}
                        </button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <>
            <h2 className="text-primary text-center fw-bold">Prise de contact</h2>
            <h3 className="text-primary text-center">{email.length} emails reçus</h3>
            <div className="table-responsive">
                <Table
                    rowKey="id"
                    dataSource={email}
                    columns={colonnes}
                    loading={email.length < 1}
                />
            </div>
        </>
    );
}

export default GestionContact;