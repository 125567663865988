import React, {useContext} from 'react';
import ModalProduit from "./modalShowProduit/ModalProduit";
import fr from "../../utils/FR.json";
import {Card} from "antd";
import Meta from "antd/es/card/Meta";
import {API_URL} from "../../utils/constants";
import {useInView} from "react-intersection-observer";
import {GlobalContext} from "../layout/GlobalProvider";

function ProductCard({produit}) {

    const {globalConfig} = useContext(GlobalContext)

    const {ref, inView, entry} = useInView({
        triggerOnce: false, // Déclenche l'événement une seule fois
        // threshold: 0.1, // Pourcentage de visibilité nécessaire pour déclencher l'événement
    });


    if (produit === undefined) {
        return (
            <Card style={{width: 300, marginTop: 16}} loading={true}>
                <Meta
                    title="Chargement"
                    description="Chargement ..."
                />
            </Card>
        )
    }


    return (
        <article
            className={`col-lg-4 col-md-6 wow ${inView ? "fadeInFromCenter" : "not-visible"} `}
            data-wow-delay="0.1s"
            style={{marginBottom: "4rem"}}
            key={produit.id}
            ref={ref}
        >
            <div className="product-items d-flex flex-column bg-white rounded overflow-hidden h-100">

                <div className="text-center p-4">

                    {globalConfig.displayPrice &&
                        <div className="d-inline-block border border-primary rounded-pill px-3 mb-3">
                            {produit.prix}
                            {produit.mesurePrix === "unitaire"
                                ? "€"
                                : "€/kg"}
                        </div>}
                    <h3 className="mb-3">
                        {produit.nom.charAt(0).toUpperCase() +
                            produit.nom.slice(1) +
                            " "}
                    </h3>
                    <h6 className="mb-3">{produit.type}</h6>
                    <span>
                        {produit.specialite !== "Aucune" && `Spécialité : ${produit.specialite}`}
                    </span>
                </div>
                <div className="position-relative mt-auto">
                    {produit.nouveau &&
                        <div
                            className="badge bg-warning text-dark position-absolute"
                            style={{
                                top: "0.5rem",
                                right: "0.5rem",
                            }}
                        >
                            Nouveau
                        </div>}

                    {produit.disponibilite ===
                    fr.disponibilite.disponible ? null : produit
                        .disponibilite ===
                    fr.disponibilite.weekend ||
                    produit.disponibilite ===
                    fr.disponibilite.commande ? (
                        <div
                            className="badge bg-dark text-white position-absolute"
                            style={{
                                top: "0.5rem",
                                left: "0.5rem",
                            }}
                        >
                            {produit.disponibilite}
                        </div>
                    ) : (
                        <div
                            className="badge bg-danger text-white position-absolute"
                            style={{
                                top: "0.5rem",
                                left: "0.5rem",
                            }}
                        >
                            Indisponible
                        </div>
                    )}
                    <img
                        className="img-fluid"
                        style={{
                            width: "100%",
                            height: "30vh",
                            objectFit: "cover",
                        }}
                        src={produit.image === "none.png" ? require("../../img/loading.jpg")
                            : API_URL + "get_image/imageProduit/" + produit.image}
                        alt={produit.name}
                        loading="lazy"
                    />

                    <div className="product-overlay">
                        <ModalProduit produit={produit}>
                            <a className="btn btn-lg-square btn-outline-light rounded-circle">
                                <i className="fa fa-eye text-primary"></i>
                            </a>
                        </ModalProduit>
                    </div>
                </div>

            </div>
        </article>
    );
}

export default ProductCard;