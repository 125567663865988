import Header from "../components/layout/Header";
import {MAIL_CONTACT, PHONE_NUMBER} from "../utils/constants";
import fr from "../utils/FR.json";
import FormulaireContact from "../components/layout/FormulaireContact";
import {Helmet} from "react-helmet";
import {NavLink} from "react-router-dom";

const titre = "Contact - Boulangerie Pâtisserie l'Épi d'Or"
const url = "https://boulangerielepidor.fr/Contact"
const content = "Besoin d'informations complémentaires sur notre boulangerie pâtisserie ? Vous souhaitez nous faire part de vos commentaires ou suggestions ? N'hésitez pas à nous contacter via notre formulaire en ligne ou par téléphone. L'équipe de l'Épi d'Or à Orléans est à votre écoute pour répondre à toutes vos questions."
const imgPreview = "preview/Contact.png"
const PageContact = ({element}) => {
    return (
        <>
            <Helmet>
                <title>
                    {titre}
                </title>
                <meta
                    name="title"
                    content={titre}
                />
                <meta
                    name="description"
                    content={content}
                />

                <meta property="og:type" content="website"/>
                <meta
                    property="og:url"
                    content={url}
                />
                <meta
                    property="og:title"
                    content={titre}
                />
                <meta
                    property="og:description"
                    content={content}
                />
                <meta property="og:image" content={imgPreview}/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta
                    property="twitter:url"
                    content={url}
                />
                <meta
                    property="twitter:title"
                    content={titre}
                />
                <meta
                    property="twitter:description"
                    content={content}
                />
                <meta property="twitter:image" content={imgPreview}/>
            </Helmet>
            <Header
                title={fr.contactTitle}
                subtitle={fr.contactSubTitle}
                element={element}
                background="contact.jpg"
                hauteur="70vh"
            />
            <section className="page-section clearfix">
                <div className="container">
                    <div className="intro">
                        <img
                            className="intro-img img-fluid mb-3 mb-lg-0 rounded"
                            src={require("../img/bg.jpg")}
                            alt="Boulangerie"
                        />
                        <div className="intro-text left-0 text-center bg-faded p-5 rounded">
                            <h2 className="section-heading mb-4">
                                <span className="section-heading-upper">
                                    {fr.contactCoordonneeSubTitle}
                                </span>
                                <span className="section-heading-lower">
                                    {fr.contactCoordonneeTitle}
                                </span>
                            </h2>
                            <p className="mb-3">
                                Venez commandez en
                                boutique ou par téléphone. Pour tout autres questions appellez
                                nous au{" "}
                                {/* {fr.contactCoordonneeText[1]}{" "} */}
                                <a
                                    href={`tel:${PHONE_NUMBER}`}
                                    style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                    }}
                                >
                                    <strong>{PHONE_NUMBER}</strong>
                                </a>{" "}
                                {fr.contactCoordonneeText[2]}
                            </p>
                            <p className="mb-3">
                                {fr.contactCoordonneeText[3]}{" "}
                                <a
                                    href={`mailto:${MAIL_CONTACT}`}
                                    style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                    }}
                                >
                                    <strong>{MAIL_CONTACT}</strong>
                                </a>
                            </p>
                            {fr.contactCoordonneeText[4]}
                            <br/>
                            <em>La boulangerie recrute, envoyez nous vos CV</em>
                            <div className="intro-button mx-auto">
                                <NavLink
                                    className="btn btn-primary btn-xl"
                                    to={fr.routeHome}
                                >
                                    Revenir au menu
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FormulaireContact/>

        </>
    );
};

export default PageContact;
