import React, {useContext, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {API_URL} from "../../utils/constants";
import fr from "../../utils/FR.json";
import {message, Spin} from "antd";
import {GlobalContext} from "../layout/GlobalProvider";
import MultiUploadPreview from "../gestion/MultiUploadPreview";

const ModalAddPhoto = ({children, getPhotos}) => {
    const [show, setShow] = useState(false);
    const {token} = useContext(GlobalContext)
    const toggle = () => setShow(!show);

    const [titre, setTitre] = useState("");
    const [type, setType] = useState("Evenement");
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (photos.length === 0) {
            message.warning("Veuillez mettre une photo");
        } else {
            setLoading(true);

            const data = new FormData();
            data.append("titre", titre);
            data.append("type", type);
            for (let i = 0; i < photos.length; i++) {
                data.append("photos[]", photos[i].originFileObj);
            }


            const Upload = async () => {
                try {
                    const response = await fetch(API_URL + "add-photo", {
                        method: "POST",
                        headers: {Authorization: "Bearer " + token},
                        body: data,
                    })
                    const res = await response.json()
                    if (res.status === 200) {
                        toggle()
                        setTitre("");
                        setType("Evenement");
                        getPhotos()
                        message.success(res.message)
                    } else {
                        message.error(res.message)
                    }
                } catch (e) {
                    message.error("Une erreur est survenue")
                }
                setLoading(false)

            };
            Upload();
        }
    };

    return (
        <>
            <button className="btn btn-outline-primary" onClick={toggle}>
                <i className="bi bi-plus-lg"></i> {children}
            </button>

            <Modal show={show} onHide={toggle}>
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une photo</Modal.Title>
                </Modal.Header>
                <Spin spinning={loading} tip="Ajout de la photo en cours ...">
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <label className="fw-bold">Titre</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Entrer le titre"
                                value={titre}
                                onChange={(e) => setTitre(e.target.value)}
                            ></input>
                            <label className="fw-bold">Type de la photo</label>
                            <Form.Select
                                aria-label="Default select example"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                {Object.entries(fr.typePhoto).map((elem, i) => {
                                    return <option key={i}>{elem[1]}</option>;
                                })}
                            </Form.Select>
                            <label className="fw-bold">Image(s)</label>
                            <MultiUploadPreview setPhotos={setPhotos}/>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={toggle}>
                            {fr.buttonClose}
                        </Button>

                        <Button variant="primary" onClick={handleSubmit}>
                            <i className="bi bi-plus-lg"></i> {fr.buttonAdd}
                        </Button>

                    </Modal.Footer>
                </Spin>
            </Modal>
        </>
    );
};

export default ModalAddPhoto;
