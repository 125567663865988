import React from 'react';
import {Form, Modal} from "react-bootstrap";
import fr from "../../../utils/FR.json";
import UploadPreview from "../../gestion/UploadPreview";
import ImageActions from "../../gestion/ImageActions";

function GeneralProduit({produitData, onProduitDataChange, typeOfModal, idProduit}) {
    return (
        <Modal.Body>
            <Form>
                <label className="fw-bold">Nom du produit</label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Entrer le nom du produit"
                    value={produitData.nom}
                    onChange={(e) => onProduitDataChange({nom: e.target.value})}
                    required
                ></input>
                <label className="fw-bold">Type du produit</label>
                <Form.Select
                    aria-label="Default select example"
                    value={produitData.type}
                    onChange={(e) => onProduitDataChange({type: e.target.value})}
                >
                    {Object.entries(fr.typeProduit).map((elem, i) => {
                        return <option key={i}>{elem[1]}</option>;
                    })}
                </Form.Select>
                <div className="d-flex my-1 align-items-center">
                    <label className="fw-bold text-nowrap">Prix en €</label>
                    <input
                        type="number"
                        className="form-control mx-1"
                        placeholder="Entrer le prix du produit"
                        value={produitData.prix}
                        onChange={(e) => onProduitDataChange({prix: e.target.value})}
                    ></input>
                    <div className="text-end">
                        <div className="form-check-inline">
                            <label
                                className="form-check-label me-1"
                                htmlFor="mesurePrix1"
                            >
                                Prix à l'unité
                            </label>
                            <input
                                type="radio"
                                className="form-check-input"
                                id="mesurePrix1"
                                name="mesurePrix"
                                value="unitaire"
                                checked={produitData.mesurePrix === "unitaire"}
                                onChange={(e) => {
                                    onProduitDataChange({mesurePrix: e.target.value});
                                }}
                            />

                        </div>
                        <div className="form-check-inline">
                            <label
                                className="form-check-label me-1"
                                htmlFor="mesurePrix2"
                            >
                                Prix au kg
                            </label>
                            <input
                                className="form-check-input"
                                type="radio"
                                name="mesurePrix"
                                id="mesurePrix2"
                                value="kg"
                                checked={produitData.mesurePrix === "kg"}
                                onChange={(e) => {
                                    onProduitDataChange({mesurePrix: e.target.value})
                                }}
                            />

                        </div>
                    </div>

                </div>
                <div className="d-flex align-items-center justify-content-center gap-4">
                    <div>
                        <label className="fw-bold small text-nowrap">Nombre de personne
                            minimum</label>
                        <input
                            type="number"
                            className="form-control "
                            placeholder="Min"
                            value={produitData.minPers}
                            onChange={(e) => onProduitDataChange({minPers: e.target.value})}
                        ></input>
                    </div>
                    <div>
                        <label className="fw-bold small text-nowrap">Nombre de personne maximum</label>
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Max"
                            value={produitData.maxPers}
                            onChange={(e) => onProduitDataChange({maxPers: e.target.value})}
                        ></input>
                    </div>
                </div>
                <label className="fw-bold">Spécilaité du produit</label>
                <Form.Select
                    aria-label="Default select example"
                    value={produitData.specialite}
                    onChange={(e) => onProduitDataChange({specialite: e.target.value})}
                >
                    {Object.entries(fr.specialiteProduit).map(
                        (elem, i) => {
                            return <option key={i}>{elem[1]}</option>;
                        }
                    )}
                </Form.Select>
                <label className="fw-bold">Disponibilité</label>
                <Form.Select
                    value={produitData.dispo}
                    onChange={(e) => onProduitDataChange({dispo: e.target.value})}
                >
                    {Object.entries(fr.disponibilite).map((elem, i) => {
                        return <option key={i}>{elem[1]}</option>;
                    })}
                </Form.Select>
                <label className="fw-bold">
                    Description du produit
                </label>
                <textarea
                    type="text"
                    className="form-control"
                    placeholder="Entrer la description du produit"
                    value={produitData.description}
                    onChange={(e) => onProduitDataChange({description: e.target.value})}
                ></textarea>
                <label className="fw-bold">
                    Image
                </label>
                {typeOfModal !== "add" &&
                    <ImageActions image={produitData.image} chemin="imageProduit" idEntite={idProduit}
                    />
                }
                <UploadPreview onProduitDataChange={onProduitDataChange}/>
                <div className="d-flex justify-content-center gap-3">
                    <Form.Check
                        type="switch"
                        id="switchDisplay"
                        label="Afficher"
                        checked={produitData.display}
                        onChange={() => onProduitDataChange({display: !produitData.display})}
                    ></Form.Check>
                    <Form.Check
                        type="checkbox"
                        id="switchNouveau"
                        label="Nouveau"
                        checked={produitData.nouveau}
                        onChange={() => onProduitDataChange({nouveau: !produitData.nouveau})}
                    ></Form.Check>
                </div>

            </Form>
        </Modal.Body>
    );
}

export default GeneralProduit;