import React from "react";
import {Helmet} from "react-helmet";

const titre = "CGU - Boulangerie Pâtisserie l'Épi d'Or"
const url = "https://boulangerielepidor.fr/LegalNotice"
const content = "Découvrez les conditions générales d'utilisation de la boulangerie pâtisserie l'Épi d'Or à Orléans. Consultez nos règles d'utilisation pour un service optimal et une expérience client réussie."

const PageCGU = ({element}) => {

    return (
        <>
            <Helmet>
                <title>
                    {titre}
                </title>
                <meta
                    name="title"
                    content={titre}
                />
                <meta
                    name="description"
                    content={content}
                />

                <meta property="og:type" content="website"/>
                <meta
                    property="og:url"
                    content={url}
                />
                <meta
                    property="og:title"
                    content={titre}
                />
                <meta
                    property="og:description"
                    content={content}
                />

                <meta property="twitter:card" content="summary_large_image"/>
                <meta
                    property="twitter:url"
                    content={url}
                />
                <meta
                    property="twitter:title"
                    content={titre}
                />
                <meta
                    property="twitter:description"
                    content={content}
                />
            </Helmet>
            <header
                className="py-5"
                ref={element}
                style={{marginTop: "5rem"}}
            >
                <div className="container px-4 px-lg-5 my-5">
                    <div className="text-center text-white">
                        <h1 className="display-4 fw-bolder">
                            Mentions Légales
                        </h1>
                        <p className="lead fw-normal text-white-50 mb-0">
                            En vigueur au 01/10/2022
                        </p>
                    </div>
                </div>
            </header>
            <section
                className="page-section clearfix"
                style={{
                    padding: "3rem",
                    margin: "2rem",
                    borderRadius: "10px",
                    backgroundColor: "lightGrey",
                    opacity: "0.85",
                }}
            >
                <div className="container">
                    <h1 className="text-dark">
                        Mentions légales du site de la boulangerie l’Épi d’Or
                        d’Orléans
                    </h1>
                    <p>
                        Conformément aux dispositions des Articles 6-III et 19
                        de la Loi n°2004-575 du 21 juin 2004 pour la Confiance
                        dans l’économie numérique, dite L.C.E.N., il est porté à
                        la connaissance des utilisateurs et visiteurs, ci-après
                        "<strong>l'Utilisateur</strong>", du site{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://boulangerielepidor.fr"
                        >
                            https://boulangerielepidor.fr
                        </a>{" "}
                        , ci-après le "<strong>Site</strong>", les présentes
                        mentions légales.
                    </p>
                    <p>
                        La connexion et la navigation sur le Site par
                        l’Utilisateur implique acceptation intégrale et sans
                        réserve des présentes mentions légales.
                    </p>
                    <p>
                        Ces dernières sont accessibles sur le Site à la rubrique
                        « Mentions légales ».
                    </p>
                    <h2>ARTICLE 1 - L'Éditeur</h2>
                    <p>
                        L’édition et la direction de la publication du Site est
                        assurée par Sevot Maxime, domiciliée 59 rue Maurice
                        Dubois, dont le numéro de téléphone est 0663771255, et
                        l'adresse e-mail max.sevot@gmail.com.
                    </p>
                    <p>
                        La personne est assujettie au Registre du Commerce et
                        des Sociétés de Orléans sous le numéro Orleans A 439 648
                        635. Le numéro de TVA intracommunautaire est
                        FR57439648635.
                    </p>
                    <h2>ARTICLE 2 - L'Hebergeur</h2>
                    <p>
                        L'hébergeur du Site est la société Ionos, dont le siège
                        social est situé au 7, place de la BP 57200
                        Sarreguemines Cedex France.
                        <br/> Numéro de téléphone : 0970808911. <br/>
                        Adresse email de contact : info@ionos.fr
                    </p>
                    <h2>ARTICLE 3 - Accès au site</h2>
                    <p>
                        Le Site est accessible en tout endroit, 7j/7, 24h/24
                        sauf cas de force majeure, interruption programmée ou
                        non et pouvant découlant d’une nécessité de maintenance
                    </p>
                    <p>
                        En cas de modification, interruption ou suspension du
                        Site, l'Editeur ne saurait être tenu responsable.
                    </p>
                    <p>
                        Les informations présentent sur le site de la
                        boulangerie l’Épi d’Or d’Orléans ne sont présentent qu’à
                        but informatif. Les informations ne sont pas tenu à jour
                        en temps réel
                    </p>
                    <p>
                        Chaque information présentent sur le site de la
                        boulangerie l’Épi d’Or d’Orléans ne font pas objet de
                        dernier mot en cas de non-correspondance avec les
                        produits disponible dans la boutique physique. Seules
                        les informations prononcées par le Responsable de la
                        boutique physique de la boulangerie l’Épi d’Or d’Orléans
                        feront objet de valeur certaine. L’ensemble de la
                        boulangerie (Site internet et boutique physique) se
                        dédouanent de toute responsabilités dans le cas où les
                        informations ne sont pas à jour.
                    </p>

                    <h2>ARTICLE 4 - Collecte des données</h2>
                    <p>
                        Le Site assure à l'Utilisateur une collecte et un
                        traitement d'informations personnelles dans le respect
                        de la vie privée conformément à la loi n°78-17 du 6
                        janvier 1978 relative à l'informatique, aux fichiers et
                        aux libertés. 2 En vertu de la loi Informatique et
                        Libertés, en date du 6 janvier 1978, l'Utilisateur
                        dispose d'un droit d'accès, de rectification, de
                        suppression et d'opposition de ses données personnelles.
                        L'Utilisateur exerce ce droit :{" "}
                        <li>
                            par mail à l'adresse email
                            contact@boulangerielepidor.fr
                        </li>
                    </p>
                    <p>
                        Toute utilisation, reproduction, diffusion,
                        commercialisation, modification de toute ou partie du
                        Site, sans autorisation de l’Editeur est prohibée et
                        pourra entraînée des actions et poursuites judiciaires
                        telles que notamment prévues par le Code de la propriété
                        intellectuelle et le Code civil.
                    </p>
                </div>
            </section>
        </>
    );
};

export default PageCGU;
