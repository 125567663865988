export const API_URL = (process.env.REACT_APP_ADDR_IP || window.env.API_URL || "http://not-found.boulangerielepidor.fr") + "/api/";
export const API_GOOGLE = (process.env.REACT_APP_API_GOOGLE || window.env.API_GOOGLE || "no_google_api_found")
export const ADSENSE_SLOT = (process.env.REACT_APP_ADSENSE_SLOT || window.env.ADSENSE_SLOT || "no_adsense_slot_found")
export const ADSENSE_CLIENT = (process.env.REACT_APP_ADSENSE_CLIENT || window.env.ADSENSE_CLIENT || "no_adsense_client_found")

export const MAIL_CONTACT = "contact@boulangerielepidor.fr";
export const MAIL_MAX = "max.sevot@gmail.com";
export const PHONE_NUMBER = "02\u00A038\u00A088\u00A030\u00A051";
export const ADDRESS = "59 rue Maurice Dubois";
export const ADDRESSPLUS = "45000 Orléans FRANCE";

