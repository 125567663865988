import React, {useContext, useEffect, useState} from "react";
import Carousel from "react-bootstrap/Carousel";
import {API_URL} from "../../utils/constants";
import FormatDate from "../../utils/FormatDate";
import {message} from "antd";
import {GlobalContext} from "../layout/GlobalProvider";


export default function ListePublication() {
    const [listePublication, setListePublication] = useState([]);
    const {token, role} = useContext(GlobalContext)

    async function getPublications() {
        try {
            const response = await fetch(API_URL + "afficherPublications")
            const res = await response.json()
            if (res.status === 200) {
                setListePublication(res.data);
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur est survenue")
        }
    }

    useEffect(() => {
        getPublications();
    }, []);

    return listePublication.map((publication, i) => {
        return (
            <div key={i}>
                <AfficherPublication
                    publication={publication}
                    getPublications={getPublications}
                    token={token}
                    role={role}
                />
            </div>
        );
    });
}

function AfficherPublication({publication}) {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return publication.photos.length <= 0 ? (
        <section className="page-section cta">
            <div className="container">
                <div className="row">
                    <div className="col-xl-9 mx-auto">
                        <div className="cta-inner bg-faded text-center rounded">
                            <h2 className="section-heading mb-4">
                                <span className="section-heading-upper">
                                    {publication.type}
                                </span>
                                <span className="section-heading-lower">
                                    {publication.titre}
                                </span>
                            </h2>
                            <p className="mb-0">
                                {FormatDate(
                                    publication.datePublication,
                                    true,
                                    false
                                )}
                            </p>
                            <p className="mb-0">{publication.texte}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ) : publication.id % 2 === 1 ? (
        <section className="page-section">
            <div className="container">
                <div className="product-item">
                    <div className="product-item-title d-flex">
                        <div className="bg-faded p-5 d-flex ms-auto rounded">
                            <h2 className="section-heading mb-0">
                                <span className="section-heading-upper">
                                    {publication.type}
                                </span>
                                <span
                                    style={{fontSize: "4vw"}}
                                    className="section-heading-lower"
                                >
                                    {publication.titre}
                                </span>
                            </h2>
                        </div>
                    </div>
                    <Carousel activeIndex={index} onSelect={handleSelect}>
                        {publication.photos.map((elem, i) => {
                            return (
                                <Carousel.Item key={i}>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            height: "40rem",
                                        }}
                                    >
                                        <img
                                            className="d-block w-100 rounded"
                                            src={
                                                API_URL +
                                                "get_image/imagePublication/" +
                                                publication.dirPhoto +
                                                "/" +
                                                elem.filename
                                            }
                                            alt={publication.titre}
                                            style={{
                                                objectFit: "cover",
                                                width: "60%",
                                            }}
                                        />
                                    </div>
                                </Carousel.Item>
                            );
                        })}
                    </Carousel>
                    {/* <img className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0" src={require("src/img/imageDB/imagePublication/C'est noel !2022-06-25 192816/C'est_noel_!photo1.jpg")} alt="..." /> */}
                    <div className="product-item-description d-flex me-auto">
                        <div className="bg-faded p-5 rounded">
                            <p className="mb-0">{publication.texte}</p>
                            <p className="mb-0">
                                {FormatDate(
                                    publication.datePublication,
                                    true,
                                    false
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ) : (
        // Publication avec le text en haut à gauche
        <section className="page-section">
            <div className="container">
                <div className="product-item">
                    <div className="product-item-title d-flex">
                        <div className="bg-faded p-5 d-flex me-auto rounded">
                            <h2 className="section-heading mb-0">
                                <span className="section-heading-upper">
                                    {publication.type}
                                </span>
                                <span
                                    style={{fontSize: "4vw"}}
                                    className="section-heading-lower"
                                >
                                    {publication.titre}
                                </span>
                            </h2>
                        </div>
                    </div>
                    <Carousel activeIndex={index} onSelect={handleSelect}>
                        {publication.photos.map((elem, i) => {
                            return (
                                <Carousel.Item key={i}>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            height: "40rem",
                                        }}
                                    >
                                        <img
                                            className="d-block w-100 rounded"
                                            src={
                                                API_URL +
                                                "get_image/imagePublication/" +
                                                publication.dirPhoto +
                                                "/" +
                                                elem.filename
                                            }
                                            alt={publication.titre}
                                            style={{
                                                objectFit: "cover",
                                                width: "60%",
                                            }}
                                        />
                                    </div>
                                </Carousel.Item>
                            );
                        })}
                    </Carousel>
                    <div className="product-item-description d-flex ms-auto">
                        <div className="bg-faded p-5 rounded">
                            <p className="mb-0">{publication.texte}</p>
                            <p
                                className="mb-0"
                                style={{textTransform: "capitalize"}}
                            >
                                {FormatDate(
                                    publication.datePublication,
                                    true,
                                    false
                                )}
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}
