import React, {useEffect, useState} from "react";
import Header from "../components/layout/Header";
import {API_URL} from "../utils/constants";


const PageSpecialites = ({element}) => {
    const [produit, setProduit] = useState({});

    function getData() {
        fetch(API_URL + "afficherSpecialite")
            .then((res) => res.json())
            .then((data) => {
                setProduit(data);
            });
    }

    useEffect(() => {
        getData();
    }, []);

    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    };

    let res = [];
    if (produit.length > 0) {
        for (let i = 0; i < produit.length; i++) {
            if (
                produit[i].nom.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
                res.push(produit[i]);
            }
        }
    }

    return (
        <main>
            <Header
                title="Nos spécialités"
                subtitle="Des patisseries orientales"
                element={element}
            />
            <section>
                <div style={{margin: "1rem"}}>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Rechercher"
                        onChange={handleSearchTerm}
                    ></input>
                </div>
                {res.length === 0 ? (
                    <h1 className="text-dark">
                        Aucun résultat à la recherche {searchTerm}
                    </h1>
                ) : searchTerm === "" ? null : (
                    <h5>Resultat pour {searchTerm}</h5>
                )}
                {/*<ListeProduit donnee={res} />*/}
            </section>
        </main>
    );
};

export default PageSpecialites;
