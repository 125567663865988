import React, {useContext, useEffect, useState} from 'react';

import fr from "../../../utils/FR.json"
import {API_URL} from "../../../utils/constants"
import {GlobalContext} from "../../layout/GlobalProvider";
import {message, Spin} from "antd";


function DetailsAllergenes(props) {

    const [allergene, setAllergene] = useState("");
    const [allergenes, setAllergenes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState("Chargement des allergènes ...");

    const {token} = useContext(GlobalContext)

    const getData = async () => {
        try {
            setLoading(true)
            setLoadingMsg("Chargement des allergènes ...")
            const response = await fetch(API_URL + "get-all-allergenes", {
                method: "GET",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                setAllergenes(res.data)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur est survenue")
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        setLoadingMsg("Ajout de l'allergène ...")
        let data = new FormData();
        data.append("allergene", allergene);

        const addAllergene = async () => {
            try {
                const response = await fetch(API_URL + "add-allergene", {
                    method: "POST",
                    headers: {Authorization: "Bearer " + token},
                    body: data,
                })
                const res = await response.json()
                if (res.status === 200) {
                    setAllergene("")
                    getData();
                    message.success(res.message)
                } else {
                    message.error(res.message)
                }
            } catch (e) {
                message.error("Une erreur est survenue")
            } finally {
                setLoading(false)
            }
        };

        addAllergene();
    };

    const removeAllergene = async (id) => {
        setLoading(true)
        setLoadingMsg("Suppression de l'allergène ...")
        try {
            const response = await fetch(API_URL + "remove-allergene/" + id, {
                method: "DELETE",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                getData();
                message.success(res.message)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur est survenue")
        } finally {
            setLoading(false)
        }
    };

    return (
        <div>
            <label className="fw-bold">Ajouter un allergène</label>
            <div className="d-flex align-items-center justify-content-center gap-1 p-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Entrer le nom de l'allergène"
                    value={allergene}
                    onChange={(e) => setAllergene(e.target.value)}
                    required
                ></input>
                <button
                    className="btn btn-primary col-3"
                    onClick={handleSubmit}
                >
                    <i className="bi bi-plus-lg"></i> {fr.buttonAdd}
                </button>
            </div>
            <Spin spinning={loading || allergenes.length <= 0} tip={loadingMsg}>
                {allergenes.map((allergene) => {
                    return (
                        <div key={allergene.id} className="d-flex justify-content-between align-items-center px-5">
                            <p>{allergene.allergene}</p>
                            <button
                                className="btn btn-danger"
                                style={{height: "10%"}}
                                onClick={() =>
                                    removeAllergene(allergene.id)
                                }
                            >
                                <i className="bi bi-trash-fill"></i> Supprimer
                            </button>


                        </div>
                    );
                })}
            </Spin>
        </div>
    );
}

export default DetailsAllergenes;