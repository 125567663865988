import React, {useState} from "react";
import {Form, Modal} from "react-bootstrap";
import {API_URL} from "../../utils/constants";
import fr from "../../utils/FR.json";
import {Button, Input, message, Spin} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined, EuroOutlined} from "@ant-design/icons";


function ModalEditPrice({action, token, getProduits, selectedProducts}) {
    const [show, setShow] = useState(false);
    const toggle = () => setShow(!show);

    const [montant, setMontant] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        console.log(selectedProducts)
        e.preventDefault();
        setLoading(true);
        let data = new FormData();
        data.append("montant", montant);
        for (let idProduct of selectedProducts) {
            data.append("produits", idProduct);
        }
        const editPrice = async () => {
            try {
                const response = await fetch(API_URL + "edit-massive-price/" + action, {
                    method: "POST",
                    headers: {Authorization: "Bearer " + token},
                    body: data,
                })
                const res = await response.json()
                if (res.status === 200) {
                    getProduits()
                    toggle()
                    message.success(res.message)
                } else {
                    message.error(res.message)
                }
            } catch (e) {
                message.error("Une erreur est survenue")
            } finally {
                setLoading(false)
            }
        };
        editPrice();
    };

    return (
        <>
            {action === "diminution" ?
                <Button type="primary" style={{backgroundColor: "#1e569e"}} shape="round"
                        icon={<ArrowDownOutlined/>} onClick={toggle}>Diminuer les prix</Button>
                : action === "augmentation" &&
                <Button danger type="primary" style={{backgroundColor: "#3e714b"}} shape="round"
                        icon={<ArrowUpOutlined/>} onClick={toggle}>Augmenter les prix</Button>}

            <Modal show={show} onHide={toggle}>
                <Modal.Header closeButton>
                    <Modal.Title>{action === "diminution" ? "Diminution du prix" : "Augmentation du prix"}</Modal.Title>
                </Modal.Header>
                <Spin spinning={loading} tip="Modifications des prix en cours ...">
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <label className="fw-bold">
                                {action === "diminution" ? "Montant à diminuer" : "Montant à augmenter"}
                            </label>
                            <Input
                                type="number"
                                addonAfter={<EuroOutlined/>}
                                placeholder="Entrer le montant"
                                value={montant}
                                onChange={(e) => setMontant(e.target.value)}
                            />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={toggle}>
                            {fr.buttonClose}
                        </Button>

                        <Button variant="primary" onClick={handleSubmit}>
                            <i className="bi bi-pencil"></i> {fr.buttonEdit}
                        </Button>
                    </Modal.Footer>
                </Spin>
            </Modal>
        </>
    );
}

export default ModalEditPrice;
