import React, {useContext, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {API_URL} from "../../utils/constants";
import fr from "../../utils/FR.json";
import {GlobalContext} from "../layout/GlobalProvider";
import {message, Spin} from "antd";
import ImageActions from "../gestion/ImageActions";
import UploadPreview from "../gestion/UploadPreview";


const ModalEditPhoto = ({children, getPhotos, photo}) => {
    const [show, setShow] = useState(false);
    const {token} = useContext(GlobalContext)
    const toggle = () => setShow(!show);

    const [titre, setTitre] = useState(photo.titre);
    const [type, setType] = useState(photo.type);
    const [image, setImage] = useState({image: null});
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let data = new FormData();
        data.append("id", photo.id);
        data.append("titre", titre);
        data.append("type", type);
        data.append("photo", image.image);

        const Upload = async () => {
            try {
                const response = await fetch(API_URL + "edit-photo", {
                    method: "POST",
                    headers: {Authorization: "Bearer " + token},
                    body: data,
                })
                const res = await response.json()
                if (res.status === 200) {
                    toggle()
                    getPhotos()
                    message.success(res.message)
                } else {
                    message.error(res.message)
                }
            } catch (e) {
                message.error("Erreur lors de la modification de la photo")
            }
            setLoading(false)
        };
        Upload();
    };

    return (
        <>
            <button className="btn btn-primary" onClick={toggle}>
                {children}
            </button>

            <Modal show={show} onHide={toggle}>
                <Modal.Header closeButton>
                    <Modal.Title>Modifier une photo</Modal.Title>
                </Modal.Header>
                <Spin spinning={loading} tip="Modification de la photo en cours ...">
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <label className="fw-bold">Titre</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Entrer le titre"
                                value={titre}
                                onChange={(e) => setTitre(e.target.value)}
                            ></input>
                            <label className="fw-bold">Type de la photo</label>
                            <Form.Select
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                {Object.entries(fr.typePhoto).map((elem, i) => {
                                    return <option key={i}>{elem[1]}</option>;
                                })}
                            </Form.Select>
                            <label className="fw-bold">Image</label>
                            <ImageActions image={photo.photo} chemin="imagePhoto" idEntite={photo.id}
                            />
                            <UploadPreview onProduitDataChange={setImage}/>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={toggle}>
                            {fr.buttonClose}
                        </Button>

                        <Button variant="primary" onClick={handleSubmit}>
                            <i className="bi bi-pencil"></i> {fr.buttonEdit}
                        </Button>

                    </Modal.Footer>
                </Spin>
            </Modal>
        </>
    );
};

export default ModalEditPhoto;
