import React, {useMemo} from "react";
import {GoogleMap, Marker, useJsApiLoader} from "@react-google-maps/api";
import "../../styles/MapGoogle.css";
import {Cookies} from "react-cookie-consent";
import fr from "../../utils/FR.json";
import {Spin} from "antd";
import {API_GOOGLE} from "../../utils/constants";


function MapGoogle() {
    const {isLoaded} = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: API_GOOGLE,
    });
    const center = useMemo(() => ({lat: 47.92208, lng: 1.8919}), []);
    if (!isLoaded) {
        return (
            <Spin spinning={true} tip="Chargement de la map ...">
                <p></p>
            </Spin>
        );
    }

    return <Map center={center}/>;

}

function Map({center}) {
    const hasAcceptedCookies = Cookies.get(fr.nameLastCookiesContract) === "true"
    if (!hasAcceptedCookies) {
        return (
            <Spin spinning={true} tip="Vous devez accepter les cookies pour afficher la map ...">
                <div className="py-2" style={{backgroundColor: "#f1f1f1", borderRadius: "10px"}}></div>
            </Spin>
        );
    }

    return (
        <GoogleMap zoom={12} center={center} mapContainerClassName="map-container">
            <Marker position={center}/>
        </GoogleMap>
    );
}

export default MapGoogle;
