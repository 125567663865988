import ListePublication from "../components/publications/ListePublication";
import {Carousel} from "react-bootstrap";
import Header from "../components/layout/Header";
import fr from "../utils/FR.json";
import {Helmet} from "react-helmet";
import GoogleAd from "../components/layout/GoogleAd";
import {useContext} from "react";
import {GlobalContext} from "../components/layout/GlobalProvider";
import {NavLink} from "react-router-dom";
import {ADSENSE_CLIENT, ADSENSE_SLOT, API_URL} from "../utils/constants";


const titre = "Accueil - Boulangerie Pâtisserie l'Épi d'Or"
const url = "https://boulangerielepidor.fr/Home"
const content = "Boulangerie pâtisserie artisanale l'Épi d'Or à Orléans - horaires, produits et actualités en ligne. Découvrez notre boutique située à Orléans Nord Ouest."
const imgPreview = "preview/Accueil.png"
const PageWelcome = ({element}) => {
    const {globalConfig} = useContext(GlobalContext)

    return (
        <>
            <Helmet>
                <title>
                    {titre}
                </title>
                <meta
                    name="title"
                    content={titre}
                />
                <meta
                    name="description"
                    content={content}
                />

                <meta property="og:type" content="website"/>
                <meta
                    property="og:url"
                    content={url}
                />
                <meta
                    property="og:title"
                    content={titre}
                />
                <meta
                    property="og:description"
                    content={content}
                />
                <meta property="og:image" content={imgPreview}/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta
                    property="twitter:url"
                    content={url}
                />
                <meta
                    property="twitter:title"
                    content={titre}
                />
                <meta
                    property="twitter:description"
                    content={content}
                />
                <meta property="twitter:image" content={imgPreview}/>
            </Helmet>
            <Header
                title={fr.welcomeTitle}
                subtitle={
                    <div className="d-flex justify-content-center align-items-center gap-3">

                        <NavLink to={"/store/cakes"} className="btn btn-primary col-3"><i
                            className="bi bi-award-fill"></i> Voir les
                            pièces
                            montées
                        </NavLink>
                        <NavLink to={"/store/products"} className="btn btn-primary col-3"><i
                            className="bi bi-basket-fill"></i> Voir les
                            produits
                        </NavLink>
                        <NavLink to={"/other/photo-gallery"} className="btn btn-primary col-3"><i
                            className="bi bi-camera-fill"></i> Voir les
                            photos
                        </NavLink>
                    </div>
                }
                element={element}
                hauteur={"70vh"}
            />
            <section className="page-section about-heading">
                <div className="container">
                    <div>
                        <Carousel fade>
                            <Carousel.Item>
                                <img
                                    className="rounded img-fluid"
                                    src={require("../img/facade.jpg")}
                                    alt={fr.welcomeTitleSlide[1]}
                                    style={{
                                        objectFit: "cover",
                                    }}
                                    loading="lazy"
                                />
                                <Carousel.Caption>
                                    <h3>{fr.welcomeTitleSlide[1]}</h3>
                                    <p>{fr.welcomeDescriptionSlide[1]}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="rounded img-fluid"
                                    src={require("../img/boutiqueAvant.jpg")}
                                    alt={fr.welcomeTitleSlide[2]}
                                    style={{
                                        objectFit: "cover",
                                    }}
                                    loading="lazy"
                                />
                                <Carousel.Caption>
                                    <h3>{fr.welcomeTitleSlide[2]}</h3>
                                    <p>{fr.welcomeDescriptionSlide[3]}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="rounded img-fluid"
                                    src={require("../img/boutiqueArriere.jpg")}
                                    alt={fr.welcomeTitleSlide[3]}
                                    style={{
                                        objectFit: "cover",
                                    }}
                                    loading="lazy"
                                />
                                <Carousel.Caption>
                                    <h3>{fr.welcomeTitleSlide[3]}</h3>
                                    <p>{fr.welcomeDescriptionSlide[3]}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <div className="about-heading-content">
                        <div className="row">
                            <div className="col-xl-9 col-lg-10 mx-auto">
                                <div className="bg-faded rounded p-5">
                                    <h2 className="section-heading mb-4">
                                        <span className="section-heading-upper">
                                            {fr.welcomeTitleFirstText}
                                        </span>
                                        <span className="section-heading-lower">
                                            {fr.welcomeSubTitleFirstText}
                                        </span>
                                    </h2>
                                    <p>{fr.welcomeWelcome}</p>
                                    <p className="mb-0">
                                        {fr.welcomeDisclaimer[1]}{" "}
                                        <strong>
                                            {fr.welcomeDisclaimer[2]}
                                        </strong>{" "}
                                        {fr.welcomeDisclaimer[3]}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mx-3">
                <div className="bg-faded rounded p-5 px-5">
                    <h2 className="section-heading">
                        <span className="section-heading-upper">
                            {fr.welcomeActualite}
                        </span>
                        <span
                            style={{fontSize: "4vw"}}
                            className="section-heading-lower"
                        >
                            {fr.welcomeTitleActualite}
                        </span>
                    </h2>
                </div>
            </section>
            <section className="text-center mt-5">
                <a
                    className="btn btn-primary"
                    href={API_URL + "get_carte_noel/"}
                    download={API_URL + "get_carte_noel/"}
                    target="_blank"
                >
                    <i className="bi bi-download"></i> Télécharger la carte de Noël
                </a>
            </section>

            <ListePublication/>
            <section className="text-center my-5">
                <GoogleAd
                    client={ADSENSE_CLIENT}
                    slot={ADSENSE_SLOT}
                    style={{display: "block"}}
                    layout="in-article"
                    format="fluid"
                />
            </section>
        </>
    );
};

export default PageWelcome;
