import React, {useEffect, useState} from "react";
import {API_URL} from "../../utils/constants";
import fr from "../../utils/FR.json";
import {message, Rate, Spin} from "antd";

function Avis() {
    const [auteur, setAuteur] = useState("");
    const [ipaddr, setIpAddr] = useState("");
    const [note, setNote] = useState(5);
    const [avis, setAvis] = useState("");
    const [like, setLike] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = new FormData();
        data.append("auteur", auteur);
        data.append("ipaddr", JSON.stringify(ipaddr));
        data.append("note", note);
        data.append("avis", avis);
        data.append("like", like);

        const Upload = async () => {
            try {
                const response = await fetch(API_URL + "addAvis", {
                    method: "POST",
                    body: data,
                })
                const res = await response.json()
                if (res.status === 200) {
                    message.success("Votre avis a été envoyé. Nous vous en remercions !")
                    setAuteur("");
                    setNote(5);
                    setAvis("");
                    setLike(false);
                } else {
                    message.error(res.message)
                }
            } catch (e) {
                console.log(e)
                message.error("Une erreur est survenue, veuillez réessayer plus tard")
            }
            setLoading(false)
        };
        Upload();
    };

    async function getIpAddr() {
        try {
            const response = await fetch("https://geolocation-db.com/json/")
            const res = await response.json()
            setIpAddr(res)
        } catch (e) {
            console.log("Erreur dans avis")
        }
    }

    useEffect(() => {
        getIpAddr();
    }, []);

    const humeur = ["Horrible", "Mauvais", "Bien", "Super", "Excellent"]

    return (

        <div
            className="card text-start"
            style={{
                marginTop: "2rem",
                marginBottom: "2rem",
                marginLeft: "10%",
                marginRight: "10%",
            }}
        >
            <Spin spinning={loading} tip="Envoie de l'avis en cours ...">
                <form onSubmit={handleSubmit} className="card-body">
                    <div className="form-group">
                        <label className="card-text">{fr.avisAvisAuthor} : </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={fr.avisAvisAuthorPlaceholder}
                            value={auteur}
                            onChange={(e) => setAuteur(e.target.value)}
                        />
                    </div>
                    <div className="form-group d-flex align-items-center gap-2 mt-2">
                        <label className="">{fr.avisAvisNote} :</label>
                        <Rate character={<span className="bg-faded rounded-circle" role="img"
                                               aria-label="baguette">🥖</span>}
                              allowHalf allowClear={false} tooltips={humeur} value={note} onChange={setNote} c/>
                    </div>
                    <div className="form-group">
                        <label>{fr.avisAvisComment} :</label>
                        <textarea
                            type="text"
                            className="form-control"
                            placeholder={fr.avisAvisCommentPlaceholder}
                            value={avis}
                            onChange={(e) => setAvis(e.target.value)}
                            required
                        />
                    </div>
                    <div style={{marginTop: "20px", textAlign: "end"}}>
                        <button className="btn btn-primary">
                            {fr.buttonValidate}
                        </button>

                    </div>
                </form>
            </Spin>
        </div>

    );
}

export default Avis;
