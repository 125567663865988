import React, {useContext, useEffect, useState} from 'react';
import {Form} from "react-bootstrap";

import fr from "../../../utils/FR.json"
import {API_URL} from "../../../utils/constants"
import {GlobalContext} from "../../layout/GlobalProvider";
import {message, Spin} from "antd";

function DetailsDecors(props) {

    const [decors, setDecors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState("Chargement des décors ...");

    const {token} = useContext(GlobalContext)

    const [position, setPosition] = useState("Dessus");
    const [nappage, setNappage] = useState("");

    const getData = async () => {
        try {
            setLoading(true)
            setLoadingMsg("Chargement des décors ...")
            const response = await fetch(API_URL + "get-all-decors", {
                method: "GET",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                setDecors(res.data)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur est survenue")
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        setLoadingMsg("Ajout du décors ...")
        let data = new FormData();
        data.append("position", position);
        data.append("nappage", nappage);

        const addDecor = async () => {
            try {
                const response = await fetch(API_URL + "add-decor", {
                    method: "POST",
                    headers: {Authorization: "Bearer " + token},
                    body: data,
                })
                const res = await response.json()
                if (res.status === 200) {
                    setNappage("")
                    getData();
                    message.success(res.message)
                } else {
                    message.error(res.message)
                }
            } catch (e) {
                message.error("Une erreur est survenue")
            } finally {
                setLoading(false)
            }
        };

        addDecor();
    };

    const removeDecor = async (id) => {
        setLoading(true)
        setLoadingMsg("Suppression du décor ...")
        try {
            const response = await fetch(API_URL + "remove-decor/" + id, {
                method: "DELETE",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                getData();
                message.success(res.message)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur est survenue")
        } finally {
            setLoading(false)
        }
    };

    return (
        <div>
            <label className="fw-bold">Ajouter un décor</label>
            <div className="d-flex align-items-center justify-content-center gap-1 p-3">
                <div>
                    <label className="fw-bold">Position du décor</label>
                    <Form.Select
                        aria-label="Default select example"
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                    >
                        <option>Dessus</option>
                        <option>Coté</option>
                    </Form.Select>
                </div>
                <div>
                    <label className="fw-bold">Nappage</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Chocolat, Copeaux de noix de coco ..."
                        value={nappage}
                        onChange={(e) => setNappage(e.target.value)}
                        required
                    ></input>
                </div>

                <button
                    className="btn btn-primary col-3 mt-4"
                    onClick={handleSubmit}
                >
                    <i className="bi bi-plus-lg"></i> {fr.buttonAdd}
                </button>
            </div>
            <Spin spinning={loading || decors.length <= 0} tip={loadingMsg}>
                {decors.map((decor) => {
                    return (
                        <div key={decor.id} className="d-flex justify-content-between align-items-center px-5">
                            <p>{decor.nappage} sur le {decor.position}</p>
                            <button
                                className="btn btn-danger"
                                style={{height: "10%"}}
                                onClick={() =>
                                    removeDecor(decor.id)
                                }
                            >
                                <i className="bi bi-trash-fill"></i> Supprimer
                            </button>


                        </div>
                    );
                })}
            </Spin>
        </div>
    );
}

export default DetailsDecors;