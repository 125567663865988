import React from "react";

function Header({
    title,
    subtitle,
    element,
    background = "welcome.jpg",
    hauteur = "100vh",
}) {
    const img = require(`../../img/${background}`);
    return (
        <header
            className="welcome"
            style={{
                backgroundImage: `url(${img})`,
                height: hauteur,
            }}
        >
            <div className="text-center text-white" ref={element}>
                <h1 className="display-4 fw-bolder">{title}</h1>
                <p className="lead fw-normal text-white-50 mb-0">{subtitle}</p>
            </div>
        </header>
    );
}

export default Header;
