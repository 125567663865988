import React, {useContext, useEffect, useState} from "react";
import jwt_decode from "jwt-decode";
import {API_URL} from "../../utils/constants";
import fr from "../../utils/FR.json";
import {message, Popconfirm, Table} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {GlobalContext} from "../layout/GlobalProvider";

const GestionConnexions = () => {
    const [connexions, setConnexions] = useState([]);
    const {token} = useContext(GlobalContext)

    async function remove(id) {
        try {
            const response = await fetch(API_URL + "remove-connexion/" + id, {
                method: "DELETE",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                getData()
                message.success(res.message)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur s'est produite")
        }
    }

    const colonnes = [
        {
            title: 'Login',
            dataIndex: 'login',
            key: 'login',
            sorter: (a, b) => a.login.localeCompare(b.login),
        },
        {
            title: 'Date tentative',
            dataIndex: 'tentative_date',
            key: 'tentative_date',
            sorter: (a, b) => new Date(a.tentative_date) - new Date(b.tentative_date)
        },
        {
            title: 'Résultat',
            dataIndex: 'resultat',
            key: 'resultat',
            sorter: (a, b) => a.resultat.localeCompare(b.resultat)
        },
        {
            title: 'Adresse IP requête',
            dataIndex: 'adresse_ip',
            key: 'adresse_ip',
            sorter: (a, b) => a.adresse_ip.localeCompare(b.adresse_ip)
        },
        {
            title: 'Pays',
            dataIndex: 'pays',
            key: 'pays',
            sorter: (a, b) => a.pays.localeCompare(b.pays)
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <div>
                    <Popconfirm title="Action irréversible"
                                description="Êtes-vous sur de supprimer cette connexion ?"
                                onConfirm={() => remove(record.id)}
                                icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                                cancelText="Annuler"
                                okText="Supprimer"
                    >
                        <button
                            className={
                                jwt_decode(token)["sub"] === record.nom
                                    ? "btn btn-danger disabled"
                                    : "btn btn-danger"
                            }
                            style={{margin: "3px"}}
                        >
                            <i className="bi bi-trash"></i> {fr.buttonRemove}
                        </button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    async function getData() {
        try {
            const response = await fetch(API_URL + "get-connexions-admin", {
                method: "GET",
                headers: {Authorization: "Bearer " + token},
            })
            const res = await response.json()
            if (res.status === 200) {
                setConnexions(res.data)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur s'est produite")
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const ligneSup = (record) => {
        return (
            <div className="d-flex justify-content-between">
                <span>IP online : {record.ipv4}</span>
                <span>Code Pays : {record.code_pays}</span>
                <span>Ville : {record.ville}</span>
                <span>
                    Département : {record.departement}
                </span>
                <span>
                    Code postal : {record.code_postal}
                </span>
                <span>
                    Coordonées : {record.longitude}long / {record.latitude}lat
                </span>
            </div>
        )
    }

    return (
        <>
            <h2 className="text-primary text-center fw-bold">Connexions</h2>
            <h3 className="text-primary text-center">{connexions.length} Connexions</h3>
            <div className="table-responsive">
                <Table
                    rowKey="id"
                    dataSource={connexions}
                    columns={colonnes}
                    loading={connexions.length < 1}
                    expandable={{
                        expandRowByClick: true,
                        expandedRowRender: ligneSup,
                    }}
                />
            </div>
        </>
    )
};


export default GestionConnexions;
