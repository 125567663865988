import React, {useState} from "react";
import {Button, Upload} from "antd";
import {UploadOutlined} from '@ant-design/icons';

function UploadPreview({onProduitDataChange}) {
    const [fileList, setFileList] = useState([]);

    const handleFileChange = (e) => {
        let fileList = [...e.fileList];
        fileList = fileList.slice(-1); // Ne conserve que le dernier fichier sélectionné
        setFileList(fileList);
        if (fileList.length > 0) {
            const originFileObj = fileList[0].originFileObj;
            onProduitDataChange({image: originFileObj});
        }
    };


    return (
        <>
            <Upload
                listType="picture"
                className="d-flex"
                fileList={fileList}
                beforeUpload={() => false}
                onChange={handleFileChange}
                onRemove={() => setFileList([])}
            >
                <Button icon={<UploadOutlined/>}>Télécharger une image</Button>
            </Upload>
        </>
    );
}

export default UploadPreview;


