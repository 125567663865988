import React from "react";

const PageTmp = ({element}) => {
    return (
        <>
            <section className="page-section about-heading" ref={element}>
                <div className="container">
                    <div
                        className="about-heading-content"
                        style={{marginTop: "10rem"}}
                    >
                        <div className="row">
                            <div className="col-xl-9 col-lg-10 mx-auto">
                                <div
                                    className="bg-faded rounded p-5 "
                                    style={{textAlign: "center"}}
                                >
                                    <h2 className="section-heading mb-4">
                                        <span className="section-heading-upper">
                                            Peut être une prochaine fois !
                                        </span>
                                        <span className="section-heading-lower">
                                            On en a pas mais c'était jolie à
                                            mettre
                                        </span>
                                    </h2>
                                    <div>
                                        <i className="bi bi-exclamation-octagon fa-6x"></i>
                                    </div>
                                    <p className="mb-0">
                                        Cette page est inexistante pour le
                                        moment.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <GoogleAd
                client={ADSENSE_CLIENT}
                slot={ADSENSE_SLOT}
                style={{ display: "block" }}
                layout="in-article"
                format="fluid"
            /> */}
        </>
    );
};

export default PageTmp;
