import React, {useEffect, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {ADDRESS, ADDRESSPLUS, API_URL, MAIL_CONTACT, PHONE_NUMBER} from "../../utils/constants";
import {Cookies} from "react-cookie-consent";
import fr from "../../utils/FR.json";
import {message} from "antd";

export default function Footer({setShowCookieModal}) {
    const [photos, setPhotos] = useState([]);
    const [otherMention, setOtherMention] = useState(false);
    const navigate = useNavigate();

    async function getPhotos() {
        try {
            const response = await fetch(API_URL + "get-photos-footer", {
                method: "GET",
            })
            const res = await response.json()
            if (res.status === 200) {
                setPhotos(res.data)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur s'est produite")
        }
    }

    useEffect(() => {
        getPhotos()
    }, []);

    return (
        <footer>
            <div
                className="container-fluid bg-dark text-light footer my-6 mb-0 py-5 wow fadeIn"
                data-wow-delay="0.1s"
                style={{bottom: "0"}}
            >
                <div className="container py-5">
                    <div className="row g-5 d-flex justify-content-between">
                        <div className="col-lg-3 col-md-6">
                            <h4 className="text-light mb-4">
                                {fr.footerAddress}
                            </h4>
                            <p className="mb-2 text-light">
                                <i className="fa fa-map-marker-alt me-3"></i>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={fr.linkGoogleMaps}
                                    style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                    }}
                                >
                                    {ADDRESS}, {ADDRESSPLUS}
                                </a>
                            </p>
                            <p className="mb-2 text-light">
                                <i className="fa fa-phone-alt me-3"></i>
                                <a
                                    href={`tel:${PHONE_NUMBER}`}
                                    style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                    }}
                                >
                                    {PHONE_NUMBER}
                                </a>
                            </p>
                            <p className="mb-2 text-light">
                                <i className="fa fa-envelope me-3"></i>
                                <a
                                    href={`mailto: ${MAIL_CONTACT}`}
                                    style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                    }}
                                >
                                    {MAIL_CONTACT}
                                </a>
                            </p>
                            <div className="d-flex pt-2">
                                <a
                                    className="btn btn-square btn-outline-light rounded-circle me-1"
                                    href={fr.linkGoogle}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fab fa-google"></i>
                                </a>
                                <a
                                    className="btn btn-square btn-outline-light rounded-circle me-1"
                                    href={fr.linkFacebook}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                                <a
                                    className="btn btn-square btn-outline-light rounded-circle me-1"
                                    href={fr.linkInstagram}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fab fa-instagram"></i>
                                </a>
                                <a
                                    className="btn btn-square btn-outline-light rounded-circle me-0"
                                    href={fr.linkLinkedin}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fab fa-linkedin-in"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h4 className="text-light mb-4">{fr.footerLink}</h4>
                            <button
                                className="btn btn-link"
                                onClick={() => navigate(fr.routeAbout)}
                            >
                                {fr.footerLinkAbout}
                            </button>
                            <button
                                className="btn btn-link"
                                onClick={() => navigate(fr.routeContact)}
                            >
                                {fr.footerLinkContact}
                            </button>
                            <button
                                className="btn btn-link"
                                onClick={() => navigate(fr.routeShopProduct)}
                            >
                                {fr.footerLinkProduct}
                            </button>
                            <button
                                className="btn btn-link"
                                onClick={() => navigate(fr.routeLegalNotice)}
                            >
                                {fr.footerLinkCGU}
                            </button>
                            <button
                                className="btn btn-link"
                                onClick={() => navigate(fr.routePrivacyPolicy)}
                            >
                                {fr.footerLinkPrivacyPolicy}
                            </button>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h4
                                className="text-light mb-4 "
                                onClick={() => navigate(fr.routeOtherPhoto)}
                                style={{cursor: "pointer"}}
                            >
                                {fr.footerPhoto}
                            </h4>
                            <div
                                className="row g-2"
                                onClick={() => navigate(fr.routeOtherPhoto)}
                                style={{cursor: "pointer"}}
                            >
                                {photos.map(photo =>
                                    <div className="col-4" key={photo.id}>
                                        <img
                                            className="img-fluid bg-light rounded p-1"
                                            src={API_URL + "get_image/imagePhoto/" + photo.photo}
                                            alt="Production de l'Épi d'Or"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="container-fluid copyright text-light py-4 wow fadeIn"
                data-wow-delay="0.1s"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            &copy; <NavLink to="/#">{fr.footerBottomLeft[1]}</NavLink>,{" "}
                            {fr.footerBottomLeft[2]}
                            <br/>
                            {fr.footerBottomLeft[3]} &copy;{" "}
                            2022 - {new Date().getFullYear()}
                        </div>

                        <div className="col-md-6 text-center text-md-end">
                            <span onClick={() => navigate(fr.routeLogin)}>
                                {fr.footerDev} :
                            </span>{" "}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={fr.linkLinkedinMax}
                            >
                                {fr.footerDevBy}
                            </a>
                            <br/>
                            <span
                                onClick={() => setOtherMention(!otherMention)}
                            >
                                {fr.foooterOtherMention}
                            </span>
                            {otherMention &&
                                <>
                                    <br/>
                                    {fr.footerDesgin}{" "}
                                    <a href="#">{fr.footerDesginBy}</a>
                                    <br/>
                                    {fr.footerDistributed}{" "}
                                    <a href="#">{fr.footerDistributedBy}</a>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <a
                href="#"
                className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"
            >
                <i className="bi bi-arrow-up"></i>
            </a>
            {Cookies.get(fr.nameLastCookiesContract) !== undefined ? (
                <button
                    onClick={() => setShowCookieModal(true)}
                    className="btn btn-lg btn-secondary btn-lg-square rounded-circle btn-cookies"
                >
                    🍪
                </button>
            ) : null}
        </footer>
    );
}
