import React, {useState} from "react";
import GoogleAd from "../components/layout/GoogleAd";
import {Spin} from "antd";
import {ADSENSE_CLIENT, ADSENSE_SLOT} from "../utils/constants";

const PageHack = ({element}) => {
    const [load, setLoad] = useState(true);

    const reinitiTimer = () => {
        setTimeout(() => {
            setLoad(false);
        }, 20000);
    };

    const handleLoad = (e) => {
        window.location.reload();
        setLoad(true);
        reinitiTimer();
    };

    reinitiTimer();

    return (
        <>
            <section className="page-section about-heading" ref={element}>
                <div className="container">
                    <div
                        className="about-heading-content"
                        style={{marginTop: "10rem"}}
                    >
                        <section>
                            <GoogleAd
                                client={ADSENSE_CLIENT}
                                slot={ADSENSE_SLOT}
                                style={{display: "block"}}
                                layout="in-article"
                                format="fluid"
                            />
                        </section>

                        <div
                            style={{
                                maxWidth: "100%",
                                display: "grid",
                                gridTemplateColumns: "repeat(3, 1fr)",
                                gridAutoRows: "2fr",
                                marginTop: "50px",
                                marginBottom: "50px",
                            }}
                        >
                            <section>
                                <GoogleAd
                                    client={
                                        ADSENSE_CLIENT
                                    }
                                    slot={ADSENSE_SLOT}
                                    style={{display: "block"}}
                                    layout="in-article"
                                    format="fluid"
                                />
                            </section>

                            <div className="row">

                                <div className="col-xl-9 col-lg-10 mx-auto">

                                    <div
                                        className="bg-faded rounded p-5 "
                                        style={{textAlign: "center"}}
                                    >
                                        <Spin spinning={load} tip="Chargement des données en cours ...">
                                            <h2 className="section-heading mb-4">
                                            <span className="section-heading-upper">
                                                {load
                                                    ? "Bonjour admin, veuillez patienter"
                                                    : "Une erreur s'est produite..."}
                                            </span>
                                            </h2>
                                            {!load && (
                                                <i>
                                                    Désolé une erreur s'est
                                                    produite, essayez d'actualiser
                                                    la page
                                                </i>
                                            )}
                                            <br/>
                                            <br/>

                                            {!load && <button
                                                className="btn btn-primary"
                                                onClick={handleLoad}
                                            >
                                                Recharger{" "}
                                                <i
                                                    style={{color: "#3380FF"}}
                                                    className="bi bi-arrow-clockwise fa-2x"
                                                ></i>
                                            </button>}
                                        </Spin>
                                    </div>

                                </div>
                            </div>
                            <section>
                                <GoogleAd
                                    client={
                                        ADSENSE_CLIENT
                                    }
                                    slot={ADSENSE_SLOT}
                                    style={{display: "block"}}
                                    layout="in-article"
                                    format="fluid"
                                />
                            </section>
                        </div>
                        <section>
                            <GoogleAd
                                client={ADSENSE_CLIENT}
                                slot={ADSENSE_SLOT}
                                style={{display: "block"}}
                                layout="in-article"
                                format="fluid"
                            />
                        </section>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PageHack;
